const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div.articlesPage
  div.articlesList
    h2.titleBig.colorGray Новости
    div.articlesList_list
      for article in state
        article.articlesList_item(
          className=article.preview_img ? null : 'articlesList_item--txt'
          key=article.id
          data-type=article.type_show
        )
          Link.articlesList_link(
            href='/novosti/'+article.url
          )
            if article.preview_img
              figure.articlesList_figure
                img.articlesList_img(
                  src=article.preview_img
                  alt=article.title
                )
            div.articlesList_txt
              h3.articlesList_title= m.trust(article.title)
              time.articlesList_time= article.date

  `;
};