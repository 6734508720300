import m from 'mithril';

import adress from './address';
import input from './input';
import select from './select';
import switcher from './switcher';
import date from './date';

const controls = {
  adress,
  input,
  select,
  switcher,
  date,
};

export default {
  view({ attrs }) {
    const {
      path,
      dataIsLoaded,
      settings,
      updateValue,
      item: { question_group: _, type, ...item },
    } = attrs;

    if (controls[type]) {
      // const { disabled } = settings;
      return m(controls[type], {
        path,
        settings,
        dataIsLoaded,
        updateValue,
        ...item,
      });
      // ,
      // disabled
      //   ? m(
      //       'button',
      //       { onclick: () => updateValue(path.concat('disabled'), true) },
      //       'редактировать "',
      //       item.label,
      //       '"',
      //     )
      //   : null,
      // m('hr'),;
    }

    return m('h3', `${attrs.item.type} не реализован`);
  },
};
