const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div.popupStandart.popupStandart--purple(class=state.wrapperClass)
  button.popupStandart__close(
    onclick=actions.hide
  ) ×
  p.popupStandart__p Успей записаться, количество мест ограничено!
  p.popupStandart__p 
    b 
      | 9 сентября, мобильная биометрия, 
      br
      | страна Франция 🇫🇷 
  Link.buttonStandart.buttonStandart--white.buttonStandart--whiteFlat(
    href="/callback"
    onclick=actions.perform
  )
    span.buttonStandart_name Узнать больше

  `;
};