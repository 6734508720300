const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div.tabs(
  className=state.classes.wrapper
)
  = children.headers
  div.tab(
      className=state.classes.tabWrapper
    )
    = children.content
  `;
};