/* eslint-disable */
import m from 'mithril';
import Autocomplete from 'models/autocomplete';
import Orders from 'models/orders';
import { API } from 'constants.js';
import autocompleteInput from 'decorators/autocomplete';
import serch from './search.svg';

// #if process.env.IS_MOBILE == 'true'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// #endif

let lastRequest = 0;
let currentPage = 0;
let pagesCount = 0;
let fetchInProgress = false;

const onChange = context => {
  const current = lastRequest + 1;
  lastRequest += 1;
  currentPage = 1;

  if (context.value) {
    m.request({
      url: `${API}/dictionaries/1`,
      params: {
        q: context.value.replace(' ', '_'),
        per_page: 25,
        page: 1,
      },
    }).then(({ data, page_count }) => {
      if (lastRequest === current) {
        pagesCount = page_count;
        context.setList(data);
        context.display = data;
      }
    });
  } else {
    context.display = [];
  }
};

const getMore = context => {
  if (currentPage < pagesCount && !fetchInProgress) {
    if (context.value) {
      const current = lastRequest + 1;
      lastRequest += 1;
      currentPage += 1;
      fetchInProgress = true;

      m.request({
        url: `${API}/dictionaries/1`,
        params: {
          q: context.value,
          per_page: 25,
          page: currentPage,
        },
      }).then(({ data }) => {
        if (lastRequest === current) {
          const items = [...context.list, ...data];
          context.setList(items);
          context.display = items;
        }
        fetchInProgress = false;
      });
    }
  }
};

const onSelect = close => item => {
  Orders.current.values.city = item;
  Orders.sendValues().then(close);
};

let instance = null;

const content = {
  // #if process.env.IS_MOBILE == 'true'
  oninit() {
    setTimeout(() => {
      disableBodyScroll(document.getElementById('cities'));
    }, 100);
  },
  // #endif
  view({ attrs, children }) {
    return m('#cities.autoselectMenu_list', attrs, children);
  },
};

export default close => {
  if (!instance) {
    const context = Autocomplete(onChange, onSelect(close));
    instance = autocompleteInput(
      context,
      () => {},
      attrs => {
        return m(
          'div.orderCalculatorDropdown_wrapInput',
          { ...attrs.state.wrapper },
          m('input.simpleInput.simpleInput--typeSearch', {
            ...attrs.state.input,
            placeholder: 'Найти',
          }),
          m.trust(serch),
        );
      },
      attrs =>
        m(
          content,
          {
            ...attrs.state.attrs,
            onscroll: e => {
              if (e.target.scrollTop / e.target.scrollHeight > 0.8) {
                getMore(context);
              }
            },
            style: {
              ...attrs.helpers.getStyles(),
              opacity: attrs.state.length ? 1 : 0,
              position: 'absolute',
              width: '350px',
              zIndex: 2,
              maxHeight: '180px',
              overflow: 'scroll',
              padding: '0px',
            },
          },
          attrs.children.items,
        ),
      attrs =>
        m(
          'button.autoselectMenu_listItem',
          {
            ...attrs.state.attrs,
            class: attrs.state.isCurrent
              ? 'autoselectMenu_listItem--current'
              : '',
            // style: {
            //   textDecoration: attrs.state.isCurrent ? 'underline' : 'none',
            // },
          },
          m('span.autoselectMenu_listItemName', attrs.state.item.title),
          m(
            'span.autoselectMenu_listItemRegion',
            ', ',
            m(
              'span.autoselectMenu_listItemRegionInner',
              attrs.state.item.region,
            ),
          ),
        ),
      context => ({}),
      null,
      1,
    );

    window.autoselectInstance = instance;
  }

  return instance;
};
