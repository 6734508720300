// #if process.env.IS_DEVELOPMENT == 'true'
import m from 'mithril';
// #endif
import routes from '../routes';

// #if process.env.IS_DEVELOPMENT == 'true'
if (!window.m) {
  window.m = m;
}
// #endif

routes();

if (module.hot) {
  module.hot.accept('../routes.js', () => {
    import('../routes').then(({ default: newRoutes }) => {
      newRoutes();

      // eslint-disable-next-line no-console
      console.clear();
    });
  });
}
