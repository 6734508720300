const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
article.text-page
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_AD Андорра
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_AE ОАЭ
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_AG Антигуа и Барбуда
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_AM Армения
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_AR Аргентина
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_AT Австрия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_AU Австралия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_BE Бельгия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_BF Буркина-Фасо
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_BG Болгария
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_BO Боливия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_BR Бразилия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_CA Канада
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_CD ДР Конго
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_CG Республика Конго
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_CH Швейцария
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_CL Чили
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_CM Камерун
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_CN Китай
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_CO Колумбия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_CZ Чехия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_DE Германия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_DJ Джибути
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_DK Дания
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_DZ Алжир
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_EE Эстония
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_EG Египет
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_ES Испания
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_FI Финляндия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_FR Франция
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_GA Габон
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_GB Великобритания
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_GM Гамбия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_GT Гватемала
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_HN Гондурас
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_HT Гаити
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_HU Венгрия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_ID Индонезия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_IE Ирландия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_IL Израиль
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_IN Индия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_IQ Ирак
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_IR Иран
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_IT Италия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_JM Ямайка
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_JO Иордания
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_JP Япония
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_KG Киргизия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_KN Сент-Китс и Невис
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_KP КНДР
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_KR Республика Корея
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_KW Кувейт
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_KZ Казахстан
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_LA Лаос
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_LB Ливан
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_LC Сент-Люсия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_LS Лесото
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_LU Люксембург
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_LV Латвия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_MG Мадагаскар
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_MK Македония
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_ML Мали
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_MM Мьянма
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_MT Мальта
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_MX Мексика
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_NA Намибия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_NE Нигер
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_NG Нигерия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_NI Никарагуа
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_NL Нидерланды
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_NO Норвегия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_OM Оман
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_PA Панама
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_PE Перу
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_PG Папуа - Новая Гвинея
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_PK Пакистан
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_PL Польша
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_PT Португалия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_PY Парагвай
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_QA Катар
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_RO Румыния
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_RU Россия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_RW Руанда
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_SA Саудовская Аравия
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_SE Швеция
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_SG Сингапур
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_SL Сьерра-Леоне
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_SN Сенегал
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_SO Сомали
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_SV Сальвадор
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_TD Чад
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_TJ Таджикистан
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_TL Восточный Тимор
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_TR Турция
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_TZ Танзания
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_UA Украина
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_US США
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_VE Венесуэла
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_VN Вьетнам
  div.homeSelectCountry_captionLink.spriteFlags.bigFlag.flags_YE Йемен

  `;
};