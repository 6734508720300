const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div.aboutPage
  div.aboutPageLead
    h1.titleBig.aboutPageLead_title Мы — сервис подготовки и проверки документов для получения визы
    p.pStandart.aboutPageLead_p  
      a.simpleLink.simpleLink--white(
        href='/'
        data-internal
      ) 
        span.line Visard.online
      | — это уникальная платформа, позволяющая подготовить документы на визу самостоятельно, не выходя из дома. Актуальная информация и консультация специалистов помогут быстро оформить визу. 
    div.aboutPageLead_video
      video.aboutPageLead_videoTag(
        poster='img/about/aboutVideoPoster.jpg'
      )
  ul.iconWall
    li.iconWall_item
      div.iconWall_icon
        img.iconWall_svg
      span.iconWall_span.pStandart Легко узнать стоимость визы
    li.iconWall_item
      div.iconWall_icon
        img.iconWall_svg
      span.iconWall_span.pStandart Получи индивидуальный список документов
    li.iconWall_item
      div.iconWall_icon
        img.iconWall_svg
      span.iconWall_span.pStandart Автоопределение места подачи на визу
    li.iconWall_item
      div.iconWall_icon
        img.iconWall_svg
      span.iconWall_span.pStandart Подача на визу из дома
  div.simpleChess
    section.simpleChess_item
      div.simpleChess_picture
        img.simpleChess_img(
          src="img/about/ab1.jpg"
        )
      div.simpleChess_txt
        h2.simpleChess_title.titleBig Легко узнать стоимость визы
        p.simpleChess_p.pStandart 
          | Калькулятор 
          a.simpleLink(
            href='/'
            data-internal
          ) 
            span.line Visard.online 
          |  даёт актуальную стоимость визы. Набор услуг в калькуляторе можно менять согласно личным обстоятельствам и предпочтениям. Обязательная минимальная стоимость визы в виде консульских сборов всегда отражена в калькуляторе, даже если все услуги выключены.
    section.simpleChess_item
      div.simpleChess_picture
        img.simpleChess_img(
          src="img/about/ab2.jpg"
        )
      div.simpleChess_txt
        h2.simpleChess_title.titleBig Получи индивидуальный список документов
        p.simpleChess_p.pStandart Сервис задаёт только необходимые вопросы, автоматически отправляет подсказки и запоминает ответы. Собрав все ответы на вопросы анкеты, сервис выдаст список документов и подскажет, где найти нужный документ и в каком виде подготовить для визы.
    section.simpleChess_item
      div.simpleChess_picture
        //- img.simpleChess_img
      div.simpleChess_txt
        h2.simpleChess_title.titleBig Автоопределение места подачи на визу
        p.simpleChess_p.pStandart В России действует более 800 официальных центров выдачи виз. Каждый из них поддерживает свои страны и способы подачи. Visard автоматически определяет подходящий центр выдачи именно для вас.
    section.simpleChess_item
      div.simpleChess_picture
        //- img.simpleChess_img
      div.simpleChess_txt
        h2.simpleChess_title.titleBig Подача на визу из${'\u00A0'}дома
        p.simpleChess_p.pStandart 
          | Оформить визу, сохраняя привычный ритм жизни, теперь возможно. Закажи услугу доставки. Курьеры 
          a.simpleLink(
            href='/'
            data-internal
          ) 
            span.line Visard.online 
          | заберут документы по удобному для вас адресу, подадут их в консульство и привезут готовый паспорт с визой к вам на дом или в офис.
    section.simpleChess_item
      div.simpleChess_picture
        img.simpleChess_img(
          src="img/about/ab5.jpg"
        )
      div.simpleChess_txt
        h2.simpleChess_title.titleBig Гарантия качественной визовой поддержки
        p.simpleChess_p.pStandart За 7 лет работы в сфере туристических услуг компания Visard достигла высокого уровня в оказании визовой поддержки. Мы${'\u00A0'}знаем все тонкости оформления визы для жителя любого региона страны.
  `;
};