/* eslint-disable */

import m from 'mithril';
// import cx from 'classnames';
import resolveTemplate from 'utils/resolveTemplate';
import OrderDocuments from 'models/orderDocuments';
import Persons from 'models/persons';
import DropZone from 'components/widgets/dropZone';
import Input from './input';

const content = {
  view(vnode) {
    return resolveTemplate('widgets/doc_content')({
      state: {
        title: 'Заявители',
      },
      actions: {
        ondelete: vnode.state.ondelete,
      },
      children: {
        content: m('fuck this mithril'),
      },
    });
  },
};

export default {
  oninit({
    attrs: {
      orderId,
      item: { id: documentId },
    },
  }) {
    this.template = resolveTemplate('pages/order/documents/item');

    this.onUpload = event =>
      OrderDocuments.uploadEventHandler(
        event,
        orderId,
        Persons.current,
        documentId,
      );
  },

  view({
    attrs: {
      orderId,
      item: {
        id: documentId,
        name,
        preview,
        // status,
        attached,
        verification,
        verified,
      },
    },
  }) {
    const { current: personId } = Persons;
    return this.template({
      state: {
        // className: cx({
        //   loaded: status !== 'notLoaded',
        //   notLoaded: status === 'notLoaded',
        // }),
        name,
        attached,
        verification,
        verified,
        href: '#',
        // status: status === 'notLoaded' ? 'не загружен' : 'загружен',
      },
      actions: {},
      children: {
        dropZone: m(
          DropZone,
          {
            onDrop: this.onUpload,
          },
          m(Input, {
            preview,
            attached,
            accepted: OrderDocuments.allowedFileTypes.join(', '),
            orderId,
            personId,
            documentId,
            onChange: this.onUpload,
          }),
        ),
      },
    });
  },
};
