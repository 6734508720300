import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import Orders from 'models/orders';
import personSvg from './persons.svg';
import childrenSvg from './children.svg';

export const person = {
  view({ attrs }) {
    return resolveTemplate('widgets/order-result/applicants/person')({
      actions: {
        deletePerson: id => () => {
          Orders.current.values.persons.splice(id, 1);
          Orders.sendValues();
          // #if process.env.IS_DEVELOPMENT == 'false'
// //           window.dataLayer.push({ event: 'changeAge' });
          // #endif
        },
        changeAge: id => ({ target: { value } }) => {
          Orders.current.values.persons[id].age = value;
          Orders.sendValues();
          // #if process.env.IS_DEVELOPMENT == 'false'
// //           window.dataLayer.push({ event: 'changeAge' });
          // #endif
        },
      },
      state: {
        notOnlyChild: attrs.notOnlyChild,
        index: attrs.index,
        age: attrs.age,
        ages: [...Array(86).keys()],
      },
      helpers: {
        getAgePostfix: age => {
          const last = age % 10;
          if (last === 1 && age !== 11) return ' год';
          if (last >= 2 && last <= 4 && age - last !== 10) return ' года';
          return ' лет';
        },
        getIcon: age =>
          m.trust(
            (age < 4 ? childrenSvg : personSvg).replace(
              'setClass',
              'personsSettings_svg',
            ),
          ),
        equal: (a, b) => a === b,
      },
    });
  },
};

const personList = {
  view() {
    const { persons } = Orders.current.values;
    const notOnlyChild = persons.length !== 1;

    return resolveTemplate('widgets/order-result/applicants/personList')({
      children: {
        persons: persons.map((item, index) =>
          m(person, { ...item, index, notOnlyChild }),
        ),
      },
    });
  },
};

export default personList;
