/* eslint-disable */

import Persons from 'models/persons';
import resolveTemplate from 'utils/resolveTemplate';

export default {
  oninit(vnode) {
    const {
      orderId,
      person: { id: personId },
    } = vnode.attrs;
    this.deletePerson = () => Persons.deletePerson(orderId, personId);
    this.template = resolveTemplate('pages/order/persons/item');
  },
  view(vnode) {
    const {
      qModel,
      editMode,
      person: {
        id: personId,
        fullName,
        questions: { summary, filled },
      },
      person_count,
    } = vnode.attrs;

    return this.template({
      state: {
        editMode,
        selectedClass:
          Persons.current === personId || editMode
            ? 'personList_item--selected'
            : '',
        fullName,
        summary,
        filled,
        person_count,
      },
      actions: {
        deleteOnclick: this.deletePerson,
        itemOnclick: !editMode
          ? () => Persons.setCurrent(personId).finally(qModel.selectFirst)
          : e => {
              e.preventDefault();
              e.stopPropagation();
            },
      },
    });
  },
};
