import m from 'mithril';

const getAutocomplete = (onChange, onSelect) => {
  let Autocomplete = null;
  Autocomplete = {
    display: [],
    currentItem: {},
    hoverItemIndex: 0,
    value: '',
    lastScroll: new Date().getTime(),
    needScroll: false,
    needRemove: false,

    setList(list) {
      Autocomplete.list = list;
    },

    setValue(value) {
      Autocomplete.value = value;
      onChange(Autocomplete);

      return value;
    },

    setPreviousValue() {
      if (Autocomplete.currentItem) {
        Autocomplete.value = Autocomplete.currentItem.name;
      } else {
        Autocomplete.value = '';
      }
    },

    setHoverItemIndex(index, needScroll = true) {
      if (index === Autocomplete.hoverItemIndex) return;

      Autocomplete.needScroll = needScroll;
      Autocomplete.hoverItemIndex = index;
    },

    selectItem(item) {
      Autocomplete.display = [];
      Autocomplete.value = item.name;
      Autocomplete.currentItem = item;
      onSelect(item);
    },

    setItem(id) {
      const nextItem = Autocomplete.list.find(item => item.id === id);

      if (nextItem) {
        Autocomplete.display = [];
        Autocomplete.value = nextItem.name;
        Autocomplete.currentItem = nextItem;
      }
    },

    changeHoverItemIndex: e => {
      let { hoverItemIndex } = Autocomplete;
      const { length } = Autocomplete.display;

      switch (e.key) {
        case 'ArrowUp': {
          if (hoverItemIndex === null) {
            hoverItemIndex = 0;
          }
          Autocomplete.setHoverItemIndex(
            (length + hoverItemIndex - 1) % length,
          );
          break;
        }
        case 'ArrowDown': {
          if (hoverItemIndex === null) {
            hoverItemIndex = -1;
          }
          Autocomplete.setHoverItemIndex((hoverItemIndex + 1) % length);
          break;
        }
        case 'Enter': {
          const item = Autocomplete.display[Autocomplete.hoverItemIndex];
          Autocomplete.selectItem(item);
          break;
        }
        case 'Escape':
          Autocomplete.setPreviousValue();
          break;
        case 'Backspace':
          if (Autocomplete.value === Autocomplete.currentItem.name) {
            Autocomplete.needRemove = true;
          }
          break;
        default:
          if (
            e.key.length === 1 && // not arrows and functional keys
            Autocomplete.value === Autocomplete.currentItem.name
          ) {
            Autocomplete.needRemove = true;
          }
      }

      m.redraw();
    },
  };

  return Autocomplete;
};

export default getAutocomplete;
