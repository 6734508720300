/* eslint-disable */
import m from 'mithril';
import Orders from 'models/orders';
import modalDecorator from 'decorators/modal';
import resolveTemplate from 'utils/resolveTemplate';
import autoselectInput from './autoselectInput';

// #if process.env.IS_MOBILE == 'true'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
const target = 'root';
const disableScroll = () =>
  [...document.querySelectorAll('.scrollable')].forEach(disableBodyScroll);
// #endif

// #if process.env.IS_MOBILE == 'false'
// // const target = 'panel';
// #endif

const button = {
  view({ attrs }) {
    const { name } = Orders.current.values.city;

    return pug`
      button.inputSubautoselect_buttonOpen(...attrs)= name
    `;
  },
};

const content = {
  // #if process.env.IS_MOBILE == 'true'
  oninit: () => {
    disableScroll();
  },
  onremove: () => {
    clearAllBodyScrollLocks();
  },
  // #endif
  view(vnode) {
    return resolveTemplate('widgets/order-result/floating-panel')({
      state: {
        title: m.trust('Город&nbsp;доставки'),
      },
      actions: {
        ondelete: () => {
          const list = document.getElementsByClassName(
            'autoselectMenu_list',
          )[0];
          if (list) {
            list.remove();
          }
          // #if process.env.IS_MOBILE == 'true'
          clearAllBodyScrollLocks();
          // #endif
          vnode.state.ondelete();
        },
      },
      children: {
        content: m(autoselectInput(vnode.state.ondelete)),
        button: m.trust(''),
      },
    });
  },
};

const autoselect = modalDecorator(
  button,
  content,
  'orderCalculatorDropdown--hide',
  300,
  target,
);

export default {
  view({ attrs }) {
    const { id, name } = Orders.current.values.city;

    return pug`
      .span.inputSubautoselect
        = attrs.label
        = m(autoselect)
    `;
  },
};
