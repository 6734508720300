import m from 'mithril';
import FormModel from 'models/submitOrderForm';
import modalDecorator from 'decorators/modal';
import resolveTemplate from 'utils/resolveTemplate';
import openButton from './button';
import form from './form';

// #if process.env.IS_MOBILE == 'true'
const target = 'root';
// #endif

// #if process.env.IS_MOBILE == 'false'
// // const target = 'panel';
// #endif

let deleteFunc;

const content = {
  oncreate() {
    const input = document.querySelector('.searchCountry_input');
    if (input) {
      deleteFunc = () => {
        this.ondelete();
        FormModel.show = false;
        input.removeEventListener('click', deleteFunc);
      };
      input.addEventListener('click', deleteFunc);
    }
  },
  onremove() {
    const input = document.querySelector('.searchCountry_input');
    if (input) {
      input.removeEventListener('click', deleteFunc);
    }
  },
  view(vnode) {
    return resolveTemplate('widgets/order-result/floating-panel')({
      state: {
        title: FormModel.submited
          ? m.trust('<span class="red">Заявка&nbsp;отправлена</span>')
          : m.trust('Отправка&nbsp;заявки'),
        buttonClassName: FormModel.submited ? 'disabled' : '',
        hideArrow: FormModel.submited,
      },
      actions: {
        ondelete: () => {
          if (!FormModel.submited) {
            vnode.state.ondelete();
            FormModel.show = false;
          }
          const input = document.querySelector('.searchCountry_input');
          if (input) {
            input.removeEventListener('click', deleteFunc);
          }
        },
      },
      children: {
        content: m(form),
        button: m.trust(''),
      },
    });
  },
};

export default modalDecorator(
  openButton,
  content,
  'orderCalculatorDropdown--hide',
  300,
  target,
);
