/* eslint-disable */
import m from 'mithril';

export default {
  view({ attrs }) {
    const {
      id,
      path,
      dataIsLoaded,
      date_max: max,
      date_min: min,
      date_range: range,
      label,
      settings: {
        min_value: minValue,
        max_value: maxValue,
        value,
        disabled: isDisabled,
      },
      updateValue,
      placeholder,
    } = attrs;

    const disabled = !dataIsLoaded || isDisabled;

    if (range) {
      return m(
        'label.inputStandartGroup',
        m('span.inputStandartGroup_label', label),
        m(
          'div.inputStandartGroup_inputs',
          m(
            'div.inputStandart.inputStandart--inline',
            m('input.inputStandart_input[required]', {
              type: 'date',
              max: max > maxValue ? maxValue : max,
              min,
              placeholder,
              disabled,
              value: minValue,
              onchange: ({ target: { value: newValue } }) =>
                updateValue(path.concat('minValue'), newValue),
            }),
            m('div.inputStandart_line'),
          ),
          m('div.inputStandartGroup_separator', ' — '),
          m(
            'div.inputStandart.inputStandart--inline',
            m('input.inputStandart_input[required]', {
              type: 'date',
              max,
              min: min < minValue ? minValue : min,
              placeholder,
              disabled,
              value: maxValue,
              onchange: ({ target: { value: newValue } }) =>
                updateValue(path.concat('maxValue'), newValue),
            }),
            m('div.inputStandart_line'),
          ),
        ),
      );
    }

    return m(
      'label.inputStandart',
      {
        for: `dt_${id}`,
      },
      m('input.inputStandart_input[required]', {
        id: `dt_${id}`,
        type: 'date',
        max,
        min: null,
        value,
        placeholder,
        disabled,
        value,
        onchange: ({ target: { value: newValue } }) =>
          updateValue(path.concat('value'), newValue),
      }),
      m('span.inputStandart_label', {}, label),
      m('div.inputStandart_line'),
    );
  },
};
