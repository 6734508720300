import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import declentionOfNumerical from 'utils/declentionOfNumerical';
import Questions from 'models/questions';
import formatDate from 'utils/formatDate';
import AnswerList from './answer-list';
import AnswerForm from './form';

export default {
  oninit() {
    this.template = resolveTemplate('pages/question');
    document.title = `Ответы на вопросы — Visard`;
  },
  view() {
    const {
      number,
      title,
      body,
      messageCount,
      closedAt,
      createdAt,
    } = Questions.current;

    return this.template({
      state: {
        number: number.toLocaleString(),
        title,
        body,
        messageCount: `${messageCount} сообщени${declentionOfNumerical(
          messageCount,
          ['е', 'я', 'й'],
        )}`,
        date: formatDate(createdAt),
        statusText: closedAt ? 'закрыт' : 'открыт',
        statusClassName: closedAt ? 'opened' : 'closed',
      },
      children: {
        answers: m(AnswerList),
        form: closedAt ? null : m(AnswerForm),
      },
    });
  },
};
