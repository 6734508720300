const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
label.document_button(
  class=${state.attached ? '.document_button--attached' : ''}
  for=state.fileInputName
)
  input.document_input(
    id= state.fileInputName
    name= state.fileInputName
    type="file"
    onchange=actions.onChange
    hidden
    multiple
    accept=state.accepted
  )
  span.document_buttonInner
    if state.attached
      img.document_preview(
        src=state.preview 
        alt=""
      )
    span.document_buttonAttah
      svg.document_buttonAttahIcon(
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25 28"
      )
        path(
          d="M14.1 25.3c-3.4 3.4-8.8 3.6-12 .5s-2.8-8.5.6-11.9L14.7 2c2.5-2.5 6.4-2.7 8.7-.4s2.1 6.2-.4 8.7l-9.7 9.6a3.8 3.8 0 0 1-5.4.3 3.8 3.8 0 0 1 .2-5.5l8.6-8.5 1.5 1.6-8.5 8.5a1.5 1.5 0 1 0 2 2l9.7-9.6a3.8 3.8 0 0 0 .3-5.4 3.8 3.8 0 0 0-5.4.2l-12 12C1.8 18 1.6 21.9 3.9 24s6.2 2.1 8.7-.4L23.4 13l1.6 1.6z"
        )
      br
      if state.attached
        | Заменить
      else
        | Прикрепить
  svg.document_buttonCorner(
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 28"
  )
    path(
      d="M.5 1.2v14.2a12.2 12.2 0 0 0 12.2 12.1h12.2z"
      fill="#fff"
      fillOpacity=".3"
    )
    path(
      d="M26 28H12.7A12.7 12.7 0 0 1 0 15.4V0zM1 2.5v13A11.7 11.7 0 0 0 12.7 27h11z"
    )
  `;
};