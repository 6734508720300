const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
label.personsSettings_item
  select.personsSettingsSelect(onchange=actions.changeAge(state.index))
    for age in state.ages
      option(
        value=age
        key=age
        selected=helpers.equal(age, state.age)
      )= age + helpers.getAgePostfix(age)
  .personsSettings_type
    .personsSettings_icon
      = helpers.getIcon(state.age)
    span.personsSettings_label= state.age + helpers.getAgePostfix(state.age)
  if state.notOnlyChild
    button.personsSettings_remove(onclick=actions.deletePerson(state.index)) Удалить

  `;
};