/* eslint-disable */
import m from 'mithril';
import { API, STORE } from 'constants.js';
import B from 'models/background';
import O from 'models/orders';

import LoadingBar from './loadingBar';

const Countries = {
  prefix: '',
  dimensions: [],
  list: [],
  full: {},
  html: {},
  setDefault: false,

  init(data, args) {
    if (Countries.current) {
      return Promise.resolve();
    }

    if (data) {
      Countries.list = data.list;
      Countries.current = data.current;
      Countries.prefix = data.prefix;
      Countries.html = data.html;
      const { background, id: countryId } = data.current;
      B.addToQueue(background, countryId);
    }

    if (!Countries.current) {
      return Countries.fetchAll().then(() => Countries.takeRandom(args));
    }

    return new Promise(resolve => {
      resolve(Countries.current);
    });
  },

  takeRandom(args = {}) {
    let countryId = args.country_id;

    if (countryId) {
      Countries.setDefault = true;
    }

    if (!countryId) {
      const index = Math.floor(Math.random() * Countries.list.length);
      const { id } = Countries.list[index];
      countryId = id;
    }

    return Countries.setCurrent(+countryId);
  },

  setCurrent(id) {
    if (Countries.current && id === Countries.current.id) {
      return new Promise(resolve => {
        resolve(Countries.current);
      });
    }

    return Countries.fetch(id)
      .then(country => {
        Countries.current = country;
        return Countries.current;
      })
      .then(country => {
        const { background, id: countryId } = country;
        B.addToQueue(background, countryId);
        Countries.fetchHTML(countryId);

        if (Countries.setDefault) {
          O.setCountry(countryId);
        }

        return country;
      })
      .catch(e => {
        throw new Error(e.code);
      });
  },

  fetchAll(data) {
    if (data) {
      Countries.list = data.list;
      Countries.prefix = data.prefix;
      Countries.html = data.html;
    }

    if (Countries.list.length) {
      return new Promise(resolve => {
        resolve(Countries.list);
      });
    }

    LoadingBar.start();
    return m
      .request({
        url: `${API}/countries/`,
      })
      .then(({ data: countries, image_url: prefix }) => {
        LoadingBar.finish();
        Countries.list = countries;
        Countries.prefix = prefix;

        return countries;
      })
      .finally(LoadingBar.finish);
  },

  fetch(id) {
    LoadingBar.start();

    if (Countries.full[id]) {
      return Promise.resolve(Countries.full[id]);
    }

    return m
      .request({
        url: `${API}/countries/${id}`,
        params: {
          device_type: STORE.isMobile ? 'mobile' : 'desktop',
        },
      })
      .then(({ data: country }) => {
        Countries.full[id] = country;
        return country;
      })
      .finally(LoadingBar.finish);
  },

  fetchHTML(id) {
    if (Countries.html[+id]) {
      return Promise.resolve();
    }
    Countries.html[+id] = true;
    LoadingBar.start();

    return m
      .request({
        url: `${API}/countries/${id}/pug`,
      })
      .then(({ data: { pug } }) => {
        Countries.html[+id] = pug;
      })
      .finally(LoadingBar.finish);
  },

  getData() {
    if (!Countries.list.length) {
      return null;
    }
    return {
      list: Countries.list,
      current: Countries.current,
      prefix: Countries.prefix,
      html: Countries.html,
    };
  },
};

if (window.models) {
  window.models.Countries = Countries;
}

window.getCurrentCountry = function() {
  return Countries.current;
};

export default Countries;
