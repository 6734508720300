const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
Link(
  key=state.id
  data-countryid=state.id
  data-pricefrom=state.price.replace(/[^-0-9]/gim,'')
  href=state.link
  data-type=state.size
  className=state.itemClassName
)
  article
    div.gridCountry_itemImgWrap
      img.gridCountry_itemImg(
        src=state.image
        alt=state.name
      )
    div.gridCountry_itemCaption
      h3(
        className=state.linkClassName
      )= state.name
      p.gridCountry_itemPrice
        if state.details_cost.length>0
          button.gridCountry_itemInfo(onclick=actions.onCostClick)= state.price
          span.gridCountry_itemDetails(onclick=actions.onDetailsClick)
            span.gridCountry_itemDetails_wrap Стоимость включает:
              ul.gridCountry_itemDetailsList
                for cost in state.details_cost
                  li.gridCountry_itemDetailsListItem(key=cost.name)
                    span.gridCountry_itemDetailsListItemSpan= cost.name + ' '
                    b.gridCountry_itemDetailsListItemSpan= cost.cost.toLocaleString() + ' ₽'
        else
          = state.price

  `;
};