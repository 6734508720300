import m from 'mithril';
import modalDecorator from './modal';

const getPosition = (button, content, offsetY) => {
  const anchorPosition = {
    top: button.y + button.height + offsetY,
    left: button.x + button.width / 2,
  };

  const transformOrigin = content.width / 2 - ((content.width / 2) % 0.5);

  const contentPosition = {
    top: anchorPosition.top,
    left: anchorPosition.left - transformOrigin + window.pageXOffset,
  };

  return {
    top: `${contentPosition.top}px`,
    left: `${contentPosition.left}px`,
    transformOrigin: `${transformOrigin}px`,
    width: `${content.width}px`,
  };
};

const popoverDecorator = (anchor, content, hideClass, hideTransition) => {
  let wrapperBoundings;
  let offset;
  const { view, ...compiled } = modalDecorator(
    {
      ...anchor,
      oncreate({ dom }) {
        wrapperBoundings = dom.getBoundingClientRect();
        offset = window.pageYOffset;
      },
      onupdate({ dom }) {
        wrapperBoundings = dom.getBoundingClientRect();
        offset = window.pageYOffset;
      },
    },
    {
      ...content,
      oncreate({ dom }) {
        this.boundings = dom.getBoundingClientRect();
        m.redraw();
      },
      onupdate({ dom }) {
        this.boundings = dom.getBoundingClientRect();
      },
      view(vnode) {
        if (!vnode.state.boundings) {
          return content.view(vnode);
        }

        return content.view({
          ...vnode,
          attrs: {
            getStyles: () =>
              getPosition(wrapperBoundings, vnode.state.boundings, offset),
          },
        });
      },
    },
    hideClass,
    hideTransition,
    'root',
  );

  return {
    ...compiled,
    view(vnode) {
      return view(vnode);
    },
  };
};

export default popoverDecorator;
