// eslint-disable-next-line
export default (obj, path, value) => {
  // eslint-disable-next-line
  eval(
    `obj${path
      .split('.')
      .map(key => {
        const int = parseInt(key, 10);
        if (int && `${int}` === key) {
          return `[${key}]`;
        }
        return `['${key}']`;
      })
      .join('')}=value`,
  );
};
