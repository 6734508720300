const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
#scrollbar(onwheel=actions.onscroll)
  .scroller
    .orderCalculator_tuningInner
      = children.controls
  = children.submit

  `;
};