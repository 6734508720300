import withWrapper from './withWrapper';

export const puntoSwitcher = string => {
  const switchEn = char =>
    /[a-z]/.test(char)
      ? 'фисвуапршолдьтщзйкыегмцчня'[char.charCodeAt(0) - 97]
      : char;

  const swithSpecials = char => {
    const index = "[];'\\,.".indexOf(char);
    return index + 1 ? 'хъжэёбю'[index] : char;
  };

  const output = string
    .toLowerCase()
    .split('')
    .map(switchEn)
    .map(swithSpecials);

  return output
    .map((char, index) =>
      index === 0 || output[index - 1] === ' ' ? char.toUpperCase() : char,
    )
    .join('')
    .trimLeft();
};

export const withPuntoSwitcher = withWrapper(puntoSwitcher);
