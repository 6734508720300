import Questions from 'models/questions';

const intersectionObserver = {
  observer: null,

  init(target) {
    intersectionObserver.observer = new window.IntersectionObserver(
      intersectionObserver.callback,
      {
        root: target,
        rootMargin: '0px',
        threshold: 1.0,
      },
    );
  },
  disconnect() {
    intersectionObserver.observer.disconnect();
  },
  callback(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        intersectionObserver.unobserve(entry.target);
        Questions.setAnswerReadedStatus(parseInt(entry.target.dataset.key, 10));
      }
    });
  },

  unobserve(target) {
    intersectionObserver.observer.unobserve(target);
  },
  observe(target) {
    intersectionObserver.observer.observe(target);
  },
};

export default intersectionObserver;
