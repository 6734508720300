const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
// div.accentTypeFirst=children.content
div.accent=children.content
  `;
};