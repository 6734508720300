import User from 'models/user';
import resolveTemplate from 'utils/resolveTemplate';
import formatDate from 'utils/formatDate';
import intersectionObserver from './intersectionObserver';

export default {
  oninit() {
    this.template = resolveTemplate('pages/question/answer-list/item');
  },
  oncreate(vnode) {
    if (vnode.attrs.answer.unreaded) {
      intersectionObserver.observe(vnode.dom);
    }
  },
  onremove(vnode) {
    intersectionObserver.unobserve(vnode.dom);
  },
  view(vnode) {
    const {
      key: answerId,
      answer: { body, userId, createdAt, unreaded },
    } = vnode.attrs;

    return this.template({
      state: {
        body,
        answerId,
        type: userId === User.current.id ? 'question' : 'answer',
        datetime: formatDate(createdAt, 'D MMMM YYYY HH:mm'),
        unreaded: unreaded ? 'unreaded' : '',
      },
    });
  },
};
