import c from 'classnames';
import FormModel from 'models/submitOrderForm';
import resolveTemplate from 'utils/resolveTemplate';
// #if process.env.IS_MOBILE == 'true'
import { ScrollListener } from 'models/mobileMenu';
// #endif

const submitDisabled = () => !FormModel.buttonEnabled;
const formSubmited = () => FormModel.submited;
const formShow = () => FormModel.show;
const subtextClass = () =>
  FormModel.show && !FormModel.submited ? '' : 'issueVisaForm_subtext—-hide';

export default {
  view({ attrs }) {
    return resolveTemplate('widgets/order-result/submit')({
      state: {
        attrs: {
          ...attrs,
          className: c(
            ['buttonStandart', 'issueVisaForm_button'],
            // #if process.env.IS_MOBILE == 'true'
            {
              'issueVisaForm_button--hide': ScrollListener.status === 4,
            },
            // #endif
          ),
        },
        formShow,
        formSubmited,
        submitDisabled,
        subtextClass,
      },
      actions: {
        onSubmit: FormModel.onSubmit,
      },
    });
  },
};
