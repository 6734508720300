const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
.homeSelectCountry_title_wrapper
  h1.homeSelectCountry_title.homeSelectCountry_title--first
    | Поможем${'\u00A0'}быстро оформить${'\u00A0'}визу
  h2.homeSelectCountry_title.homeSelectCountry_title--second
    | Это${'\u00A0'}лучший${'\u00A0'}способ насладиться${'\u00A0'}поездкой

  `;
};