/* eslint-disable */

export const API_PATH = '/api/v1';

// #if process.env.IS_DEVELOPMENT == 'true'
const domain = 'https://api.dev.visa.gridpix.ru';
// #endif

// #if process.env.IS_DEVELOPMENT == 'false'
// // const domain = 'https://api.visa.gridpix.ru';
// #endif

export const DOMAIN = domain;

const apiChoice = {};
if (USE_STUBS) {
  apiChoice.api = '/api/stubs';
  apiChoice.wsapi = 'ws://localhost:9003/';
} else {
  apiChoice.api = DOMAIN + API_PATH;
  apiChoice.wsapi = `ws://api.dev.visa.gridpix.ru:3000/ws`;
}

export const API = apiChoice.api;
export const WSAPI = apiChoice.wsapi;
export const STORE = {
  topMenuLinks: [
    {
      text: 'Оформление визы',
      url: '/',
      id: 'headerGetVisa',
    },
    {
      text: 'О сервисе',
      url: '/o-servise',
      id: 'headerAboutService',
    },
    {
      text: 'Виза для россиян',
      url: '/viza-dlja-rossijan',
      id: 'headerRussianVisa',
    },
    {
      text: 'Задать вопрос',
      url: '/faq',
      id: 'headerFaq',
    },
  ],
  footerLinks: [
    {
      text: 'О сервисе',
      url: '/o-servise',
      id: 'footerAboutService',
    },
    {
      text: 'Контакты',
      url: '/kontakty',
      id: 'footerContacts',
    },
    {
      text: 'Пользовательское соглашение',
      url: '/polzovatelskoe-soglashenie',
      id: 'footerAgreement',
    },
  ],
};

STORE.titles = [
  { text: '', url: '/' },
  { text: 'Страница страны', url: '/countries' },
  { text: 'История заказов', url: '/orders' },
  ...STORE.topMenuLinks,
  ...STORE.footerLinks,
].reduce(
  (result, { text, url }) => ({
    ...result,
    [url]: text,
  }),
  {},
);

STORE.WebPSupport = false;

const WebP = new window.Image();

WebP.onload = () => {
  const isSupported = WebP.height === 2;

  if (isSupported) {
    STORE.WebPSupport = true;
  }
};

WebP.src =
  'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAg' +
  'CdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';

// #if process.env.IS_MOBILE == 'true'
STORE.isMobile = true;
// #endif

// #if process.env.IS_MOBILE == 'false'
// // STORE.isMobile = false;
// #endif
