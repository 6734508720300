import m from 'mithril';

import Layout from 'components/layout';
import Callback from 'components/pages/callback';
import Country from 'components/pages/country';
import CountryList from 'components/pages/country-list';
import Order from 'components/pages/order';
import OrderList from 'components/pages/order-list';
import QuestionList from 'components/pages/question-list';
import Question from 'components/pages/question';
import QuestionCreate from 'components/pages/question-create';
import About from 'components/pages/about';
import Contacts from 'components/pages/contacts';
import Reviews from 'components/pages/reviews';
import Article from 'components/pages/article';
import ArticlesList from 'components/pages/articles-list';
import NewsList from 'components/pages/news-list';
import NotReady from 'components/pages/notReady';

// import Banner from 'components/widgets/banner';

// #if process.env.IS_MOBILE == 'false'
// // import Home from 'components/pages/home';
// // import 'styles/desktop.styl';
// #endif
// #if process.env.IS_MOBILE == 'true'
import Home from 'components/mobile/pages/home';
import 'styles/mobile.styl';

if (!document.getElementById('mobile_meta')) {
  const meta = document.createElement('meta');
  meta.setAttribute('id', 'mobile_meta');
  meta.setAttribute('name', 'viewport');
  meta.setAttribute('content', 'width=device-width, maximum-scale=1');
  document.head.appendChild(meta);
}
// #endif

const withLayout = (template, whiteTheme = false, footerShow = true) =>
  Layout(
    {
      view() {
        return m.fragment({}, [m(template)]);
      },
    },
    whiteTheme,
    footerShow,
  );

export const CallbackPage = withLayout(Callback);
export const HomePage = withLayout(Home, true);
export const OrderListPage = withLayout(OrderList);
export const OrderPage = withLayout(Order, true, false);
export const QuestionListPage = withLayout(QuestionList);
export const QuestionPage = withLayout(Question);
export const QuestionCreatePage = withLayout(QuestionCreate);
export const CountryListPage = withLayout(CountryList);
export const CountryPage = withLayout(Country, true);
export const AboutPage = withLayout(About, true);
export const ContactsPage = withLayout(Contacts);
export const ArticlePage = withLayout(Article);
export const ArticlesListPage = withLayout(ArticlesList);
export const NewsListPage = withLayout(NewsList);
export const ReviewsPage = withLayout(Reviews);
export const NotReadyPage = withLayout(NotReady);
