const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
details.faqList_item
  summary.faqList_itemTitle(onclick=actions.onClick)= m.trust(state.question)
  p.faqList_itemAnswer= m.trust(state.answer)

  `;
};