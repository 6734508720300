import resolveTemplate from 'utils/resolveTemplate';

export default {
  oninit() {
    this.template = resolveTemplate('pages/question-create/faq/item');
  },
  view(vnode) {
    const {
      question: { question, answer },
      onUpdate = () => {},
    } = vnode.attrs;

    return this.template({
      actions: {
        onClick: onUpdate,
      },
      state: {
        question,
        answer,
      },
    });
  },
};
