import m from 'mithril';
import debounce from 'lodash/debounce';
import { API } from 'constants.js';

const addressModel = (value, callback) => {
  const state = {
    value,
    query: '',
    list: [],
  };

  const search = debounce(
    () =>
      m
        .request({
          url: `${API}/dictionaries/1`,
          params: {
            q: state.queery,
            per_page: 10,
            page: 1,
          },
        })
        .then(({ data }) => {
          state.list = data;
        }),
    700,
  );

  const choise = id => {
    state.value = state.list.find(item => item.id === id);
    state.list = [];
    state.queery = '';
    callback(state.value);
  };

  return {
    search: ({ target: { value: inputValue } }) => {
      state.queery = inputValue;
      search();
    },
    choise: ({
      target: {
        dataset: { id },
      },
    }) => {
      choise(+id);
    },
    set: newValue => {
      state.value = newValue;
    },
    state,
  };
};

export default {
  oninit({ attrs }) {
    const {
      settings: { city: value },
      updateValue,
      path,
    } = attrs;

    this.model = addressModel(value, newValue =>
      updateValue(path.concat('city'), newValue),
    );
  },
  onbeforeupdate({ attrs: { value } }, { attrs: { value: oldValue } }) {
    if (value !== oldValue) {
      this.model.set(value);
    }
  },
  view({ attrs }) {
    /* eslint-disable */
    const {
      id,
      dataIsLoaded,
      label,
      placeholder,
      settings: { city: value, disabled },
    } = attrs;

    return m('div', [
      m('label', label),
      this.model.state.value
        ? m(
            'div',
            'Выбрано: ',
            this.model.state.value.title,
            ' - ',
            this.model.state.value.region,
          )
        : null,
      m('input', {
        type: 'text',
        oninput: this.model.search,
        placeholder: placeholder || 'Поиск',
      }),
      this.model.state.list.length
        ? m(
            'ul',
            this.model.state.list.map(item =>
              m(
                'li',
                m(
                  'button',
                  { 'data-id': item.id, onclick: this.model.choise },
                  item.title,
                  ' - ',
                  item.region,
                ),
              ),
            ),
          )
        : null,
    ]);
  },
};
