import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';

const templates = ['accented', 'h1', 'h2', 'h3', 'h4', 'p'].reduce(
  (result, path) => ({
    ...result,
    [path]: resolveTemplate(`widgets/info-block/${path}`),
  }),
  {
    html: state => m.trust(state.content),
  },
);

const getComponent = ({ accent, type, content, params }) => ({
  accent,
  content: templates[type]({
    state: { content, params },
  }),
});

const groupAccented = (components, current) => {
  if (current.accent) {
    const lastIndex = components.length - 1;
    const isAggregator = component => Array.isArray(component);

    if (isAggregator(components[lastIndex])) {
      components[lastIndex].push(current.content);
    } else {
      components.push([current.content]);
    }

    return components;
  }

  return [...components, current.content];
};

const wrapAccented = component => {
  const isAccent = Array.isArray(component);

  return isAccent
    ? templates.accented({
        children: {
          content: component,
        },
      })
    : component;
};

export default {
  view(vnode) {
    const { settings = [] } = vnode.attrs;

    return m.fragment(
      {},
      settings
        .map(getComponent)
        .reduce(groupAccented, [])
        .map(wrapAccented),
    );
  },
};
