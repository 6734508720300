const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
button.orderCalculatorDropdown_headerButton.buttonStandart.buttonStandart--small(
  onclick=actions.addPerson
)
  span.buttonStandart_name Добавить

  `;
};