const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
if state.isOrderFetched
  .orderCalculator.grow(className=state.className)
    = children.popupPanel
    .orderCalculator_side.orderCalculator_side--tuning
      = children.form
    = children.result

  `;
};