import m from 'mithril';
import cx from 'classnames';
import resolveTemplate from 'utils/resolveTemplate';
import Orders from 'models/orders';
import CountUp from 'countup.js';
import FormModel from 'models/submitOrderForm';
// #if process.env.IS_MOBILE == 'true'
import { MenuModel } from 'models/mobileMenu';
// #endif

// #if process.env.IS_MOBILE == 'true'
import applicants from './applicants/openButton';
// #endif
// #if process.env.IS_MOBILE == 'false'
// // import applicants from './applicants';
// #endif

import submit from './submit';

// #if process.env.IS_MOBILE == 'true'
const applicantsAttrs = {
  onclick: () => MenuModel.openMenu('applicants'),
};
// #endif
// #if process.env.IS_MOBILE == 'false'
// // const applicantsAttrs = {};
// #endif

export default {
  oninit() {
    this.template = resolveTemplate('pages/home/order/panel/result');
    this.currentPrice = Orders.current.values.price;
    FormModel.show = false;
  },
  onbeforeupdate() {
    if (this.currentPrice !== Orders.current.values.price) {
      const countup = new CountUp(
        document.getElementById('price'),
        this.currentPrice,
        Orders.current.values.price,
        0,
        1,
        {
          useEasing: true,
          useGrouping: true,
          separator: ' ',
          decimal: '',
          suffix: ' ₽',
        },
      );
      if (!countup.error) {
        countup.start();
      } else {
        // eslint-disable-next-line
        console.error(countup.error);
      }
      this.currentPrice = Orders.current.values.price;
    }
    return true;
  },
  view() {
    return this.template({
      state: {
        id: Orders.current.country,
        price: Orders.current.values.price.toLocaleString(),
        conculPrice: Orders.current.values.consul_price.toLocaleString(),
        peopleCount: Orders.current.values.persons.length,
        className: cx({
          'orderCalculator_side--fetching': Orders.fetchingValues,
        }),
      },
      children: {
        applicants: m(applicants, applicantsAttrs),
        submit: m(submit),
      },
    });
  },
};
