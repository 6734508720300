import m from 'mithril';
import { API } from 'constants.js';
import LoadingBar from './loadingBar';

class NotificationsClass {
  constructor() {
    this.items = [];
  }

  hasUnread = () => this.items.some(item => !item.read);

  fetchNotifications() {
    LoadingBar.start();
    return m
      .request(`${API}/notifications`, { withCredentials: true })
      .then(notifications => {
        LoadingBar.finish();
        this.items = notifications;
      })
      .catch(() => {});
  }

  setReaded(id) {
    LoadingBar.start();
    m.request({
      method: 'PUT',
      url: `${API}/notifications/${id}`,
      withCredentials: true,
    }).then(() => {
      LoadingBar.finish();
      this.fetchNotifications();
    });
  }

  delete(id) {
    LoadingBar.start();
    m.request({
      method: 'DELETE',
      url: `${API}/notifications/${id}`,
    }).then(() => {
      LoadingBar.finish();
      this.fetchNotifications();
    });
  }

  clear() {
    this.items = [];
  }
}

const Notifications = new NotificationsClass();

// #if process.env.IS_DEVELOPMENT == 'true'
if (window.models) {
  window.models.Notifications = Notifications;
}
// #endif

export default Notifications;
