const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div.document
  div.document_buttonWrap
    = children.dropZone
    if state.attached
      button.document_remove
        svg.document_removeIcon(
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 28"
        )
          path(
            d="M21 7.8h-3.5v-.5a2 2 0 0 0-2.1-1.8h-2.8a2 2 0 0 0-2.1 1.8v.5H7V10h1.2a5 5 0 0 0 0 .6v9c0 1.5.7 2.9 2.3 2.9h7c1.6 0 2.4-1.4 2.4-2.9v-9a5 5 0 0 0 0-.6H21zm-9.3-.5c0-.4.4-.7 1-.7h2.7c.5 0 1 .3 1 .7v.5h-4.7v-.5zm7 12.4c0 .8-.2 1.7-1.2 1.7h-7c-1 0-1.2-.9-1.2-1.7v-9.1a4.7 4.7 0 0 1 0-.6h9.3a4.7 4.7 0 0 1 0 .6v9zm-8.2.5h1.2v-9h-1.2zm3 0h1v-9h-1zm2.8 0h1.2v-9h-1.2z"
          )
        | Удалить
  - var status = 'noattached'
  - var statustext = 'Не загружен'
  if state.attached && !state.verification && !state.verified
    - status = 'attached'
    - statustext = 'Не проверен'
  else if state.verification
    - status = 'verification'
    - statustext = 'Проверяется'
  else if state.verified
    - status = 'verified'
    - statustext = 'Проверен'
  span.document_status(
    className='.document_status--'+status
  )
    =statustext
  span.document_label
    if state.href
      a.document_link(
        href=state.href
      )
        span.line=state.name
    else 
      =state.name



  `;
};