/* global progressJs */
const LoadingBar = {
  progressBar: progressJs(),
  activeLoads: 0,
  finishedLoads: 0,

  update() {
    if (LoadingBar.finishedLoads === LoadingBar.activeLoads) {
      LoadingBar.progressBar.end();
    } else {
      const progress = LoadingBar.finishedLoads / LoadingBar.activeLoads;
      LoadingBar.progressBar.set(progress ? progress * 100 : 10);
    }
  },

  start(count = 1) {
    if (LoadingBar.activeLoads === LoadingBar.finishedLoads) {
      LoadingBar.progressBar.start();
    }

    LoadingBar.activeLoads += count;
    LoadingBar.update();
  },

  finish() {
    setTimeout(() => {
      if (LoadingBar.finishedLoads === LoadingBar.activeLoads) {
        return;
      }

      LoadingBar.finishedLoads += 1;
      LoadingBar.update();
    }, 50);
  },
};

// #if process.env.IS_DEVELOPMENT == 'true'
if (window.models) {
  window.models.LoadingBar = LoadingBar;
}
// #endif

export default LoadingBar;
