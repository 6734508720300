const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
//- Класс для белого варианта .footerPage--whiteStyle
footer.footerPage
  span.copyright= '© 2011–2020, VISARD'
  nav.footerPage_menu.menuTypeFirst
    each link in state.links
      Link.menuTypeFirst_item(
        key=link.id
        href=link.url
        id=link.id
      )
        span.line= link.text
  address.authors
    each author, index in state.authors
      a(
        key=index
        href=author.url
        target="_blank"
        class=${`authors_item ${author.text.toLowerCase()}`}
      )
        span.authors_subtitle= ${`${author.subtitle} — `}
        b.authors_title= author.text
        if author.icon
          ${author.icon}

  `;
};