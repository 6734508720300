/* eslint-disable */
import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import Background from 'models/background';
import { STORE } from 'constants.js';

export default {
  oninit() {
    this.template = ({ state, helpers }) =>
      m('div', [
        state.image.preview.insert
          ? [
              m('img.homeSelectCountry_img', {
                src: state.image.blur,
                class: state.image.preview.show
                  ? 'homeSelectCountry_img--loaded'
                  : '',
                onload: state.image.setLoaded('preview'),
              }),
              state.image.full.insert
                ? m('img.homeSelectCountry_img', {
                    src: helpers.getSource(state.image.sources),
                    class: state.image.full.show
                      ? 'homeSelectCountry_img--loaded'
                      : '',
                    onload: state.image.setLoaded('full'),
                  })
                : null,
            ]
          : null,
      ]);
  },
  view() {
    return m.fragment(
      {},
      [...Background.images].map(image =>
        this.template({
          state: { image },
          helpers: {
            getSource: sources => {
              const sourceSet =
                STORE.WebPSupport && sources.webp.src_set.length
                  ? sources.webp.src_set
                  : sources.jpeg.src_set;
              let url;
              let neededWidth =
                document.body.clientWidth * window.devicePixelRatio;

              if (neededWidth < 1280) {
                neededWidth = 1280;
              }

              sourceSet.some((source, index) => {
                const [sourceUrl, widthString] = source.split(' ');
                const width = parseInt(widthString, 10);

                if (width >= neededWidth || index === sourceSet.length - 1) {
                  url = sourceUrl;
                  return true;
                }

                return false;
              });

              return url;
            },
          },
        }),
      ),
    );
  },
};
