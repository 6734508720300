const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
li.historyOrders_item
  Link.historyOrders_itemLink(
    href=state.href
  )
    div.historyOrders_itemHeader.spriteFlags.middleFlag(
      className=state.flag
    )
      div.historyOrders_itemHeaderInner
        h2.historyOrders_itemCountry= state.title
        span.historyOrders_itemId 
          | № 
          = state.number
    div.historyOrders_itemContent.fadeSides.fadeSides--y
      div.historyOrders_itemContentInner.gpScroll.gpScroll--y
        h3.pMiddle Заявители
        p.pMiddle= state.persons
        h3.pMiddle Что входит
        p.pMiddle= state.content
    div.historyOrders_itemFooter
      if (state.payed>0)
        h3.pMiddle Оплачено
        span.historyOrders_itemCost= state.payed
      span.historyOrders_itemStatus(
        className='historyOrders_itemStatus--'+state.dateClassName
      )
        = state.dateText

  `;
};