const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div.issueVisaForm
  if !state.formShow()
    button#submitOrder(
      ...state.attrs
    )
      span.buttonStandart_name Продолжить оформление
  else
    if state.formSubmited()
      button.buttonStandart.issueVisaForm_button
        span.buttonStandart_name Отправлено
    else
      button.buttonStandart.issueVisaForm_button(
        disabled=state.submitDisabled()
        onclick=actions.onSubmit
      )
        span.buttonStandart_name Отправить
  p.issueVisaForm_subtext(className=state.subtextClass()) Нажимая соответствующую кнопку «Отправить», вы${'\u00A0'}подтверждаете, что предоставляете компании ООО${'\u00A0'}«Визард» своё согласие на обработку ваших персональных данных


  `;
};