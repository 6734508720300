import Modal from 'models/modal';

const vfn = () => {};

const contentDecorator = (
  content,
  hiddenClass = 'hide',
  hideTransition = 200,
  ondelete,
) => ({
  ...content,

  oninit() {
    if (content.oninit) {
      content.oninit();
    }
    this.ondelete = ondelete;
  },
  onbeforeremove({ dom }) {
    dom.classList.add(hiddenClass);

    return new Promise(resolve => {
      setTimeout(resolve, hideTransition);
    });
  },
});

const modalDecorator = (
  { view, oninit = vfn, ...anchor },
  content,
  hiddenClass,
  hideTransition,
  section = 'root',
) => {
  let ondelete = null;
  let currentVnode;

  const callDeleteFunc = () => {
    currentVnode.state.opened = false;
    if (ondelete) {
      ondelete();
    }
  };

  return {
    // TODO: fix bug oninit on anchor
    oninit(vnode) {
      oninit(vnode);
      this.opened = false;
    },
    onremove: () => {
      callDeleteFunc();
    },
    view({ children, ...vnode }) {
      const { attrs: { onkeydown = vfn, onclick = vfn, ...attrs } = {} } = view(
        vnode,
      );

      currentVnode = vnode;

      const tryOpen = () => {
        if (!vnode.state.opened) {
          vnode.state.opened = true;

          ondelete = Modal.add(
            contentDecorator(
              content,
              hiddenClass,
              hideTransition,
              callDeleteFunc,
            ),
            section,
          );

          vnode.state.ondelete = ondelete;
        }
      };

      return view({
        attrs: {
          ...attrs,
          open: vnode.state.opened,
          onkeydown(e) {
            tryOpen();
            onkeydown(e);
          },
          onclick(e) {
            e.stopPropagation();
            tryOpen();
            onclick(e);
          },
        },
        children,
      });
    },
    ...anchor,
  };
};

export default modalDecorator;
