const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
aside.personsSettings(
  tabindex='-1'
  aria-label='Количество заявителей'
  className=state.wrapperClass
)
  = children.personList
  label.buttonStandart.personsSettings_buttonAdd(onclick=actions.addPerson)
    span.buttonStandart_name Добавить заявителя
    select.personsSettings_select(
      onchange=actions.changeAge
      onblur=actions.onBlur
    )
      for age in state.ages
        option(
          value=age
          key=age
          selected=helpers.selected(age)
        )= age + helpers.getAgePostfix(age)

  `;
};