import m from 'mithril';
import User from 'models/user';
import resolveTemplate from 'utils/resolveTemplate';
import Input from 'components/widgets/input';

const AuthForm = {
  data: { username: '', password: '' },
  errorMessage: '',
  getErrorMessage: () => AuthForm.errorMessage,
  setErrorMessage: () => {
    AuthForm.errorMessage = User.getErrorMessage();
    return null;
  },
  setUsername: ({ target: { value: username } }) => {
    AuthForm.data.username = username;
  },
  setPassword: ({ target: { value: password } }) => {
    AuthForm.data.password = password;
  },
  login: closeModal => {
    User.login({
      username: AuthForm.data.username,
      password: AuthForm.data.password,
    })
      .then(() => {
        closeModal();
        AuthForm.errorMessage = '';
      })
      .catch(() => {
        AuthForm.setErrorMessage();
        return null;
      });
  },
};

export default closeModal =>
  resolveTemplate('layout/modal/authForm')({
    state: {
      errorMessage: AuthForm.getErrorMessage(),
    },
    actions: {
      login: () => AuthForm.login(closeModal),
      keyDown: e => {
        if (e.key === 'Enter') {
          AuthForm.login(closeModal);
        }
        if (e.key === 'Escape') {
          closeModal();
        }
      },
      onSubmit: e => e.preventDefault(),
    },
    children: {
      usernameInput: m(Input, {
        label: 'Имя пользователя',
        placeholder: '',
        value: AuthForm.username,
        oninput: AuthForm.setUsername,
        onremove: () => {
          // TODO: Clean this dirty
          AuthForm.errorMessage = '';
        },
        name: 'username',
        id: 'username',
      }),
      passwordInput: m(Input, {
        label: 'Пароль',
        placeholder: '',
        value: AuthForm.password,
        oninput: AuthForm.setPassword,
        name: 'password',
        id: 'password',
      }),
    },
  });
