/* eslint-disable */

import m from 'mithril';
import { API } from 'constants.js';
import query from 'utils/query';
import set from 'utils/set';
import Persons from './persons';
import Orders from './orders';

const Questionaries = {
  current: {},
  id: null,
  dataIsLoaded: true,

  fetch: query(
    (id, personId = '') =>
      `${API}/questionnaires?order_id=${id}&person_id=${personId}`,
    data => {
      Questionaries.current.groups = [
        ...new Set(data.map(item => item.question_group.id)),
      ].reduce(
        (res, id) => ({
          ...res,
          [id]: {
            id,
            title: data.find(item => item.question_group.id === id)
              .question_group.name,
            fields: data.filter(item => item.question_group.id === id),
          },
        }),
        {},
      );
    },
  ),

  fetchValues: query(
    (id, personId = '') => {
      Questionaries.id = id;

      return `${API}/questionnaires/values/${personId || ''}?order_id=${id}`;
    },
    data => {
      Questionaries.current.values = data;
      return Questionaries.fetch(Questionaries.id, Persons.current);
    },
  ),

  getControls: tab => Questionaries.current.groups[tab].fields,

  getSettings: path => {
    const { questions } = Questionaries.current.values;
    // console.log(questions);

    if (questions) {
      switch (path.length) {
        case 1:
          return questions[path[0]];
        case 2: {
          const item = questions[path[0]];
          if ('questions' in item) {
            return item.questions[path[1]];
          }

          return item.list[path[1]];
        }
        default:
          console.error('HELP', path);
          return {};
      }
    } else {
      return {};
    }
  },

  updateValue(path, value) {
    set(Questionaries.current.values.questions, path.join('.'), value);
    Questionaries.dataIsLoaded = false;
    m.redraw();

    return m
      .request(`${API}/questionnaires/values/`, {
        method: 'PUT',
        body: Questionaries.current.values,
        withCredentials: true,
      })
      .then(data => {
        Questionaries.current.values = data;
        return Promise.all([
          Questionaries.fetch(Questionaries.id, Persons.current),
          Orders.fetch(Questionaries.id),
        ]);
        // return Questionaries.fetch(Questionaries.id, Persons.current);
      })
      .finally(() => {
        Questionaries.dataIsLoaded = true;
      });
  },
};

Questionaries.init = id => {
  Promise.all([
    Questionaries.fetchValues(id),
    Questionaries.fetch(id, Persons.current),
  ]);
};

// #if process.env.IS_DEVELOPMENT == 'true'
if (window.models) {
  window.models.Questionaries = Questionaries;
}
// #endif

export default Questionaries;
