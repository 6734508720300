/* eslint-disable */

import m from 'mithril';
import LoadingBar from 'models/loadingBar';

export default (getUrl, callback, getParams) => (...args) => {
  LoadingBar.start();

  const url = typeof getUrl === 'string' ? getUrl : getUrl(...args);
  var params = typeof getParams === 'function' ? getParams(...args) : getParams;

  if (params === undefined) {
    params = {};
  }

  params.withCredentials = true;

  // console.log(params);

  // console.log(url);
  return m
    .request(url, params)
    .then(data => {
      callback(data);
      return data;
    })
    .finally(LoadingBar.finish);
};
