/* eslint-disable */
import m from 'mithril';
// #if process.env.IS_MOBILE == 'true'
import { ScrollListener } from 'models/mobileMenu';
// #endif
// #if process.env.IS_MOBILE == 'false'
// // import IScroll from 'iscroll/build/iscroll-probe.js';
// #endif
import resolveTemplate from 'utils/resolveTemplate';
import control from './form-control';

// #if process.env.IS_MOBILE == 'false'
// // let delta = 0;
// // const submitButton = {
// //   view() {
// //     return null;
// //   },
// // };
// #endif

// #if process.env.IS_MOBILE == 'true'
const button = {
  onbeforeremove({ dom }) {
    dom.classList.add('orderCalculator_issueVisaFormFixedWrap--hide');

    return new Promise(resolve => {
      setTimeout(resolve, 500);
    });
  },
  view() {
    return pug`
      .orderCalculator_issueVisaFormFixedWrap
        button.buttonStandart.issueVisaForm_button(
          onclick=() => document.getElementById('submitOrder').click()
        )
          span.buttonStandart_name Продолжить оформление
    `;
  },
};

const submitButton = {
  view() {
    return ScrollListener.status === 4 ? m(button) : null;
  },
};
// #endif

export default {
  oninit() {
    this.template = resolveTemplate('widgets/order-form');

    // #if process.env.IS_MOBILE == 'false'
// //     let lastY = 0;
// //     this.onscroll = () => {
// //       if (lastY === this.scroller.y) {
// //         window.scrollBy(0, delta);
// //       } else {
// //         lastY = this.scroller.y;
// //       }
// //     };
    // #endif
  },
  oncreate({ dom }) {
    // #if process.env.IS_MOBILE == 'false'
// //     this.scroller = new IScroll(dom, {
// //       mouseWheel: true,
// //       scrollbars: 'custom',
// //       probeType: 2,
// //       interactiveScrollbars: true,
// //     });
// // 
// //     this.addFocus = () => {
// //       dom.children[1].children[0].classList.add('focus');
// //     };
// // 
// //     this.removeFocus = () => {
// //       dom.children[1].children[0].classList.remove('focus');
// //       delta = 0;
// //     };
// // 
// //     this.scroller.on('scroll', this.onscroll);
// //     this.scroller.on('scrollStart', this.addFocus);
// //     this.scroller.on('scrollEnd', this.removeFocus);
    // #endif
    // #if process.env.IS_MOBILE == 'true'
    const f = () => {};
    f(dom);
    // #endif
  },
  onupdate() {
    // #if process.env.IS_MOBILE == 'false'
// //     if (this.scroller) {
// //       setTimeout(() => {
// //         this.scroller.refresh();
// //       }, 300);
// //     }
    // #endif
  },
  onbeforeremove() {
    // #if process.env.IS_MOBILE == 'false'
// //     delta = 0;
// //     this.scroller.off('scroll', this.onscroll);
// //     this.scroller.off('scrollStart', this.addFocus);
// //     this.scroller.off('scrollEnd', this.removeFocus);
// //     this.scroller.destroy();
    // #endif
  },
  view({ attrs }) {
    const { dataIsLoaded, getSettings, getControls, updateValue } = attrs;

    // #if process.env.IS_MOBILE == 'false'
// //     const scrollFunc = e => {
// //       delta = e.deltaY;
// //     };
    // #endif
    // #if process.env.IS_MOBILE == 'true'
    const scrollFunc = () => {};
    // #endif

    return this.template({
      state: {
        className: dataIsLoaded ? '' : 'loading',
      },
      actions: {
        onscroll: scrollFunc,
      },
      children: {
        controls: getControls().map(item =>
          m(control, {
            item,
            path: `${item.id}.list`,
            dataIsLoaded,
            getSettings,
            updateValue,
          }),
        ),
        submit: m(submitButton),
      },
    });
  },
};
