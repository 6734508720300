import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import Input from 'components/widgets/input';
import maskInput from 'vanilla-text-mask/dist/vanillaTextMask';

import FormModel from './model';

export default {
  oninit() {
    this.template = resolveTemplate('pages/callback');
    this.setName = ({ target: { value } }) => FormModel.setValue('name')(value);
    this.setPhone = ({ target: { value } }) =>
      FormModel.setValue('phone')(value);
    this.setEmail = ({ target: { value } }) =>
      FormModel.setValue('email')(value);
  },
  oncreate() {
    maskInput({
      inputElement: document.getElementById('phone'),
      mask: value => {
        FormModel.setValue('phone')(
          value.replace(/[a-zA-Z]/, '_').slice(0, 16),
        );

        return [
          '+',
          '7',
          ' ',
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
        ];
      },
    });
  },
  view() {
    const {
      values: { name, email, phone },
      formSubmitted,
      hasErrors,
      onSubmit,
    } = FormModel;

    return this.template({
      actions: {
        onSubmit,
      },
      state: {
        formSubmitted,
        hasErrors,
      },
      children: {
        nameInput: m(Input, {
          label: 'Имя',
          value: name,
          oninput: this.setName,
          name: 'name',
          id: 'name',
          w100: true,
          required: true,
        }),
        emailInput: m(Input, {
          label: 'Email',
          type: 'email',
          value: email,
          oninput: this.setEmail,
          name: 'email',
          id: 'email',
          required: true,
        }),
        phoneInput: m(Input, {
          label: 'Телефон',
          value: phone,
          name: 'phone',
          id: 'phone',
          required: true,
        }),
      },
    });
  },
};
