/* eslint-disable */
import m from 'mithril';
import c from 'classnames';
import resolveTemplate from 'utils/resolveTemplate';
import maskInput from 'vanilla-text-mask/dist/vanillaTextMask';
import { STORE, API } from 'constants.js';
// import User from 'models/user';
import Orders from 'models/orders';
import Countries from 'models/countries';
import { ScrollListener, MenuModel, getElementType } from 'models/mobileMenu';
import applicants from './applicants';

import logo from '../layout/header/logo.svg';
import phone from '../layout/header/phone.svg';
import person from '../widgets/order-result/applicants/persons.svg';

const getCountry = id =>
  Countries.list.find(country => country.id === id) || { iso: '' };

// fogive me for this sheet
// TODO: need decoupling

export default {
  oninit() {
    this.template = resolveTemplate('layout/header');
    this.drawerTeplate = resolveTemplate('layout/header/drawer');
    this.callbackTemplate = resolveTemplate('layout/header/callback');
    this.callbackFormHidden = true;
    this.phone = '';
    ScrollListener.init();

    this.onClose = () => {
      this.callbackFormHidden = true;
      MenuModel.closeMenu();
    };
  },
  oncreate() {
    // add mask handler to input
    maskInput({
      inputElement: document.getElementById('callbackPhone'),
      mask: [
        '+',
        '7',
        ' ',
        '(',
        /\d/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ],
    });
  },
  onremove() {
    ScrollListener.destroy();
  },
  view({ attrs: { whiteTheme }, state }) {
    const iso = Orders.current.country
      ? getCountry(Orders.current.country).iso
      : '';

    return m.fragment({}, [
      m('.swipeBackground', {
        className: c({
          'swipeBackground--show': MenuModel.status > 0,
        }),
        onclick: () => {
          state.onClose();
        },
      }),
      this.template({
        state: {
          uri: m.route.get(),
          className: c('headerPage--mobile', {
            'headerPage--whiteStyle': whiteTheme,
            'headerPage--blackStyle':
              MenuModel.status > 1 || ScrollListener.status > 0,
          }),
          burgerClass: c({
            'burgerMenu--open': MenuModel.status > 0,
            'burgerMenu--white': MenuModel.status > 1,
          }),
          price: Orders.current.values
            ? Orders.current.values.price.toLocaleString()
            : 0,
          priceClass: c({
            [`flags_${iso}`]: Orders.current.country,
            'header_fullCost--show': getElementType('center') === 'price',
          }),
          logoClass: c({
            'logo--hide': getElementType('center') !== 'logo',
          }),
          callbackClass: c({
            'callbackButton--hide': getElementType('right') === 'applicants',
            'callbackButton--active': MenuModel.isMenuVisible('callback'),
          }),
          applicantsClass: c({
            'header_persons--show': getElementType('right') === 'applicants',
            'header_persons--showEdit': MenuModel.isMenuVisible('applicants'),
          }),
          applicantsCount:
            Orders.current.values && Orders.current.values.persons.length,
        },
        actions: {
          // login: () => User.login({}),
          burgerAction: () => {
            if (MenuModel.status === 0) {
              MenuModel.openMenu('main');
            } else {
              state.onClose();
            }
          },
          phoneAction: () => {
            if (MenuModel.status < 3) {
              MenuModel.openMenu('callback');
            } else {
              state.onClose();
            }
          },
          applicantsAction: () => {
            if (MenuModel.status !== 2) {
              MenuModel.openMenu('applicants');
            } else {
              state.onClose();
            }
          },
        },
        children: {
          logo: m.trust(logo),
          phone: m.trust(phone),
          price: Orders.current.values ? Orders.current.values.price : 0,
          personIcon: m.trust(
            person.replace('setClass', 'orderCalculator_iconPersons'),
          ),
        },
      }),
      this.drawerTeplate({
        state: {
          className: c({
            'swipeMenu--show': MenuModel.isMenuVisible('main'),
          }),
          links: STORE.topMenuLinks,
        },
        actions: {
          closeMenu: e => {
            e.preventDefault();
            state.onClose();
            const el =
              e.target.tagName === 'A' ? e.target : e.target.parentElement;
            const link = el.getAttribute('href');
            setTimeout(() => {
              m.route.set(link);
            }, 300);
          },
        },
      }),
      this.callbackTemplate({
        state: {
          className: c({
            'callbackFormRequest--show': MenuModel.status > 2,
          }),
          buttonClass: c({
            'callbackFormRequest_button--hide': !state.callbackFormHidden,
          }),
          formClass: c({
            'callbackFormRequest_buttonSend--show': !state.callbackFormHidden,
          }),
          inputClass: c({
            'callbackFormRequest_label--show': !state.callbackFormHidden,
          }),
        },
        actions: {
          onClick() {
            state.callbackFormHidden = !state.callbackFormHidden;
          },
          onChange(event) {
            state.phone = event.target.value;
          },
          onSubmit() {
            const phone = state.phone.replace(/_/g, '').slice(0, 18);
            if (phone.length === 18) {
              return m
                .request({
                  url: `${API}/send_call`,
                  method: 'POST',
                  body: { phone },
                })
                .then(MenuModel.closeMenu);
            }
          },
        },
      }),
      m(applicants),
    ]);
  },
};
