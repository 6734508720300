const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div.createQuestion
  h1.titleBig.colorGray Задать вопрос
  p.pStandart.colorGray Если у вас возникли проблемы, мы готовы ответить на все интересующие вас вопросы. Напишите тему обращения и опишите вашу проблему. Ответим в течение 24 часов.
  if state.formSubmitted
    p.success Спасибо за ваше сообщение! Ответ вы получите на указанный почтовый ящик в течение 24 часов!
  else
    form.createQuestion_form(
      name='ask_question'
      onsubmit=actions.onSubmit
    )
      if !state.isLogged
        = children.emailInput
      =children.titleInput
      =children.bodyInput
      button.buttonStandart.buttonStandart--alternativeDisabled.createQuestion_formButtonSubmit(
        disabled
      )
        span.buttonStandart_name Отправить вопрос
  `;
};