const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
.menuMyProfile(...state.attrs)
  .menuMyProfile_wrap
    each item in state.items
      Link.menuMyProfile_item(...helpers.getHref(item) key=item.name id=item.id)
        = item.name
        span.menuMyProfile_counter= helpers.getCounter(item)

  `;
};