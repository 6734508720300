import resolveTemplate from 'utils/resolveTemplate';

export default {
  oninit() {
    this.template = resolveTemplate('pages/order/documents/input');
  },
  view({
    attrs: { accepted, orderId, personId, documentId, image, onChange },
  }) {
    return this.template({
      state: {
        fileInputName: `file-${orderId}${personId}${documentId}`,
        image,
        accepted,
      },
      actions: {
        onChange,
      },
    });
  },
};
