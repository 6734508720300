import m from 'mithril';
// import IScroll from 'iscroll/build/iscroll-probe.js';
import resolveTemplate from 'utils/resolveTemplate';
import TabItem from './item.js';

export default {
  oninit() {
    this.template = resolveTemplate('widgets/tabs/header');
  },
  // oncreate({ dom }) {
  //   this.scroll = new IScroll(dom, {
  //     mouseWheel: true,
  //     scrollbars: true,
  //     scrollX: true,
  //     scrollY: false,
  //   });
  // },
  view(vnode) {
    const {
      classes,
      headers,
      selected,
      onSelect,
      onUnderline,
      onIcons,
    } = vnode.attrs;
    return this.template({
      state: {
        classes,
        onUnderline,
      },
      children: {
        underline: onUnderline
          ? m(`.tabSwitch_underline[data-length="${headers.length}"]`, {
              style: {
                left: `${(100 / headers.length).toFixed(2) *
                  headers.findIndex(el => el.key === selected)}%`,
              },
            })
          : null,
        content: headers.map(item =>
          m(TabItem, {
            classes,
            id: item.key,
            key: item.key,
            title: item.title,
            active: item.key === selected,
            onSelect,
            onIcons,
          }),
        ),
      },
    });
  },
};
// tabHeaderLine
