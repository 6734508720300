import m from 'mithril';
import c from 'classnames';
import resolveTemplate from 'utils/resolveTemplate';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import authFormModal from './modal/authForm';

let isOpen = false;
let closeTransition = false;
let currentType = 'lorem';

export const openModal = type => {
  currentType = type;
  isOpen = true;
  setTimeout(() => {
    [...document.querySelectorAll('.scrollable')].forEach(disableBodyScroll);
  }, 50);
};

const closeModal = () => {
  closeTransition = true;
  setTimeout(() => {
    closeTransition = false;
    isOpen = false;
    m.redraw();
    clearAllBodyScrollLocks();
  }, 500);
};

const modal = resolveTemplate('layout/modal');
const lorem = resolveTemplate('layout/modal/lorem');
const authForm = () => authFormModal(closeModal);

const modalTypes = {
  authForm,
  lorem,
};

export default {
  view() {
    if (!isOpen) {
      return null;
    }

    // #if process.env.IS_DEVELOPMENT == 'true'
    if (!modalTypes[currentType]) {
      // eslint-disable-next-line
      window.alert(`Нужен шаблон: layout/modal/${currentType}.pug`);
      currentType = 'lorem';
    }
    // #endif
    return modal({
      actions: {
        close: closeModal,
        stopPropagation: e => {
          e.stopPropagation();
        },
      },
      state: {
        className: c({
          'modalWindow--hide': closeTransition,
        }),
      },
      children: {
        content: modalTypes[currentType]({}),
      },
    });
  },
};
