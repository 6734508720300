import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import Faq from './faq';
import Form from './form';

export default {
  oninit() {
    this.template = resolveTemplate('pages/question-create');
    this.title = 'Задать вопрос';
    document.title = `${this.title} — Visard`;
  },
  view() {
    return this.template({
      state: {
        title: this.title,
      },
      children: {
        faq: m(Faq),
        form: m(Form),
      },
    });
  },
};
