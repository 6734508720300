import m from 'mithril';
import c from 'classnames';
import resolveTemplate from 'utils/resolveTemplate';
import Orders from 'models/orders';
import { MenuModel } from 'models/mobileMenu';
import personList from '../widgets/order-result/applicants/list';

const getItem = () =>
  document.querySelector(
    '.personsSettings_item:last-child .personsSettingsSelect',
  );

export default {
  oninit() {
    this.template = resolveTemplate('pages/home/order/applicants');
  },
  view() {
    return this.template({
      state: {
        wrapperClass: c({
          'personsSettings--open': MenuModel.isMenuVisible('applicants'),
        }),
        ages: [...Array(86).keys()],
      },
      actions: {
        addPerson: () => {
          Orders.current.values.persons.push({
            age: 18,
            number: 1,
          });

          setTimeout(() => {
            const wrap = document.querySelector('.personsSettings_list');
            wrap.scrollTo({ top: wrap.scrollHeight });

            const el = document.querySelector('.personsSettingsSelect--active');
            if (el) {
              el.classList.remove('personsSettingsSelect--active');
            }

            getItem().classList.add('personsSettingsSelect--active');
          }, 10);

          Orders.sendValues();
          // #if process.env.IS_DEVELOPMENT == 'false'
// //           window.dataLayer.push({ event: 'changeAge' });
          // #endif
        },
        changeAge: e => {
          Orders.current.values.persons[
            Orders.current.values.persons.length - 1
          ].age = e.target.value;
          Orders.sendValues();
          getItem().classList.remove('personsSettingsSelect--active');
          // #if process.env.IS_DEVELOPMENT == 'false'
// //           window.dataLayer.push({ event: 'changeAge' });
          // #endif
        },
        onBlur: () => {
          getItem().classList.remove('personsSettingsSelect--active');
        },
      },
      helpers: {
        selected: id => id === 18,
        getAgePostfix: age => {
          const last = age % 10;
          if (last === 1 && age !== 11) return ' год';
          if (last >= 2 && last <= 4 && age - last !== 10) return ' года';
          return ' лет';
        },
      },
      children: {
        personList: Orders.current.values ? m(personList) : null,
      },
    });
  },
};
