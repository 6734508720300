/* eslint-disable */
import m from 'mithril';
import Questions from 'models/questions';

import { WSAPI } from 'constants.js';

const WebSockets = {
  socket: null,

  init: () => {
    if (!WebSockets.socket) {
      // WebSockets.socket = new window.WebSocket(WSAPI);
      // WebSockets.socket.onmessage = event => {
      //   let parsedMessage;
      //   if (event.data) {
      //     try {
      //       parsedMessage = JSON.parse(event.data);
      //     } catch (e) {
      //       return null;
      //     }
      //     const { type, data } = parsedMessage;
      //     if (type === 'answer') {
      //       Questions.onAnswerRecieve(data);
      //     }
      //     m.redraw();
      //   }
      //   return null;
      // };
    }
  },

  close: () => {
    if (WebSockets.socket) {
      WebSockets.socket.close();
      return null;
    }
    return null;
  },
};

// #if process.env.IS_DEVELOPMENT == 'true'
if (window.models) {
  window.models.WebSockets = WebSockets;
}
// #endif

export default WebSockets;
