const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
aside.orderCalculatorDropdown.orderCalculatorDropdown--open
  header.orderCalculatorDropdown_header
    if !state.hideArrow
      button.orderCalculatorDropdown_back.burgerMenu.burgerMenu--open(
        className=state.buttonClassName
        onclick=actions.ondelete
      )
        span.burgerMenu_inner Назад
    label.orderCalculatorDropdown_label= state.title
    = children.button
  = children.content
  .closeBG(
    onclick=actions.ondelete
  )

  `;
};