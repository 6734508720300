import m from 'mithril';
import { STATE_INIT, transitions, actions } from './imageFSM';

const TRANSITION_DURATION = 1000;

const Background = {
  current: null,
  images: new Set(),

  addToQueue(background, id) {
    if (id === Background.current) return;
    Background.current = id;

    const image = {
      id: new Date().getTime(),
      ...background,
      state: STATE_INIT,
      preview: {
        loaded: false,
        transitionCompleted: false,
      },
      full: {
        loaded: false,
        transitionCompleted: false,
      },
      setLoaded: type => () => {
        image[type].loaded = true;
        Background.update();

        setTimeout(() => {
          image[type].transitionCompleted = true;
          Background.update();
        }, TRANSITION_DURATION);
      },
    };

    Background.images.add(image);
    Background.update();
  },

  update: () => {
    [...Background.images]
      .map(Background.findTransition)
      .forEach(Background.performTransition);

    m.redraw();
  },

  findTransition: (image, index) => {
    const imagesArray = [...Background.images];
    const transition = transitions[image.state].find(({ check }) =>
      check({
        previous: imagesArray[index - 1],
        current: image,
        next: imagesArray[index + 1],
      }).every(condition => condition),
    );

    return transition ? { actionType: transition.to, image } : {};
  },

  performTransition: ({ image, actionType }) => {
    if (!actionType) return;

    actions[actionType](image, Background.images);
    image.state = actionType;
  },
};

// #if process.env.IS_DEVELOPMENT == 'true'
if (window.models) {
  window.models.Background = Background;
}
// #endif

export default Background;
