import resolveTemplate from 'utils/resolveTemplate';
import cx from 'classnames';

class Model {
  constructor(onDrop) {
    this.isDragged = false;
    this.isDroped = false;
    this.onDrop = onDrop;
    this.ondragover = this.ondragover.bind(this);
    this.ondragleave = this.ondragleave.bind(this);
    this.ondrop = this.ondrop.bind(this);
  }

  ondragover() {
    this.isDragged = true;
    return false;
  }

  ondragleave() {
    this.isDragged = false;
  }

  ondrop(event) {
    event.preventDefault();
    this.isDragged = false;
    this.isDroped = this.onDrop(event);
  }
}

export default {
  oninit({ attrs: { onDrop } }) {
    this.model = new Model(onDrop);
    this.template = resolveTemplate('widgets/dropZone');
  },
  view({ children: content }) {
    return this.template({
      state: {
        className: cx({
          hover: this.model.isDragged,
          drop: this.model.isDroped,
        }),
      },
      children: {
        content,
      },
      actions: {
        ondragover: this.model.ondragover,
        ondragleave: this.model.ondragleave,
        ondrop: this.model.ondrop,
      },
    });
  },
};
