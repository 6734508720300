import resolveTemplate from 'utils/resolveTemplate';

export default {
  oninit() {
    document.title = 'О сервисе';
    this.template = resolveTemplate('pages/about');
  },
  view() {
    return this.template({});
  },
};
