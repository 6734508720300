import m from 'mithril';
import c from 'classnames';

export const cache = {};

export default {
  oninit({ attrs }) {
    const { parentId, path, getSettings } = attrs;
    const input = getSettings(path);
    const { active: checked } = input;

    if (checked) {
      cache[parentId] = `${path}.active`;
    }
  },
  onupdate({ attrs }) {
    const { parentId, path, getSettings } = attrs;
    const input = getSettings(path);
    const { active: checked } = input;

    if (checked && cache[parentId] === undefined) {
      cache[parentId] = path;
    }
  },
  view({ attrs, children }) {
    const {
      id,
      path,
      parentId,
      name,
      dataIsLoaded,
      getSettings,
      updateValue,
    } = attrs;
    const input = getSettings(path);
    const { disabled, active: checked } = input;

    const valuePath = `${path}.active`;

    return m(
      '.radio.radio--big',
      {
        class: c({
          'radio--disabled': !dataIsLoaded || disabled,
        }),
      },
      m(
        'label.radio_label',
        {
          for: `inp_${id}`,
          onclick: e => {
            // e.preventDefault();
            e.stopPropagation();
            if (cache[parentId]) {
              updateValue(cache[parentId], false);
            }
            updateValue(valuePath, true);
            cache[parentId] = valuePath;
          },
        },
        [
          m('input.radio_input', {
            disabled,
            checked,
            id: `inp_${id}`,
            type: 'radio',
            name: `radio${parentId}`,
            onchange: ({ target: { checked: isActive } }) => {
              if (cache[parentId]) {
                updateValue(cache[parentId], false);
              }
              updateValue(valuePath, isActive);
              cache[parentId] = valuePath;
            },
          }),
          m('.radio_icon', m('.radio_mark')),
          m('.radio_content', [name, ...children]),
        ],
      ),
    );
  },
};
