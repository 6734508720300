import m from 'mithril';
import { API } from 'constants.js';
import LoadingBar from './loadingBar';

const Questions = {
  current: null,
  currentIndex: -1,
  display: [],
  list: [],
  showOnlyOpened: false,
  countUnreaded: 0,

  fetchAll() {
    LoadingBar.start();
    return m
      .request(`${API}/questions`)
      .then(questions => {
        Questions.list = questions;
        Questions.countUnreaded = Questions.list.filter(
          item => item.unreaded === true,
        ).length;
        Questions.getDisplayItems();
      })
      .finally(LoadingBar.finish);
  },

  fetch(id) {
    LoadingBar.start();
    return m
      .request(`${API}/questions/${id}`)
      .then(question => {
        const questionIndex = Questions.list.findIndex(
          item => item.id === question.id,
        );
        if (Questions.list[questionIndex].unreaded) {
          Questions.countUnreaded -= 1;
        }
        Questions.list[questionIndex].unreaded = false;
        Questions.currentIndex = questionIndex;
        Questions.current = question;
      })
      .finally(LoadingBar.finish);
  },

  setAnswerReadedStatus(answerId) {
    const answerIndex = Questions.list[
      Questions.currentIndex
    ].answers.findIndex(item => item.id === answerId);
    Questions.list[Questions.currentIndex].answers[
      answerIndex
    ].unreaded = false;
    Questions.list[Questions.currentIndex].unreaded = Questions.list[
      Questions.currentIndex
    ].answers.some(item => item.unreaded);
    Questions.current = Questions.list[Questions.currentIndex];
    m.redraw();
    return m.request(`${API}/questions/${Questions.current.id}/${answerId}`);
  },

  getDisplayItems() {
    Questions.display = Questions.showOnlyOpened
      ? Questions.list.filter(question => !question.closedAt)
      : [...Questions.list];
  },

  toggleShowOnlyOpened() {
    Questions.showOnlyOpened = !Questions.showOnlyOpened;
    Questions.getDisplayItems();
  },

  postQuestion(title, body, email) {
    LoadingBar.start();

    return m
      .request({
        method: 'POST',
        url: `${API}/questions`,
        body: { title, body, email },
      })
      .then(response => {
        if (!email) {
          m.route.set(`faq/${response.id}`);
        }
      })
      .finally(LoadingBar.finish);
  },

  postMessage(body) {
    LoadingBar.start();

    return m
      .request({
        method: 'POST',
        url: `${API}/questions/${Questions.current.id}`,
        body: { body },
      })
      .then(() => Questions.fetch(Questions.current.id))
      .finally(LoadingBar.finish);
  },

  onAnswerRecieve(data) {
    const { question_id: questionId, body: answer } = data;
    const questionIndex = Questions.list.findIndex(
      item => item.id === questionId,
    );
    Questions.list[questionIndex].answers.push(answer);
    Questions.list[questionIndex].messageCount += 1;
    if (
      !Questions.list[questionIndex].unreaded &&
      data.question_id !== Questions.current.id
    ) {
      Questions.list[questionIndex].unreaded = true;
      Questions.countUnreaded += 1;
    }
    if (Questions.current) {
      if (questionId === Questions.current.id) {
        Questions.current = Questions.list[questionIndex];
      }
    }
  },
};

// #if process.env.IS_DEVELOPMENT == 'true'
if (window.models) {
  window.models.Questions = Questions;
}
// #endif

export default Questions;
