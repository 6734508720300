const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
aside.personList(
  className=state.editMode ? 'personList--edit' : ''
)
  header.personList_header
    b.personList_label Заявители
    button.personList_editButton(
      onclick=actions.editOnclick
    ) 
      if state.editMode
        | Готово
      else
        | Править
  div.personList_list.gpScroll.gpScroll--y
    div.personList_fade
  
    if state.person_count > 1
      label.personList_item.personList_item--common(
        className=state.commonSelectedClass
        onclick=actions.commonOnclick
      ) 
        = children.common
        span.personList_itemName Общие вопросы
        span.personList_itemProgress 
          // числа будут анимироваться если изменятся
          span.personList_itemCount 10
          = ' из '
          span.personList_itemCount 15 
          = ' полей'
    
    = children.items
    button.personList_addButton.buttonAdd(
      onclick=actions.createOnclick
    )
      span Добавить заявителя


  `;
};