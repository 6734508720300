const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div.simpleOrderForm(style=state.style)
  .simpleOrderForm_message
    = children.icon
    p.simpleOrderForm_messageP
      | Менеджер свяжется с вами 
      nobr и поможет оформить визу
  form(onsubmit=actions.onSubmit autocomplete="false")
    label.inputStandart.inputStandart--inline(
      className=state.status.fullName
      for="fullName"
    )
      input.inputStandart_input#fullName(
        oninput=actions.onFullNameChange
        className=state.disabled ? 'noempty' : ''
        value=state.values.fullName
        disabled=state.disabled
        required
      )
      span.inputStandart_label Имя
      div.inputStandart_line
    label.inputStandart.inputStandart--inline(
      className=state.status.phone
      for="phone"
    )
      input.inputStandart_input#phone(
        className=state.disabled ? 'noempty' : ''
        type="tel"
        onkeyup=actions.onPhoneChange
        value=state.values.phone
        disabled=state.disabled
        required
      )
      span.inputStandart_label Телефон
      div.inputStandart_line
    label.inputStandart(
      className=state.status.email
      for="email"
    )
      input.inputStandart_input#email(
        className=state.disabled ? 'noempty' : ''
        type="email"
        oninput=actions.onEmailChange
        value=state.values.email
        disabled=state.disabled
        required
      )
      span.inputStandart_label Электронная почта
      div.inputStandart_line
    input(type="submit" hidden)

    = children.button


  `;
};