import m from 'mithril';
import { API } from 'constants.js';
import Orders from 'models/orders';
import roistatVisit from 'utils/roistatVisit';

const initialValues = {
  show: false,
  buttonEnabled: false,
  disabled: false,
  submited: false,
  values: {
    fullName: '',
    phone: '',
    email: '',
  },
  status: {
    fullName: '',
    phone: '',
    email: '',
  },
};

const FormModel = {
  ...initialValues,
  onChange: fieldName => value => {
    FormModel.values[fieldName] = value;

    let isValid = false;
    switch (fieldName) {
      case 'email':
        if (value.length && document.getElementById('email').validity.valid) {
          isValid = true;
        }
        break;
      case 'phone':
        if (value.length && !/_/.test(value)) {
          isValid = true;
        }
        break;
      case 'fullName':
        if (/^[ёЁа-я А-Яa-zA-Z]+$/.test(value.trim())) {
          isValid = true;
        }
        break;
      default:
        break;
    }

    if (isValid) {
      FormModel.status[fieldName] = 'inputStandart--success';
      // #if process.env.IS_DEVELOPMENT == 'false'
// //       window.dataLayer.push({ event: 'orderFormValid' });
      // #endif
    } else {
      FormModel.status[fieldName] = '';
    }

    if (
      Object.values(FormModel.status).filter(a => a).length > 1 &&
      FormModel.values.fullName
    ) {
      FormModel.buttonEnabled = true;
    } else {
      FormModel.buttonEnabled = false;
    }
  },
  onSubmit: event => {
    event.preventDefault();
    if (FormModel.buttonEnabled) {
      FormModel.disabled = true;
      FormModel.values.roistat_visit = roistatVisit();

      return m
        .request({
          url: `${API}/send_order`,
          method: 'POST',
          body: {
            ...FormModel.values,
            country_id: Orders.current.country,
            values: Orders.current.values,
          },
          withCredentials: true,
        })
        .then(() => {
          FormModel.submited = true;
        })
        .catch(() => {
          FormModel.disabled = false;
        });
    }

    return null;
  },
};

// #if process.env.IS_DEVELOPMENT == 'true'
if (window.models) {
  window.models.FormModel = FormModel;
}
// #endif

export default FormModel;
