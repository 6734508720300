import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import Countries from 'models/countries';
import Articles from 'models/articles';
import Orders from 'models/orders';
import { ScrollListener } from 'models/mobileMenu';
import background from 'components/pages/home/background';
import form from 'components/widgets/order-form';
import CountryCard from 'components/widgets/country-card';

import order from './order';

const sizes = [3, 2, 1, 1];

const FilterCountriesModel = {
  display: [],
  current: 'popular',
  filter(key) {
    FilterCountriesModel.current = key;
    if (!key) {
      FilterCountriesModel.display = Countries.list;
    } else if (key === 'popular') {
      FilterCountriesModel.display = Countries.list.filter(
        item => item.priority_sale === 3,
      );
    } else {
      FilterCountriesModel.display = Countries.list.filter(
        item => item.region === key,
      );
    }
  },
};

export default {
  oninit() {
    document.title = 'Visard';
    this.template = resolveTemplate('pages/home');
    this.listener = ScrollListener;
    this.listener.init();
    this.countries = Countries.list.reduce((result, item) => {
      const { show_main: showMain } = item;
      if (showMain) {
        result[showMain - 1] = item;
      }
      return result;
    }, []);
    FilterCountriesModel.filter('popular');
  },
  onupdate() {
    if (Orders.current.values) {
      this.isOrderFetched = true;
    }
  },
  onremove() {
    this.listener.destroy();
  },
  view() {
    const country = Countries.current;

    return m.fragment({}, [
      this.template({
        children: {
          background: m(background),
          order: m(order),
          countries: this.countries.map((item, index) =>
            m(CountryCard, {
              country: { ...item, priority_sale: sizes[index] },
            }),
          ),
        },
        state: {
          ...Articles.home,
          country,
          popularChecked: FilterCountriesModel.current === 'popular',
          allChecked: FilterCountriesModel.current === '',
          schengenChecked: FilterCountriesModel.current === 'schengen',
          asiaChecked: FilterCountriesModel.current === 'asia',
          countries: FilterCountriesModel.display.map(item => ({
            key: country.id,
            link: `/strany/${item.url}`,
            title: item.name,
            price: `от ${item.mincost.toLocaleString()} ₽`,
          })),
          mincost: ` — от ${country.mincost.toLocaleString()} ₽`,
          isCountrySelected: Orders.current.country,
          needHideCountires: Orders.current.values,
        },
        actions: {
          countryClick() {
            Orders.setCountry(Countries.current.id).then(m.redraw);
          },
          regionClick: key => () => FilterCountriesModel.filter(key),
        },
      }),
      ...(this.isOrderFetched
        ? [
            m(form, {
              dataIsLoaded: Orders.current.fetched,
              getControls: Orders.getControls,
              getSettings: Orders.getSettings,
              updateValue: Orders.updateValue,
            }),
          ]
        : []),
    ]);
  },
};
