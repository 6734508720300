import m from 'mithril';

import button from './button';
import checkbox from './checkbox';
import radio from './radio';

const switcher = {
  button,
  buttons_without_question: button,
  checkbox,
  radio,
};

export default {
  view({
    attrs: {
      id,
      list_answer: list,
      type: _,
      type_switcher: type,
      label,
      ...other
    },
  }) {
    const ids = list.map(item => item.id);

    return m(
      'div.toogleSelect',
      label ? [m('span.toogleSelect_label', label)] : null,
      m(switcher[type], { ids, list, ...other }),
    );
  },
};
