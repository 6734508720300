import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import Questions from 'models/questions';
import Input from 'components/widgets/input';

const FormModel = {
  body: '',
  setMessageText: ({ target: { value: body } }) => {
    FormModel.body = body;
  },
  onSubmit: e => {
    e.preventDefault();

    if (FormModel.body.trim()) {
      Questions.postMessage(FormModel.body).then(() => {
        FormModel.body = '';
      });
    }
  },
};

export default {
  oninit() {
    this.template = resolveTemplate('pages/question/form');
  },
  view() {
    return this.template({
      actions: {
        onSubmit: FormModel.onSubmit,
      },
      children: {
        input: m(Input, {
          tagName: 'textarea',
          oninput: FormModel.setMessageText,
          placeholder: 'Текст вашего ответа',
          value: FormModel.body,
        }),
      },
    });
  },
};
