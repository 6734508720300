const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
.notificationList(...state.attrs)
  .notificationList_wrap
    .notificationList_arrow(style=state.arrowStyle)
    .notificationList_inner
      if state.hasNotifications
        = children.notifications
      else
        .notificationList_noNotification У вас пока нет уведомлений

  `;
};