const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
a(...state.link)
  span.notificationList_name=state.title
  span.notificationList_country=state.caption
  button.notificationList_hide(onclick=actions.deleteItem)

  `;
};