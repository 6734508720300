const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
.orderPage
  style.
    body {
      background-color: #000;
    }
  div.orderPage_side--left
    div.orderPage_info.spriteFlags.bigFlag(className=state.flag)
      div.orderPage_infoInner
        b.orderPage_country
          = state.title
        span.orderPage_orderid
          | № 
          = state.orderid
    = children.persons
    div.purchaseStart
      button.buttonStandart
        span.buttonStandart_name Заказать оформление
      span.purchaseStart_subtext
        = 'Стоимость услуг '
        =state.cost
        = ' ₽'
  div.orderPage_side--right
    = children.tabs
  //   div.test434.gpScroll.gpScroll--x
  //     | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione placeat non dignissimos. Asperiores nisi consectetur quisquam iusto enim aperiam suscipit corporis accusantium autem nesciunt, placeat, consequuntur illo cupiditate quis sequi.
  `;
};