import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import worksheet from './icon/worksheet.svg';
import worksheetActive from './icon/worksheet--active.svg';
import documents from './icon/documents.svg';
import documentsActive from './icon/documents--active.svg';
import institutions from './icon/institutions.svg';
import institutionsActive from './icon/institutions--active.svg';

const icons = { worksheet, documents, institutions };
const iconsActive = {
  worksheet: worksheetActive,
  documents: documentsActive,
  institutions: institutionsActive,
};
const iconsWidth = {
  worksheet: '20px',
  documents: '15px',
  institutions: '13px',
};
export default {
  oninit(vnode) {
    const { id, onSelect } = vnode.attrs;
    this.onclick = () => onSelect(id);
    this.template = resolveTemplate('widgets/tabs/header/item');
  },
  view(vnode) {
    const { id: nameItem, classes, title, active, onIcons } = vnode.attrs;
    return this.template({
      state: {
        class: active
          ? `${classes.headerItem} ${classes.headerItemActive}`
          : classes.headerItem,
        title,
        iconWidth: iconsWidth[nameItem],
        onIcons,
      },
      children: {
        icon: m.trust(icons[nameItem]),
        iconActive: m.trust(iconsActive[nameItem]),
      },
      actions: {
        onclick: this.onclick,
      },
    });
  },
};
