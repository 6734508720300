import m from 'mithril';
import cx from 'classnames';

export default {
  oninit(vnode) {
    const {
      tagName = 'input',
      focusClass = '',
      errorClass = 'error',
      onblur = () => {},
      onfocus = () => {},
    } = vnode.attrs;
    this.tag = tagName;
    this.focused = false;
    this.focusClass = focusClass;
    this.errorClass = errorClass;
    this.onBlur = e => {
      this.focused = false;
      onblur(e);
    };

    this.onFocus = e => {
      this.focused = true;
      onfocus(e);
    };
  },
  view(vnode) {
    const {
      tagName,
      focusClass,
      onblur,
      onfocus,
      label,
      error,
      inline,
      w100,
      required,
      ...attrs
    } = vnode.attrs;

    return m(
      'label.inputStandart',
      {
        className: cx({
          'inputStandart--inline': inline,
          'inputStandart--width100': w100,
        }),
      },
      [
        m(`${this.tag}.inputStandart_input`, {
          onfocus: this.onFocus,
          onblur: this.onBlur,
          className: cx({
            [this.focusClass]: this.focused,
            [this.errorClass]: error,
          }),
          required,
          ...attrs,
        }),
        m('span.inputStandart_label', label),
        m('div.inputStandart_line'),
        error ? m('p.error', error) : null,
      ],
    );
  },
};
