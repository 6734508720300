/* eslint-disable */
import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import FormModel from 'models/submitOrderForm';
import maskInput from 'vanilla-text-mask/dist/vanillaTextMask';
// #if process.env.IS_MOBILE == 'true'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// #endif
import iconOperator from './operator.svg';
// #if process.env.IS_MOBILE == 'true'
import button from './button';

const disableScroll = () =>
  [...document.querySelectorAll('.scrollable')].forEach(disableBodyScroll);

const buttonElement = () => m('.mt36', m(button));
// #endif

// #if process.env.IS_MOBILE == 'false'
// // const buttonElement = () => null;
// #endif

export default {
  oninit() {
    this.template = resolveTemplate('widgets/order-result/submitForm');
  },
  oncreate() {
    maskInput({
      inputElement: document.getElementById('phone'),
      mask: [
        '+',
        '7',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ],
    });
    FormModel.show = true;
    m.redraw();
    // #if process.env.IS_MOBILE == 'true'
    disableScroll();
    // #endif
  },
  // #if process.env.IS_MOBILE == 'true'
  onremove() {
    clearAllBodyScrollLocks();
  },
  // #endif
  view() {
    return this.template({
      actions: {
        onSubmit: FormModel.onSubmit,
        onFullNameChange: ({ target: { value } }) =>
          FormModel.onChange('fullName')(value),
        onPhoneChange: ({ target: { value } }) =>
          FormModel.onChange('phone')(value),
        onEmailChange: ({ target: { value } }) =>
          FormModel.onChange('email')(value),
      },
      state: {
        values: FormModel.values,
        status: FormModel.status,
        disabled: FormModel.disabled,
      },
      children: {
        icon: m.trust(iconOperator),
        button: buttonElement(),
      },
    });
  },
};
