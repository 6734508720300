import Articles from 'models/articles';
import resolveTemplate from 'utils/resolveTemplate';

export default {
  oninit() {
    document.title = 'Новости';
    this.template = resolveTemplate('pages/news-list');
  },
  view() {
    return this.template({
      state: Articles.news,
    });
  },
};
