import m from 'mithril';

export default {
  oninit({ attrs }) {
    const { ids, updateValue, path } = attrs;

    this.update = id =>
      updateValue(
        path.concat('list'),
        ids.reduce(
          (res, item) => ({
            ...res,
            [item]: {
              active: item === id,
            },
          }),
          {},
        ),
      );
  },
  view({ attrs }) {
    const {
      list,
      settings: { list: values = {}, disabled },
      dataIsLoaded,
    } = attrs;

    return m(
      'ul',
      list.map(({ id, name }) =>
        m(
          'li',
          m(
            'label',
            m('input', {
              type: 'radio',
              checked: values[id] && values[id].active,
              onclick: () => this.update(id),
              disabled: !dataIsLoaded || disabled,
            }),
            name,
          ),
        ),
      ),
    );
  },
};
