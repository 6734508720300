const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div.faqList_scroll2(onwheel=actions.onscroll)
  div.faqList_items
    = children.items

  `;
};