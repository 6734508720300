import m from 'mithril';
import { API } from 'constants.js';
import LoadingBar from 'models/loadingBar';

const api = {
  // eslint-disable-next-line camelcase
  send: ({ name, email, phone, title: subject, body: text, crm_tag }) => {
    LoadingBar.start();

    // eslint-disable-next-line
    text = `<h1>Заявка на 9 сентября</h1><p><b>Имя:</b> ${name}</p><p><b>Телефон:</b> ${phone}</p><p><b>Почта:</b> ${email}</p></p>`;

    // console.log(text);
    // eslint-disable-next-line
    // const text2 = `${text} <h1>Заявка на 9 сентября</h1><p><b>Имя:</b>${phone}</p><p><b>Телефон:</b> '${phone}</p><p><b>Почта:</b> '${email}</p></p>'`;

    return m
      .request({
        method: 'POST',
        url: `${API}/data_sender`,
        body: {
          name,
          email,
          phone,
          subject,
          text,
          crm_tag,
        },
      })
      .finally(LoadingBar.finish);
  },
};

const FormModel = {
  values: {
    name: '',
    email: '',
    phone: '',
    title: 'Заявка на 9 сентября',
    body: '<h1>Заявка на 9 сентября</h1>',
    crm_tag: [{ type: '9_september' }],
  },
  errors: {
    phone: false,
    email: false,
  },
  formSubmitted: false,

  setValue: field => value => {
    FormModel.values[field] = value;
    FormModel.errors[field] = false;
  },

  hasErrors: () => {
    const {
      values: { email, phone, name },
    } = FormModel;

    FormModel.errors.name = !name && 'ФИО не должно быть пустым!';
    FormModel.errors.email = !email && 'Email не должно быть пустым!';
    FormModel.errors.phone = !phone && 'Телефон не должно быть пустым!';

    return FormModel.errors.email || FormModel.errors.phone;
  },

  onSubmit: e => {
    e.preventDefault();

    if (!FormModel.hasErrors()) {
      const { values } = FormModel;

      api.send(values).then(() => {
        FormModel.values.phone = '';
        FormModel.values.email = '';
        FormModel.formSubmitted = true;
      });
    }
  },
};

export default FormModel;
