/* eslint-disable */

import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import { STORE } from 'constants.js';
import Countries from 'models/countries';

const ratio = window.devicePixelRatio;
const CountryCardModel = {
  openCountryId: 0,
  openCosts: country => e => {
    e.preventDefault();
    e.stopPropagation();

    if (CountryCardModel.openCountryId === country) {
      CountryCardModel.openCountryId = 0;
      m.redraw();
      return;
    }

    CountryCardModel.openCountryId = country;
    m.redraw();
    document
      .querySelector('.gridCountry_list')
      .classList.add('gridCountry_list—openDetails');
    document.body.onclick = () => {
      document.body.onclick = null;
      CountryCardModel.openCountryId = 0;
      m.redraw();
      document
        .querySelector('.gridCountry_list')
        .classList.remove('gridCountry_list—openDetails');
    };
  },
  clickDetails: e => {
    e.preventDefault();
    e.stopPropagation();
  },
};

const getSize = ({ priority_sale: type }) => {
  if (type === 1) {
    return '1x1';
  }

  if (type === 2) {
    return '2x1';
  }

  return '2x2';
};

const getImageLink = country => {
  let image = '';
  const type = country.priority_sale;

  if (ratio > 1) {
    if (type === 1) {
      image = 'w528x528';
    }

    if (type === 2) {
      image = 'w1104';
    }

    if (type === 3) {
      image = 'w1104x1104';
    }
  } else {
    if (type === 1) {
      image = 'w264x264';
    }

    if (type === 2) {
      image = 'w552';
    }

    if (type === 3) {
      image = 'w552x552';
    }
  }
  const ext = STORE.WebPSupport ? 'webp' : 'jpg';

  return `${Countries.prefix}${country.id}/${image}.${ext}`;
};

export default {
  oninit() {
    this.template = resolveTemplate('widgets/coutry-card');
  },
  view({ attrs: { country } }) {
    const itemClassName = `gridCountry_item ${
      country.id === CountryCardModel.openCountryId
        ? 'gridCountry_item--openDetails'
        : ''
    }`;
    const linkClassName = `gridCountry_itemName spriteFlags flags_${country.iso}`;

    return this.template({
      state: {
        ...country,
        size: getSize(country),
        link: `/strany/${country.url}`,
        price: `от ${country.mincost.toLocaleString()} ₽`,
        image: getImageLink(country),
        itemClassName,
        linkClassName,
      },
      actions: {
        onCostClick: CountryCardModel.openCosts(country.id),
        onDetailsClick: CountryCardModel.clickDetails,
      },
    });
  },
};
