import Countries from 'models/countries';
import resolveTemplate from 'utils/resolveTemplate';
import formatDate from 'utils/formatDate';

const getCountry = id => Countries.list.find(country => country.id === id);

export default {
  oninit(vnode) {
    this.template = resolveTemplate('pages/order-list/item');
    if (vnode.attrs.order.closeDate === '') {
      this.dateClassName = 'opened';
      this.dateText = 'Открыт';
    } else {
      this.dateClassName = 'closed';
      this.dateText = formatDate(
        vnode.attrs.order.closeDate,
        'Завершён D MMMM YYYY',
      );
    }
  },
  view(vnode) {
    const {
      attrs: {
        order: { id, title, number, persons, content, payed },
      },
    } = vnode;
    return this.template({
      state: {
        href: `/orders/${id}`,
        title,
        number: number.toLocaleString('ru'),
        persons: persons.map(person => person.fullName).join(', '),
        content: content.join(', '),
        payed,
        dateText: this.dateText,
        dateClassName: this.dateClassName,
        flag: `flags_${getCountry(vnode.attrs.order.country).iso}`,
      },
    });
  },
};
