import m from 'mithril';
import c from 'classnames';
import Countries from 'models/countries';
import Orders from 'models/orders';
import Autocomplete from 'models/autocomplete';
import resolveTemplate from 'utils/resolveTemplate';
import withWrapper from 'utils/withWrapper';
import autocompleteInput from 'decorators/autocomplete';
import { STORE } from 'constants.js';
// #if process.env.IS_MOBILE == 'true'
import scrollIt from 'utils/scrollIt';
// #endif
import searchIcon from './search.svg';

const oninit = autocompleteContext => {
  autocompleteContext.setList(Countries.list);
  Orders.setCountry = withWrapper(promise =>
    promise.then(id => {
      autocompleteContext.setItem(id);
    }),
  )(Orders.setCountry);
};

const gettInputAttrs = autocompleteContext => {
  const countryIso = Orders.current.country
    ? Countries.list.find(country => country.id === Orders.current.country).iso
    : null;

  return {
    wrapper: {
      class: c('searchCountry', {
        spriteFlags: countryIso,
        [`flags_${countryIso}`]: countryIso,
        'searchCountry--selected': Orders.current.values,
        'searchCountry--mobile': STORE.isMobile,
      }),
    },
    input: {
      autofocus: true,
      class: c('searchCountry_input', {
        filled: autocompleteContext.value === Countries.current.name,
      }),
      placeholder: Countries.current.name,
    },
    children: {
      icon: m.trust(searchIcon),
    },
  };
};

// #if process.env.IS_MOBILE == 'true'
let opening = false;
// #endif

const onChange = context => {
  let items = [];
  const string = context.value.toLowerCase();

  if (string) {
    const filter = new RegExp(`^${string}`, 'i');

    items = context.list.filter(item => item && filter.test(item.name));

    if (items.length === 0) {
      items = context.list.filter(item =>
        item.subname.some(submane => filter.test(submane)),
      );
    }

    items.sort((a, b) => {
      const A = a.name.toLowerCase().indexOf(string);
      const B = b.name.toLowerCase().indexOf(string);
      if (A > B) return 1;
      if (A < B) return -1;
      return 0;
    });
  }

  if (items.length === 0) {
    items = [];
  }

  // #if process.env.IS_MOBILE == 'true'
  if (opening) return string;

  if (context.display.length === 0 && items.length) {
    opening = true;
    setTimeout(() => {
      opening = false;
    }, 500);
  }
  // #endif

  context.display = items;
  context.hoverItemIndex = items.length ? 0 : null;
  return string;
};

const onSelect = ({ id }) => {
  Promise.all([Countries.setCurrent(id), Orders.setCountry(id)]).then(() => {
    m.redraw();
    // #if process.env.IS_MOBILE == 'true'
    setTimeout(
      () => scrollIt(document.getElementById('scrollbar').offsetTop - 48),
      300,
    );
    // #endif
  });
};

export default autocompleteInput(
  Autocomplete(onChange, onSelect),
  oninit,
  resolveTemplate('pages/home/order/autocomplete/input'),
  resolveTemplate('pages/home/order/autocomplete/list'),
  resolveTemplate('pages/home/order/autocomplete/item'),
  gettInputAttrs,
  'searchCountry_result--hide',
  600,
);
