const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
.searchCountry_result(
  ...state.attrs
  style=${{
    opacity: state.length ? 1 : 0,
    ...helpers.getStyles(),
  }}
)
  if children.items.length
    .searchCountry_items
      .searchCountry_itemsWrap
        = children.items

  `;
};