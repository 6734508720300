import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import Questions from 'models/questions';
import Item from './item';

export default {
  oninit() {
    this.template = resolveTemplate('pages/question-list');
    this.title = 'Мои вопросы';
    document.title = `${this.title} — Visard`;
  },
  view() {
    return this.template({
      state: {
        title: this.title,
        showOnlyOpen: Questions.showOnlyOpened,
      },
      children: {
        items: Questions.display.map(question =>
          m(Item, { key: question.id, question }),
        ),
      },
      actions: {
        toggleShowOnlyOpened: Questions.toggleShowOnlyOpened,
        toAddQuestionForm: () => m.route.set('faq/create'),
      },
    });
  },
};
