import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import OrderDocuments from 'models/orderDocuments';
import Item from './item';

export default {
  oninit() {
    this.template = resolveTemplate('pages/order/documents');
  },
  view({ attrs: { orderId } }) {
    return this.template({
      children: {
        items: OrderDocuments.list.map(({ list }) =>
          list.map(item => m(Item, { key: item.id, orderId, item })),
        ),
      },
    });
  },
};
