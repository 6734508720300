import Articles from 'models/articles';
import resolveTemplate from 'utils/resolveTemplate';

export default {
  oninit() {
    document.title = 'Виза для россиян';
    this.template = resolveTemplate('pages/articles-list');
  },
  view() {
    return this.template({
      state: Articles.articles,
    });
  },
};
