import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
// #if process.env.IS_MOBILE == 'false'
// // import IScroll from 'iscroll/build/iscroll-probe.js';
// #endif
import Faq from 'models/faq';
import Item from './item';

// #if process.env.IS_MOBILE == 'false'
// // let delta = 0;
// #endif

export default {
  oninit() {
    this.template = resolveTemplate('pages/question-create/faq');

    // #if process.env.IS_MOBILE == 'false'
// //     let lastY = 0;
// //     this.onscroll = () => {
// //       if (lastY === this.scroll.y) {
// //         window.scrollBy(0, delta);
// //       } else {
// //         lastY = this.scroll.y;
// //       }
// //     };
    // #endif
  },
  oncreate(vnode) {
    // #if process.env.IS_MOBILE == 'false'
// //     this.scroll = new IScroll(vnode.dom, {
// //       mouseWheel: true,
// //       scrollbars: 'custom',
// //       probeType: 2,
// //       interactiveScrollbars: true,
// //     });
// // 
// //     this.addFocus = () => {
// //       vnode.dom.children[1].children[0].classList.add('focus');
// //     };
// // 
// //     this.removeFocus = () => {
// //       vnode.dom.children[1].children[0].classList.remove('focus');
// //       delta = 0;
// //     };
// // 
// //     this.scroll.on('scroll', this.onscroll);
// //     this.scroll.on('scrollStart', this.addFocus);
// //     this.scroll.on('scrollEnd', this.removeFocus);
    // #endif
    // #if process.env.IS_MOBILE == 'true'
    const f = () => {};
    f(vnode.dom);
    // #endif
  },
  onbeforeremove() {
    // #if process.env.IS_MOBILE == 'false'
// //     delta = 0;
// //     this.scroll.off('scroll', this.onscroll);
// //     this.scroll.off('scrollStart', this.addFocus);
// //     this.scroll.off('scrollEnd', this.removeFocus);
// //     this.scroll.destroy();
    // #endif
  },
  view() {
    // #if process.env.IS_MOBILE == 'false'
// //     const scrollFunc = e => {
// //       delta = e.deltaY;
// //     };
    // #endif
    // #if process.env.IS_MOBILE == 'true'
    const scrollFunc = () => {};
    // #endif
    return this.template({
      actions: {
        onscroll: scrollFunc,
      },
      children: {
        items: Faq.display.map(question =>
          m(Item, {
            key: question.id,
            question,
            // #if process.env.IS_MOBILE == 'false'
// //             onUpdate: () => {
// //               setTimeout(() => {
// //                 this.scroll.refresh();
// //               }, 130);
// //             },
            // #endif
          }),
        ),
      },
    });
  },
};
