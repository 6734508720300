/* eslint-disable */

import m from 'mithril';
import Orders from 'models/orders';
import Countries from 'models/countries';
import resolveTemplate from 'utils/resolveTemplate';
// import roistatVisit from 'utils/roistatVisit';
import Tabs from 'components/widgets/tabs';
import DocumentsTab from './documents';
import InstitutionsTab from './institutions';
import QuestionnaireTab from './questionnaires';
import Persons from './persons';

export default {
  oninit() {
    this.template = resolveTemplate('pages/order');
    this.country = Countries.full[Orders.current.country];
    this.questionnairesTabsModel = null;
    this.setModel = model => {
      this.questionnairesTabsModel = model;
    };
  },
  view() {
    const { id: orderId, number, cost, title: product_title } = Orders.current;

    return m.fragment({}, [
      m(
        'div.backBlur',
        m('img.backBlur_img', {
          src: this.country.background.blur,
        }),
      ),
      this.template({
        state: {
          flag: `flags_${this.country.iso}`,
          title: product_title, //this.country.name,
          orderid: number.toLocaleString('ru'),
          cost: cost.toLocaleString('ru'),
        },
        children: {
          persons: m(Persons, {
            orderId,
            qModel: this.questionnairesTabsModel,
          }),
          tabs: m(Tabs, {
            tabs: [
              {
                key: 'questionnaire',
                title: 'Анкета',
                content: QuestionnaireTab,
                args: { bubble: this.setModel },
              },
              {
                key: 'documents',
                title: 'Докуметны',
                content: DocumentsTab,
                args: { orderId },
              },
              {
                key: 'institutions',
                title: 'Учреждения',
                content: InstitutionsTab,
              },
            ],
            classes: {
              wrapper: '',
              headerList: 'tabSwitch--type1',
              headerItem: '',
              headerItemActive: 'tabSwitch_label--active',
            },
            onUnderline: true,
            onIcons: true,
          }),
        },
      }),
    ]);
  },
};
