const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
li.tabSwitch_label(
  className=state.class
  onclick=actions.onclick
)
  span.tabSwitch_labelSpan
    if state.onIcons
      span.tabSwitch_labelIcon
        =children.icon
        span.tabSwitch_labelIconActive
          =children.iconActive
    span.tabSwitch_labelText=state.title

  `;
};