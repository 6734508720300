const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
nav.scrollable.swipeMenu(className=state.className)
  .swipeMenu_header
    //- a.enterButton
  .swipeMenu_inner
    .swipeMenu_list
      each link, index in state.links
        a.menu_item(
          key=${`${link.uri}--${state.uri}`}
          href=link.url
          onclick=actions.closeMenu
          class=${state.uri === link.url ? 'active' : ''}
        )
          span.line= link.text

  `;
};