import m from 'mithril';

export default {
  oninit({ attrs }) {
    const {
      settings: { list },
      updateValue,
      path,
    } = attrs;

    this.update = (id, active) =>
      updateValue(path.concat('list'), {
        ...list,
        [id]: { ...list[id], active },
      });
  },
  view({ attrs }) {
    const {
      list,
      settings: { list: values = {}, disabled },
      dataIsLoaded,
    } = attrs;

    return m(
      'ul',
      list.map(({ id, name }) =>
        m(
          'li',
          m(
            'label',
            m('input', {
              type: 'checkbox',
              checked: values[id] && values[id].active,
              onclick: () => this.update(id, !values[id].active),
              disabled: !dataIsLoaded || disabled,
            }),
            name,
          ),
        ),
      ),
    );
  },
};
