const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
section.askQuestions
  = children.form
  div.faqList
    div.faqList_scroll
       = children.faq

  `;
};