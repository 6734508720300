export default (obj, path) => {
  try {
    // eslint-disable-next-line
    return eval(
      `obj${path
        .split('.')
        .map(key => {
          const int = parseInt(key, 10);
          if (int && `${int}` === key) {
            return `[${key}]`;
          }
          return `['${key}']`;
        })
        .join('')}`,
    );
  } catch (e) {
    return null;
  }
};
