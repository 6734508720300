const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div.orderCalculator_side.orderCalculator_side--result(
  className=state.className
)
  .orderCalculator_fullCost(
    data-countryid=state.id
  )
    span#price #{state.price} ₽
  .orderCalculator_fullCostDetails
    span 
      | В том числе консульские 
      br
      | и визовые сборы — #{state.conculPrice} ₽
  = children.applicants
  = children.submit

  `;
};