import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import IScroll from 'iscroll/build/iscroll-probe.js';
import OrdersModel from 'models/orders';
import Item from './item.js';

export default {
  oninit() {
    this.title = 'История заказов';
    this.template = resolveTemplate('pages/order-list');
    document.title = `История заказов — Visard`;
  },
  oncreate() {
    this.scroll = new IScroll('.scrollable', {
      mouseWheel: true,
      scrollbars: true,
      scrollX: true,
      scrollY: false,
    });
  },
  view() {
    return this.template({
      children: {
        items: OrdersModel.list.map(order => m(Item, { order })),
      },
    });
  },
};
