import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import Modal from 'models/modal';
import { API } from 'constants.js';
// #if process.env.IS_MOBILE == 'true'
import header from '../mobile/header';
// #endif
// #if process.env.IS_MOBILE == 'false'
// // import header from './header';
// #endif
import footer from './footer';
import modal from './modal';

let resolved = false;

const eventPageChanged = new window.Event('pageChanged');

const metaTypes = {
  seo_desc: ['name', 'description'],
  seo_keywords: ['name', 'keywords'],
  social_title: ['property', 'og:title'],
  social_desc: ['property', 'og:description'],
  social_image: ['property', 'og:image'],
  href: ['property', 'og:url'],
};

export default (page, whiteTheme = false, footerShow = true) => ({
  oninit() {
    window.scrollTo({ top: 0 });

    m.request(`${API}/meta`, {
      params: {
        path: `/${m.route.get()}`,
      },
    }).then(({ data }) => {
      const metaData = { ...data, href: window.location.href };
      if (metaData.seo_title) {
        document.title = metaData.seo_title;
      }
      document
        .querySelectorAll('[data-mark]')
        .forEach(item => document.head.removeChild(item));

      Object.keys(metaData).forEach(key => {
        if (metaData[key] && metaTypes[key]) {
          const meta = document.createElement('meta');
          meta.setAttribute(...metaTypes[key]);
          meta.setAttribute('content', metaData[key]);
          meta.setAttribute('data-mark', true);
          document.head.appendChild(meta);
        }
      });

      // #if process.env.IS_DEVELOPMENT == 'false'
// //       window.dataLayer.push({
// //         event: 'pageview',
// //         pagetitle: document.title,
// //       });
// //       window.dataLayer.push({ referrer2: document.location.href });
      // #endif
      // window.dataLayer.push({ event: 'gtm.elementVisibility' });

      window.dispatchEvent(eventPageChanged);
    });

    if (!resolved) {
      resolved = true;
      document.body.removeAttribute('style');
    }
    this.template = resolveTemplate('layout');
  },
  view() {
    return m.fragment({}, [
      this.template({
        children: {
          header: m(header, {
            whiteTheme,
          }),
          page: m(page),
          footer: footerShow && m(footer),
        },
      }),
      m(modal),
      ...[...(Modal.items.root || [])].map(item => m(item, { key: item.id })),
    ]);
  },
});
