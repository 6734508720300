/* eslint-disable */
import m from 'mithril';
import { STORE } from 'constants.js';

const resolve = template => {
  const s = STORE;

  try {
    return require(`../views/${s.isMobile ? 'mobile' : 'desktop'}/${template}`);
  } catch (e) {
    return require(`../views/common/${template}`);
  }
};

export default template => {
  try {
    return resolve(template);
  } catch (e) {
    return () => m('span', `Template "${template}" not found`);
  }
};
