import m from 'mithril';
import Articles from 'models/articles';

export default {
  onupdate() {
    setTimeout(() => {
      [...document.querySelectorAll('[data-internal]')].forEach(dom => {
        dom.onclick = e => {
          e.preventDefault();
          window.scrollTo({ top: 0 });
          m.route.set(dom.getAttribute('href'));
        };
      });
    }, 10);
  },
  view() {
    return m.trust(Articles.full[m.route.param('url')].pug);
  },
};
