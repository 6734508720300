/* eslint-disable */

import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import Questionare from 'models/questionnaries';
import Tabs from 'components/widgets/tabs';
import control from './control';
import Persons from 'models/persons';
import Questionaries from '../../../../models/questionnaries';

const form = {
  view({ attrs: { items, path = [] } }) {
    return items.map(item => {
      const newPath = path.concat(item.id);
      const settings = Questionare.getSettings(newPath) || {};

      if (!settings.show) return null;

      if (item.type === 'group') {
        const { id, label, questions } = item;

        return m(
          'fieldset',
          m('legend', label),
          m(form, {
            items: questions,
            path: path.concat(id),
          }),
        );
      }

      return m(control, {
        item,
        path: newPath,
        settings,
        dataIsLoaded: !!Questionare.current.groups,
        updateValue: Questionare.updateValue,
      });
    });
  },
};

export default {
  oninit() {
    this.template = resolveTemplate('pages/order/questionnaires');

    // console.log(this.template);
  },
  view({ attrs: { bubble } }) {
    const tabs = Object.values(Questionare.current.groups).filter(group =>
      Questionare.getControls(group.id).some(({ id }) => {
        const item = Questionare.getSettings([id]);
        return item && item.show;
      }),
    );

    this.template = resolveTemplate('pages/order/questionnaires');
    // console.log(this.template);
    return this.template({
      state: {},
      children: {
        tabs: m(Tabs, {
          bubble,
          tabs: tabs.map(({ id, title }) => ({
            key: `q${id}`,
            title,
            content: form,
            args: { items: Questionare.getControls(id) },
          })),
          classes: {
            wrapper: '',
            tabWrapper: 'questionnaire gpScroll gpScroll--y',
            headerList: 'tabSwitch--type2 fadeSides fadeSides--x',
            headerItem: '',
            headerItemActive: 'tabSwitch_label--active',
            scrollHeader: 'gpScroll gpScroll--x',
          },
          onUnderline: false,
          onIcons: false,
        }),
      },
    });
  },
};
