/* eslint-disable */

import m from 'mithril';
import debounce from 'lodash/debounce';
import set from 'utils/set';
import get from 'utils/get';
import query from 'utils/query';
import { API } from 'constants.js';

const Orders = {
  current: {},
  list: [],
  requestsCount: 0,
  fetchingValues: false,
  XHR: null,

  fetchAll: query(`${API}/orders`, data => {
    Orders.list = data;
  }),

  fetch: query(
    id => `${API}/order/${id}`,
    data => {
      Orders.current = data;
    },
  ),

  fetchInstitutions: query(
    personId => {
      const { id } = Orders.current;
      const personString = `?personId=${personId}`;

      return `${API}/order/${id}/institutions${personId ? personString : ''}`;
    },
    data => {
      Orders.current.institutions = data;
    },
  ),

  setCountry(id) {
    if (Orders.current.country === id) {
      return Promise.resolve(id);
    }

    Orders.current.country = id;
    Orders.current.fetched = false;

    return Promise.all([Orders.fetchServices(), Orders.fetchValues()])
      .then(([services, values]) => {
        Orders.current.fetched = true;
        Orders.current.values = values;
        Orders.current.services = services;
      })
      .then(() => id)
      .finally(() => {
        setTimeout(() => {
          m.redraw();
        }, 100);
      });
  },

  fetchServices() {
    const data = {
      city_id: m.route.param('city_id'),
    };

    return m
      .request(`${API}/countries/${Orders.current.country}/services`, {
        params: data.city_id ? data : {},
        withCredentials: true,
      })
      .then(({ data: services }) => services);
  },

  fetchValues() {
    const data = {
      city_id: m.route.param('city_id'),
    };

    return m
      .request(`${API}/countries/${Orders.current.country}/values`, {
        params: data.city_id ? data : {},
        withCredentials: true,
      })
      .then(({ data: values }) => values);
  },

  sendValues() {
    Orders.fetchingValues = true;
    const currentRequest = Orders.requestsCount + 1;
    Orders.requestsCount = currentRequest;

    return m
      .request({
        method: 'POST',
        url: `${API}/countries/${Orders.current.country}/values`,
        body: Orders.current.values,
        withCredentials: true,
        config: function(xhr) {
          this.XHR = xhr;
        },
      })
      .then(({ data }) => {
        if (currentRequest === Orders.requestsCount) {
          Orders.current.values = data;
        }
      })
      .finally(() => {
        Orders.fetchingValues = false;
      });
  },

  abortSendValues() {
    if (this.XHR !== null) this.XHR.abort();
    this.XHR = null;
  },

  updateValue(path, value) {
    Orders.fetchingValues = true;
    set(Orders, `current.values.services.${path}`, value);

    if (/additional/.test(path)) {
      // eslint-disable-next-line
      const [newPath, list, id] = path.match(/((?:[^.]+\.?){2})\.([^.]+)/);

      Object.entries(Orders.getSettings(list)).forEach(([key]) => {
        let isActive = false;
        if (id === key) {
          isActive = true;
        }

        set(Orders, `current.values.services.${list}.${key}.active`, isActive);
      });
    }

    return Orders.sendValuesDebounce();
  },

  getValue: path => get(Orders, `current.values.${path}`),

  getControls: () => get(Orders, 'current.services'),

  getSettings: path => get(Orders, `current.values.services.${path}`),

  clear() {
    Orders.current = {};
    Orders.list = [];
  },
};

Orders.sendValuesDebounce = debounce(Orders.sendValues, 200);

// #if process.env.IS_DEVELOPMENT == 'true'
if (window.models) {
  window.models.Orders = Orders;
}
// #endif

export default Orders;
