import m from 'mithril';
import { API } from 'constants.js';
import LoadingBar from './loadingBar';

const highlight = (string, rx) =>
  string.replace(rx, "$1<span class='faqList_highlight'>$2</span>");

const Faq = {
  list: [],
  display: [],

  fetchAll() {
    LoadingBar.start();

    return m
      .request(`${API}/faq`)
      .then(({ data: faq }) => {
        Faq.list = faq;
        Faq.display = faq;
      })
      .finally(LoadingBar.finish);
  },

  filterByString: query => {
    const matchedTitleItems = [];
    const matchedBodyItems = [];
    const unmatchedItems = [];

    const rx = new RegExp(`(^| )(${query})`, 'gi');

    Faq.list.forEach(({ id, question, answer }) => {
      if (rx.test(question)) {
        matchedTitleItems.push({
          id,
          question: highlight(question, rx),
          answer: highlight(answer, rx),
        });
      } else if (rx.test(answer)) {
        matchedBodyItems.push({
          id,
          question: highlight(question, rx),
          answer: highlight(answer, rx),
        });
      } else {
        unmatchedItems.push({
          id,
          question,
          answer,
        });
      }
    });

    Faq.display = [
      ...matchedTitleItems,
      ...matchedBodyItems,
      ...unmatchedItems,
    ];
  },
};

// #if process.env.IS_DEVELOPMENT == 'true'
if (window.models) {
  window.models.Faq = Faq;
}
// #endif

export default Faq;
