const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
.menuMyOrders.menuMyOrders--arrow(...state.attrs)
  .menuMyOrders_wrap
    each order in state.orders
      Link.menuMyOrders_item.spriteFlags.middleFlag(
        key=order.id
        className=helpers.getFlagClass(order)
        href=helpers.getHref(order)
      )
        div
          span.menuMyOrders_row.menuMyOrders_country
            = order.title
          span.menuMyOrders_row.menuMyOrders_completed
            | Заполнено на ${order.fillPercentage} %
    else
      .menuMyOrders_noOrders У вас пока нет заказов
    Link.menuMyOrders_all(href='/orders')
      span.line
        |  Все заказы

  `;
};