import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import Faq from 'models/faq';
import User from 'models/user';
import Input from 'components/widgets/input';
import FormModel from './model';

const setBody = ({ target: { value } }) => FormModel.setValue('body')(value);
const setEmail = ({ target: { value } }) => FormModel.setValue('email')(value);

const setTitle = ({ target: { value } }) => {
  FormModel.setValue('title')(value);
  Faq.filterByString(value);
};

export default {
  oninit() {
    this.template = resolveTemplate('pages/question-create/form');
  },
  onupdate() {
    if (FormModel.formSubmitted) {
      FormModel.formSubmitted = false;
    }
  },
  view() {
    const {
      onSubmit,
      values: { title, body, email },
      formSubmitted,
      errors,
    } = FormModel;

    return this.template({
      state: {
        isLogged: !!User.current,
        errors,
        formSubmitted,
        hasErrors: FormModel.hasErrors,
      },
      actions: {
        onSubmit,
      },
      children: {
        emailInput: m(Input, {
          label: 'Электронная почта',
          // placeholder: 'E-mail',
          value: email,
          oninput: setEmail,
          name: 'email',
          w100: true,
          required: true,
        }),
        titleInput: m(Input, {
          label: 'Тема вопроса',
          // placeholder: 'Тема обращения',
          value: title,
          oninput: setTitle,
          name: 'title',
          w100: true,
          required: true,
        }),
        bodyInput: m(Input, {
          tagName: 'textarea',
          label: 'Сообщение',
          // placeholder: 'Текст обращения',
          value: body,
          oninput: setBody,
          name: 'body',
          w100: true,
          required: true,
        }),
      },
    });
  },
};
