const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
section.modalWindow_item(
  role='dialog'
)
  h1 Заголовок
  p Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit aperiam placeat, voluptate aliquam repellendus sequi, perspiciatis est inventore ipsam adipisci totam numquam! Hic voluptatem accusantium accusamus. Praesentium eos veritatis amet.
  p Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit aperiam placeat, voluptate aliquam repellendus sequi, perspiciatis est inventore ipsam adipisci totam numquam! Hic voluptatem accusantium accusamus. Praesentium eos veritatis amet.
  p Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit aperiam placeat, voluptate aliquam repellendus sequi, perspiciatis est inventore ipsam adipisci totam numquam! Hic voluptatem accusantium accusamus. Praesentium eos veritatis amet.
  p Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit aperiam placeat, voluptate aliquam repellendus sequi, perspiciatis est inventore ipsam adipisci totam numquam! Hic voluptatem accusantium accusamus. Praesentium eos veritatis amet.
  p Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit aperiam placeat, voluptate aliquam repellendus sequi, perspiciatis est inventore ipsam adipisci totam numquam! Hic voluptatem accusantium accusamus. Praesentium eos veritatis amet.
  p Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit aperiam placeat, voluptate aliquam repellendus sequi, perspiciatis est inventore ipsam adipisci totam numquam! Hic voluptatem accusantium accusamus. Praesentium eos veritatis amet.
  p Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit aperiam placeat, voluptate aliquam repellendus sequi, perspiciatis est inventore ipsam adipisci totam numquam! Hic voluptatem accusantium accusamus. Praesentium eos veritatis amet.
  p Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit aperiam placeat, voluptate aliquam repellendus sequi, perspiciatis est inventore ipsam adipisci totam numquam! Hic voluptatem accusantium accusamus. Praesentium eos veritatis amet.
  p Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit aperiam placeat, voluptate aliquam repellendus sequi, perspiciatis est inventore ipsam adipisci totam numquam! Hic voluptatem accusantium accusamus. Praesentium eos veritatis amet.
  p Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit aperiam placeat, voluptate aliquam repellendus sequi, perspiciatis est inventore ipsam adipisci totam numquam! Hic voluptatem accusantium accusamus. Praesentium eos veritatis amet.

  `;
};