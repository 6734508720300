import m from 'mithril';
import autoselect from './autoselect';
import counter from './counter';
import switcher from './switcher';

const inputs = {
  autoselect,
  counter,
  switcher,
};

export default {
  view({ attrs: { items, path, getSettings, updateValue } }) {
    return m.fragment(
      {},
      items.map(({ type, ...attrs }) =>
        inputs[type]
          ? m(inputs[type], {
              path: `${path}.${attrs.id}`,
              getSettings,
              updateValue,
              ...attrs,
            })
          : null,
      ),
    );
  },
};
