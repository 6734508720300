const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
header.headerPage(class=${`${state.className}${state.hasUserAPI ? '' : ' product'}`})
  .headerPage__side
    Link.logo(
      href='/'
    )
      = children.logo
    .menuTypeFirst
      each link, index in state.links
        Link.menuTypeFirst_item(
          key=${`${link.uri}--${state.uri}`}
          href=link.url
          class=${state.uri === link.url ? 'active' : ''}
          id=link.id
        )
          span.line= link.text
    .headerPage_phone
      = children.callback
      a.phoneNumberTypeFirst(href=state.phone.url)
        span=state.phone.text
  if state.hasUserAPI
    .headerPage__side
      if state.user
        = children.ordersDropDown
        = children.notificationsDropDown
        = children.profileDropDown
      else
        button.buttonLight(
          name='login'
          onclick=actions.login
          id='LoginOrRegisterButton'
        ) Вход
//- .headerPage(class=state.className)
//-   a.logo(
//-     href=''
//-     oncreate=m.route.Link
//-   )
//-     = children.logo
//-   .menuTypeFirst
//-     each link, index in state.links
//-       a.menuTypeFirst_item(
//-         key=${`${link.uri}--${state.uri}`}
//-         href=link.url
//-         oncreate=m.route.Link
//-         class=${state.uri === link.url ? 'active' : ''}
//-         id=link.id
//-       )
//-         span.line= link.text
//-   .headerPage_phone
//-     = children.callback
//-     a.phoneNumberTypeFirst.callHanterHeader(href=state.phone.url)
//-       span=state.phone.text
//-   if state.hasUserAPI
//-     if state.user
//-       = children.ordersDropDown
//-       = children.notificationsDropDown
//-       = children.profileDropDown
//-     else
//-       button(
//-         name='login'
//-         onclick=actions.login
//-         id='LoginOrRegisterButton'
//-       ) Вход / Регистрация

  `;
};