import m from 'mithril';

export default {
  view({ attrs }) {
    const {
      id,
      label,
      list_answer: list,
      path,
      placeholder,
      settings: { value, disabled },
      updateValue,
      dataIsLoaded,
      multiselect,
      alternative_answer: alternative,
    } = attrs;

    return m('div', [
      m(
        'label',
        {
          for: `select_${id}`,
        },
        label,
      ),
      m('br'),
      m(
        'select',
        {
          id: `select_${id}`,
          onchange: ({ target: { value: inputValue } }) =>
            updateValue(path.concat('value'), inputValue),
          disabled: !dataIsLoaded || disabled,
          multiple: multiselect,
          value: value || '',
        },
        m('option', { value: '' }, placeholder || 'Выберите значение'),
        list.map(({ id: key, name, name2 }) =>
          m(
            'option',
            {
              key,
              value: key,
            },
            name,
            name2 ? ` (${name2})` : '',
          ),
        ),
        alternative
          ? m('option', { value: 'alternative_answer' }, alternative)
          : null,
      ),
    ]);
  },
};
