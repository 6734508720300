import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import Orders from 'models/orders';
import personSvg from './persons.svg';

export default {
  view({ attrs }) {
    return resolveTemplate('widgets/order-result/applicants/open-button')({
      state: {
        peopleCount: Orders.current.values.persons.length,
        attrs,
      },
      children: {
        icon: m.trust(
          personSvg.replace('setClass', 'orderCalculator_iconPersons'),
        ),
      },
    });
  },
};
