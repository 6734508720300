const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div.contactsPage
  div.contactsPageInner
    div.contactsPage_info
      div.contactsPage_infoSide
        h1.titleBig.contactsPage_h1 Контакты
        p.pStandart.contactsPage_p 
          b Адрес: 
          | Тюмень, «Сити-Центр», 
          nobr Герцена, 64
        p.pStandart.contactsPage_p 
          b Телефон: 
          a(
            href='tel:+73452566566'
          ) +7 (3452) 566‒566
        p.pStandart.contactsPage_p
          b Почта: 
          a(
            href='mailto:visa@visatmn.ru'
          ) visa@visatmn.ru
        p.pStandart.contactsPage_p
          b Время работы: 
          | #[br]ПН-ПТ с 09:00 до 20:00, #[br]СБ с 10:00 до 18:00,#[br]ВС выходной
        ul.socialList
          li.socialList_item
            a.socialList_link.socialList_vk(
              href='https://vk.com/visardtmn'
              target='_blank'
            ) Мы на VK
          li.socialList_item 
            a.socialList_link.socialList_fb(
              href='https://www.facebook.com/groups/547212322052034/'
              target='_blank'
            ) Мы в Фейсбук
          li.socialList_item
            a.socialList_link.socialList_ok(
              href='https://ok.ru/visardviza'
              target='_blank'
            ) Мы на Однокласниках
          li.socialList_item 
            a.socialList_link.socialList_in(
              href='https://www.instagram.com/visardtmn/'
              target='_blank'
            ) Мы в Инстаграме
          li.socialList_item 
            a.socialList_link.socialList_tm(
              href='https://t.me/visardtmn'
              target='_blank'
            ) Мы в Телеграм
    div.contactsPage_map(
      id='map'
      style='width: 100%; height: 100%'
    )
  `;
};