const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div.reviewsPage
  div.reviewsList
    h2.titleBig.colorGray Отзывы клиентов
    div.reviewsList_list
      article.reviewsList_item
        figure.reviewsList_figure
          img.reviewsList_img(
            src='/img/reviews/khusainov_ildar-276@2x.jpg'
            alt=''
          )
        div.reviewsList_txt
          div.reviewsList_txtInner
            h3.reviewsList_txtTitle Агентство недвижимости «Этажи»
            p.pStandart Я Хусаинов Ильдар, учредитель федеральной компании Этажи, обширная география работы заставляет много путешествовать по России, а с образовательными или бизнес целями я регулярно вылетаю в другие страны. Визовые вопросы уже много лет доверяю визовому центру Визард. Они легко делают визу в любой уголок мира.
          a.reviewsList_instLink(
            href='//instagram.com/khusainov_ildar'
            target='_blank'
          )
            svg.reviewsList_instLinkSvg(
              xmlns='http://www.w3.org/2000/svg' 
              viewBox='0 0 17 17'
              width='17px'
              height='17px'
            )
              path(
                d='M5,0A5,5,0,0,0,0,5v7a5,5,0,0,0,5,5h7a5,5,0,0,0,5-5V5a5,5,0,0,0-5-5Zm7.73,3.4a.85.85,0,1,1-.85.85A.85.85,0,0,1,12.75,3.4ZM8.5,4.25A4.25,4.25,0,1,1,4.25,8.5,4.25,4.25,0,0,1,8.5,4.25Zm0,.85a3.4,3.4,0,1,0,3.4,3.4A3.41,3.41,0,0,0,8.5,5.1Z'
                fill='#8144e0'
              )
            span khusainov_ildar
      article.reviewsList_item
        figure.reviewsList_figure
          img.reviewsList_img(
            src='/img/reviews/vicky_b2b-276@2x.jpg'
            alt=''
          )
        div.reviewsList_txt
          div.reviewsList_txtInner
            h3.reviewsList_txtTitle Ресторанный холдинг «МаксиМ»
            p.pStandart Я Барсукова Виктория, руководитель ресторанного холдинга, очень часто путешествую со своими детьми, хочется показать им весь мир. Визовой поддержкой в наших поездках занимается визовый центр Визард, они делают визы под любые задачи и маршруты, с ними всегда очень легко и понятно.
          a.reviewsList_instLink(
            href='//instagram.com/vicky_b2b'
            target='_blank'
          )
            svg.reviewsList_instLinkSvg(
              xmlns='http://www.w3.org/2000/svg' 
              viewBox='0 0 17 17'
              width='17px'
              height='17px'
            )
              path(
                d='M5,0A5,5,0,0,0,0,5v7a5,5,0,0,0,5,5h7a5,5,0,0,0,5-5V5a5,5,0,0,0-5-5Zm7.73,3.4a.85.85,0,1,1-.85.85A.85.85,0,0,1,12.75,3.4ZM8.5,4.25A4.25,4.25,0,1,1,4.25,8.5,4.25,4.25,0,0,1,8.5,4.25Zm0,.85a3.4,3.4,0,1,0,3.4,3.4A3.41,3.41,0,0,0,8.5,5.1Z'
                fill='#8144e0'
              )
            span vicky_b2b
      article.reviewsList_item
        figure.reviewsList_figure
          img.reviewsList_img(
            src='/img/reviews/ipiskulin-276@2x.jpg'
            alt=''
          )
        div.reviewsList_txt
          div.reviewsList_txtInner
            h3.reviewsList_txtTitle Маркетинговая агентство «Love${'\u00A0'}marketing»
            p.pStandart Я Пискулин Илья, моя компания занимается маркетингом по роду работы я много летаю и много об этом рассказываю. Но, личные путешествия я предпочитаю проводить в тишине один на один со своими близкими. За визами мы обращаемся в Визард, они также тихо и незаметно решают наши визовые вопросы, чтобы мы могли больше времени оставить для себя.
          a.reviewsList_instLink(
            href='//instagram.com/ipiskulin'
            target='_blank'
          )
            svg.reviewsList_instLinkSvg(
              xmlns='http://www.w3.org/2000/svg' 
              viewBox='0 0 17 17'
              width='17px'
              height='17px'
            )
              path(
                d='M5,0A5,5,0,0,0,0,5v7a5,5,0,0,0,5,5h7a5,5,0,0,0,5-5V5a5,5,0,0,0-5-5Zm7.73,3.4a.85.85,0,1,1-.85.85A.85.85,0,0,1,12.75,3.4ZM8.5,4.25A4.25,4.25,0,1,1,4.25,8.5,4.25,4.25,0,0,1,8.5,4.25Zm0,.85a3.4,3.4,0,1,0,3.4,3.4A3.41,3.41,0,0,0,8.5,5.1Z'
                fill='#8144e0'
              )
            span ipiskulin
      article.reviewsList_item
        figure.reviewsList_figure
          img.reviewsList_img(
            src='/img/reviews/eteristefeykina-276@2x.jpg'
            alt=''
          )
        div.reviewsList_txt
          div.reviewsList_txtInner
            h3.reviewsList_txtTitle Международное свадебное агентство «The${'\u00A0'}Wedding${'\u00A0'}Stars»
            p.pStandart Я Стефейкина Этери, за последние годы моя жизнь круто изменилась, я и моя семья смогли позволить себе то, о чем мы всегда мечтали, среди всего прочего это конечно путешествия. За визой всегда приходим в Визард, отдаем документы и спим спокойно,  потому что знаем, виза выйдет гарантировано.
          a.reviewsList_instLink(
            href='//instagram.com/eteristefeykina'
            target='_blank'
          )
            svg.reviewsList_instLinkSvg(
              xmlns='http://www.w3.org/2000/svg' 
              viewBox='0 0 17 17'
              width='17px'
              height='17px'
            )
              path(
                d='M5,0A5,5,0,0,0,0,5v7a5,5,0,0,0,5,5h7a5,5,0,0,0,5-5V5a5,5,0,0,0-5-5Zm7.73,3.4a.85.85,0,1,1-.85.85A.85.85,0,0,1,12.75,3.4ZM8.5,4.25A4.25,4.25,0,1,1,4.25,8.5,4.25,4.25,0,0,1,8.5,4.25Zm0,.85a3.4,3.4,0,1,0,3.4,3.4A3.41,3.41,0,0,0,8.5,5.1Z'
                fill='#8144e0'
              )
            span eteristefeykina
      article.reviewsList_item
        figure.reviewsList_figure
          img.reviewsList_img(
            src='/img/reviews/katyapopovastyle-276@2x.jpg'
            alt=''
          )
        div.reviewsList_txt
          div.reviewsList_txtInner
            h3.reviewsList_txtTitle KatyaPopovaStyle.ru
            p.pStandart Я Катерина Попова, персональный стилист. Я обожаю путешествовать, особенно люблю Италию и конечно Францию. Уже много лет я, вся моя семья, и даже мои друзья оформляют визу в Визовом центре Визард, надо ли говорить что нам все нравится и мы возвращаемся снова и снова.
          a.reviewsList_instLink(
            href='//instagram.com/katyapopovastyle'
            target='_blank'
          )
            svg.reviewsList_instLinkSvg(
              xmlns='http://www.w3.org/2000/svg' 
              viewBox='0 0 17 17'
              width='17px'
              height='17px'
            )
              path(
                d='M5,0A5,5,0,0,0,0,5v7a5,5,0,0,0,5,5h7a5,5,0,0,0,5-5V5a5,5,0,0,0-5-5Zm7.73,3.4a.85.85,0,1,1-.85.85A.85.85,0,0,1,12.75,3.4ZM8.5,4.25A4.25,4.25,0,1,1,4.25,8.5,4.25,4.25,0,0,1,8.5,4.25Zm0,.85a3.4,3.4,0,1,0,3.4,3.4A3.41,3.41,0,0,0,8.5,5.1Z'
                fill='#8144e0'
              )
            span katyapopovastyle
      article.reviewsList_item
        figure.reviewsList_figure
          img.reviewsList_img(
            src='/img/reviews/ingaiurova-276@2x.jpg'
            alt=''
          )
        div.reviewsList_txt
          div.reviewsList_txtInner
            h3.reviewsList_txtTitle Инга Юрова, «Бизнес-Эго»
            p.pStandart Чего всем хочется при заказе визы? Отдать документы, а затем получить шенген, забыв какие хлопоты с его изготовлением связаны. Некоторые компании имитируют бурную деятельность, пока оказывают услугу, всего тебя задергают. «Визард» напоминает о себе только, когда в твоем паспорте уже стоит виза. Для меня такой уровень обслуживания важен еще и потому, что визы мы заказываем для наших клиентов, выезжающих на обучение за рубеж из Тюмени. Если я рекомендую компанию, мне нужно быть в ней уверенной. Визовый центр «Визард» проверила многократно. Рекомендую!))
          a.reviewsList_instLink(
            href='//instagram.com/ingaiurova'
            target='_blank'
          )
            svg.reviewsList_instLinkSvg(
              xmlns='http://www.w3.org/2000/svg' 
              viewBox='0 0 17 17'
              width='17px'
              height='17px'
            )
              path(
                d='M5,0A5,5,0,0,0,0,5v7a5,5,0,0,0,5,5h7a5,5,0,0,0,5-5V5a5,5,0,0,0-5-5Zm7.73,3.4a.85.85,0,1,1-.85.85A.85.85,0,0,1,12.75,3.4ZM8.5,4.25A4.25,4.25,0,1,1,4.25,8.5,4.25,4.25,0,0,1,8.5,4.25Zm0,.85a3.4,3.4,0,1,0,3.4,3.4A3.41,3.41,0,0,0,8.5,5.1Z'
                fill='#8144e0'
              )
            span ingaiurova
      article.reviewsList_item
        figure.reviewsList_figure
          img.reviewsList_img(
            src='/img/reviews/romanmamontov-276@2x.jpg'
            alt=''
          )
        div.reviewsList_txt
          div.reviewsList_txtInner
            h3.reviewsList_txtTitle Роман Мамонтов, «Mamontov${'\u00A0'}Production»
            p.pStandart Визовый центр отправлял нашу семью во Францию, в Евродиснейленд. Порадовал профессиональный подход к делу. Четкий инструктаж, помощь в сборе документов, оперативная консультация по любому вопросу. Ехали с 6-ти -летним ребенком, поэтому важно было, чтобы все было тщательно спланировано. В итоге поездка получилась просто замечательной. Сказочное путешествие без единого звонка волшебницам Визового центра «Визард». Спасибо!
          a.reviewsList_instLink(
            href='//instagram.com/romanmamontov'
            target='_blank'
          )
            svg.reviewsList_instLinkSvg(
              xmlns='http://www.w3.org/2000/svg' 
              viewBox='0 0 17 17'
              width='17px'
              height='17px'
            )
              path(
                d='M5,0A5,5,0,0,0,0,5v7a5,5,0,0,0,5,5h7a5,5,0,0,0,5-5V5a5,5,0,0,0-5-5Zm7.73,3.4a.85.85,0,1,1-.85.85A.85.85,0,0,1,12.75,3.4ZM8.5,4.25A4.25,4.25,0,1,1,4.25,8.5,4.25,4.25,0,0,1,8.5,4.25Zm0,.85a3.4,3.4,0,1,0,3.4,3.4A3.41,3.41,0,0,0,8.5,5.1Z'
                fill='#8144e0'
              )
            span romanmamontov
      article.reviewsList_item
        figure.reviewsList_figure
          img.reviewsList_img(
            src='/img/reviews/zhikin-276@2x.jpg'
            alt=''
          )
        div.reviewsList_txt
          div.reviewsList_txtInner
            h3.reviewsList_txtTitle Виктор Жилкин, «Raceonepro»
            p.pStandart Я – постоянный клиент Визового центра, и главным образом, благодаря компетентности его сотрудников. Подобрали самый выгодный вариант оформления визы. Рекомендовал Визовый центр "Визард" всем знакомым в Тюмени. Так держать!
          a.reviewsList_instLink(
            href='//instagram.com/zhikin'
            target='_blank'
          )
            svg.reviewsList_instLinkSvg(
              xmlns='http://www.w3.org/2000/svg' 
              viewBox='0 0 17 17'
              width='17px'
              height='17px'
            )
              path(
                d='M5,0A5,5,0,0,0,0,5v7a5,5,0,0,0,5,5h7a5,5,0,0,0,5-5V5a5,5,0,0,0-5-5Zm7.73,3.4a.85.85,0,1,1-.85.85A.85.85,0,0,1,12.75,3.4ZM8.5,4.25A4.25,4.25,0,1,1,4.25,8.5,4.25,4.25,0,0,1,8.5,4.25Zm0,.85a3.4,3.4,0,1,0,3.4,3.4A3.41,3.41,0,0,0,8.5,5.1Z'
                fill='#8144e0'
              )
            span zhikin
  `;
};