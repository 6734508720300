/* eslint-disable */

import m from 'mithril';
import 'styles/main';
import { User, Questions, Orders } from 'observers';
import Articles from 'models/articles';
import Countries from 'models/countries';
import Questionaries from 'models/questionnaries';
import Faq from 'models/faq';

import * as Pages from './pages';
import Persons from './models/persons';
// import Banner from 'components/widgets/banner';

const root = document.getElementById('root');

// #if process.env.IS_DEVELOPMENT == 'false'
// // m.route.prefix = '';
// #endif

const { prefetched = {} } = window;

const checkUser = () => {
  if (!User.current) {
    throw new Error("User isn't authorized!");
  }
};

const defaultPage = page => ({
  onmatch: () => User.init(),
  render: () => m(page),
});

export default () => {
  m.route(
    root,
    '/',
    Object.entries({
      '': {
        onmatch: args =>
          Promise.all([
            User.init(),
            Countries.init(prefetched.countries, args),
            Articles.fetchForHomePage(),
          ]),
        render: () => m(Pages.HomePage),
      },
      visa: defaultPage(Pages.NotReadyPage),
      callback: defaultPage(Pages.CallbackPage),
      'o-servise': defaultPage(Pages.AboutPage),
      'viza-dlja-rossijan': {
        onmatch: () => Promise.all([User.init(), Articles.fetchArticles()]),
        render: () => m(Pages.ArticlesListPage),
      },
      'stati/:url...': {
        onmatch: args =>
          Promise.all([User.init(), Articles.fetchArticle(args)]),
        render: () => m(Pages.ArticlePage),
      },
      novosti: {
        onmatch: () => Promise.all([User.init(), Articles.fetchNews()]),
        render: () => m(Pages.NewsListPage),
      },
      'novosti/:url...': {
        onmatch: args =>
          Promise.all([User.init(), Articles.fetchArticle(args)]),
        render: () => m(Pages.ArticlePage),
      },
      otzyvy: defaultPage(Pages.ReviewsPage),
      kontakty: defaultPage(Pages.ContactsPage),
      faq: {
        onmatch: () =>
          User.init()
            .then(() => {
              if (User.current.questionsCount === 0) {
                m.route.set('faq/create');
              }
            })
            .catch(() => {
              m.route.set('faq/create');
            }),
        render: () => m(Pages.QuestionListPage),
      },
      'faq/create': {
        onmatch: () =>
          User.init()
            .then(Faq.fetchAll)
            // eslint-disable-next-line no-console
            .catch(console.error),
        render: () => m(Pages.QuestionCreatePage),
      },
      'faq/:id': {
        onmatch: args =>
          User.init().then(() => {
            checkUser();
            return Promise.all([
              Countries.fetchAll(prefetched.countries),
              Questions.fetch(+args.id),
            ]);
          }),
        render: () => m(Pages.QuestionPage),
      },
      contacts: defaultPage(Pages.NotReadyPage),
      strany: {
        onmatch: () =>
          Promise.all([Countries.fetchAll(prefetched.countries), User.init()]),
        render: () => m(Pages.CountryListPage),
      },
      'strany/:key...': {
        onmatch(args) {
          return Promise.all([
            Countries.setCurrent(args.key).then(({ id }) =>
              Promise.all([Orders.setCountry(id), Countries.fetchHTML(id)]),
            ),
            Countries.fetchAll(prefetched.countries),
            User.init(),
          ]);
        },
        render: () => m(Pages.CountryPage),
      },
      orders: {
        onmatch: () =>
          User.init().then(() => {
            checkUser();
            return Countries.fetchAll(prefetched.countries);
          }),
        render: () => m(Pages.OrderListPage),
      },
      'orders/:id': {
        onmatch: args =>
          User.init().then(() => {
            checkUser();

            return Promise.all([
              Countries.fetchAll(prefetched.countries),
              Orders.fetch(+args.id),
              Questionaries.init(+args.id),
            ]);
          }),
        render: () => m(Pages.OrderPage),
      },
      500: defaultPage(Pages.NotReadyPage),
      logout: {
        onmatch: () =>
          new Promise(resolve => {
            m.route.set('');
            resolve();
          }),
      },
      ':url...': {
        onmatch: args =>
          Promise.all([User.init(), Articles.fetchArticle(args)]),
        render: () => m(Pages.ArticlePage),
      },
    }).reduce(
      (result, [key, value]) => ({
        ...result,
        [`/${key}`]: value,
      }),
      {},
    ),
  );
};
