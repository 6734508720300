import m from 'mithril';
import { API } from 'constants.js';
import WebSockets from 'models/webSocket';

const User = {
  current: null,
  apiNotImplemented: false,
  errorMessage: '',

  init() {
    if (!User.apiNotImplemented) {
      // eslint-disable-next-line no-console
      return User.fetch().catch(console.error);
    }
    return Promise.resolve();
  },
  showError(message) {
    User.errorMessage = message;
  },
  getErrorMessage: () => User.errorMessage,
  fetch() {
    return m
      .request({
        url: `${API}/auth`,
        withCredentials: true,
      })
      .then(({ data: user }) => {
        User.current = user;
        if (!WebSockets.socket) {
          WebSockets.init();
        }
        return user;
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
        if (e.code === 401) {
          const message = 'User not logged id';
          throw new Error(message);
        }
        delete User.current;
        User.apiNotImplemented = true;
        throw new Error('User api not implemented');
      });
  },

  login({ username, password }) {
    return m
      .request({
        url: `${API}/auth/login`,
        method: 'POST',
        body: {
          username,
          password,
        },
        withCredentials: true,
      })
      .then(User.fetch)
      .catch(e => {
        if (e.code === 403) {
          const message = 'Username or password incorrect!';
          User.errorMessage = message;
          throw new Error(message);
        }
      });
  },

  register(user) {
    return m.request({
      url: `${API}/auth/register`,
      method: 'POST',
      body: user,
      withCredentials: true,
    });
  },

  forgotPassword() {
    return m.request({
      url: `${API}/auth/forgot`,
      method: 'POST',
    });
  },

  verifyCode(code) {
    return m
      .request({
        url: `${API}/auth/verify`,
        method: 'POST',
        body: code,
      })
      .then(User.fetch);
  },

  logout: () =>
    m
      .request({
        url: `${API}/auth/logout`,
        withCredentials: true,
      })
      .then(() => {
        User.current = null;
      })
      .then(() => {
        WebSockets.close();
        m.route.set('');
      }),
  // .finally(() => {
  // setTimeout(() => {
  // m.redraw();
  // }, 500);
  // }),
};

// #if process.env.IS_DEVELOPMENT == 'true'
if (window.models) {
  window.models.User = User;
}
// #endif

export default User;
