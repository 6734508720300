import m from 'mithril';
import { API } from 'constants.js';
import LoadingBar from './loadingBar';

const OrderDocuments = {
  current: null,
  list: [],

  allowedFileTypes: [
    'image/png',
    'image/x-png',
    'image/jpeg',
    'image/tiff',
    'application/pdf',
  ],

  getFilesFromEvent(event) {
    if (event instanceof window.DragEvent) {
      if (event.dataTransfer.files) {
        return event.dataTransfer.files;
      }
    }
    return event.target.files;
  },

  verifyTypesOfFiles(files) {
    for (let i = 0; i < files.length; i += 1) {
      if (!OrderDocuments.allowedFileTypes.includes(files[i].type)) {
        return false;
      }
    }
    return files;
  },

  fetchDocuments(orderId, personId) {
    LoadingBar.start();
    const personString = `?personId=${personId}`;
    return m
      .request(
        `${API}/order/${orderId}/documents${personId ? personString : ''}`,
        { withCredentials: true },
      )
      .then(documents => {
        OrderDocuments.list = documents;
      })
      .finally(LoadingBar.finish);
  },

  uploadEventHandler(event, orderId, personId, documentId) {
    return OrderDocuments.verifyTypesOfFiles(
      OrderDocuments.getFilesFromEvent(event),
    )
      ? OrderDocuments.upload(
          orderId,
          personId,
          documentId,
          OrderDocuments.getFilesFromEvent(event),
        )
      : false;
  },

  upload(orderId, personId, documentId, files) {
    const data = new window.FormData();
    for (let i = 0; i < files.length; i += 1) {
      data.append(`file_${i}`, files[i]);
    }
    if (personId) {
      data.append('personId', personId);
    }
    data.append('documentId', documentId);
    LoadingBar.start();
    m.request({
      method: 'POST',
      url: `${API}/order/${orderId}/documents`,
      data,
      withCredentials: true,
    })
      .then(() => OrderDocuments.fetchDocuments(orderId, personId))
      .finally(LoadingBar.finish);
  },
};

// #if process.env.IS_DEVELOPMENT == 'true'
if (window.models) {
  window.models.OrderDocuments = OrderDocuments;
}
// #endif

export default OrderDocuments;
