const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
a.searchCountry_item(
  ...state.attrs
  className=${state.isCurrent ? 'searchCountry_item--hover' : ''}
  data-countryid=state.item.id
  data-pricefrom=(state.item.mincost || 0)
)
  span.searchCountry_side.searchCountry_side--name.spriteFlags(
    className=${`flags_${state.item.iso}`}
  )= state.item.name
  span.searchCountry_side.searchCountry_side--price
    = ${(state.item.mincost || 0).toLocaleString()}
    span.rub  ₽

  `;
};