const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div
  if state.image.preview.insert
    img.homeSelectCountry_img(
      src=state.image.blur
      class=${state.image.preview.show ? 'homeSelectCountry_img--loaded' : ''}
      onload=state.image.setLoaded('preview')
    )
    if state.image.full.insert
      img.homeSelectCountry_img(
        src=${helpers.getSource(state.image.sources)}
        class=${state.image.full.show ? 'homeSelectCountry_img--loaded' : ''}
        onload=state.image.setLoaded('full')
      )

  `;
};