import Questions from 'models/questions';
import User from 'models/user';

const FormModel = {
  values: {
    title: '',
    body: '',
    email: '',
  },
  errors: {
    title: false,
    body: false,
    email: false,
  },
  formSubmitted: false,

  setValue: field => value => {
    FormModel.values[field] = value;
    FormModel.errors[field] = false;
  },

  hasErrors: () => {
    const {
      values: { title, body, email },
    } = FormModel;

    FormModel.errors.title = !title && 'Поле тема не должно быть пустым!';
    FormModel.errors.body = !body && 'Поле сообщения не должно быть пустым!';
    FormModel.errors.email =
      !User.current && !email && 'Поле email не должно быть пустым!';

    return (
      FormModel.errors.title || FormModel.errors.body || FormModel.errors.email
    );
  },

  onSubmit: e => {
    e.preventDefault();

    if (!FormModel.hasErrors()) {
      const {
        values: { title, body, email },
      } = FormModel;

      Questions.postQuestion(title, body, email).then(() => {
        FormModel.values.title = '';
        FormModel.values.body = '';
        FormModel.values.email = '';
        FormModel.formSubmitted = true;
      });
    }
  },
};

export default FormModel;
