const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div.callbackFormRequest(onclick=actions.close style=state.style)
  div(onclick=actions.preventClose)
    div.callbackFormRequest_wrap
      p.callbackFormRequest_title 
        | Менеджер свяжется с вами 
        br 
        | и поможет оформить визу
      form(onsubmit=actions.onSubmit autocomplete="false")
        .field(className=state.status.phone)
          label.inputStandart.inputStandart--inline(
            for="callbackPhone"
          )
            input.inputStandart_input.callbackFormRequest_input#callbackPhone(
              type="tel"
              placeholder="+7"
              onchange=actions.onPhoneChange
              onblur=actions.onPhoneBlur
              value=state.values.phone
              disabled=state.disabled
              required
            )
            div.inputStandart_line
        button.buttonStandart.buttonStandart--alternativeDisabled.callbackFormRequest_button(
          type="submit"
          disabled=state.submitDisabled
        ) 
          span.buttonStandart_name Перезвонить мне



  `;
};