/* eslint-disable */
import m from 'mithril';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export const SCROLL_STATUSES = {
  TOP: 0,
  SCROLL_START: 1,
  AFTER_PRICE: 2,
  AFTER_APPLICANTS: 3,
  AFTER_SUBMIT_BUTTON: 4,
};

const MENU_STATUSES = {
  CLOSED: 0,
  MAIN: 1,
  APPLICANTS: 2,
  CALLBACK: 3,
  CALLBACK_AFTER_MAIN: 4,
};

export const ScrollListener = {
  status: SCROLL_STATUSES.TOP,
  init() {
    window.addEventListener('scroll', ScrollListener.onScroll);
  },
  destroy() {
    window.removeEventListener('scroll', ScrollListener.onScroll);
  },
  getTopPosition(id) {
    const el = document.getElementById(id);

    if (el) {
      return el.getBoundingClientRect().y;
    }

    return Infinity;
  },
  updatePositions() {
    ScrollListener.positions = {
      price: ScrollListener.getTopPosition('price'),
      applicants: ScrollListener.getTopPosition('applicants'),
      submit: ScrollListener.getTopPosition('submitOrder'),
    };
  },
  onScroll() {
    const scroll = window.scrollY;
    const previousStatus = ScrollListener.status;
    ScrollListener.updatePositions();

    if (scroll === 0) {
      ScrollListener.status = SCROLL_STATUSES.TOP;
    } else if (ScrollListener.positions.price > 0) {
      ScrollListener.status = SCROLL_STATUSES.SCROLL_START;
    } else if (ScrollListener.positions.applicants > 0) {
      ScrollListener.status = SCROLL_STATUSES.AFTER_PRICE;
    } else if (ScrollListener.positions.submit > 12) {
      ScrollListener.status = SCROLL_STATUSES.AFTER_APPLICANTS;
    } else {
      ScrollListener.status = SCROLL_STATUSES.AFTER_SUBMIT_BUTTON;
    }

    if (previousStatus !== ScrollListener.status) {
      m.redraw();
    }
  },
};

export const MenuModel = {
  status: MENU_STATUSES.CLOSED,
  openMenu(menu) {
    [...document.querySelectorAll('.scrollable')].forEach(disableBodyScroll);

    switch (menu) {
      case 'main':
        MenuModel.status = MENU_STATUSES.MAIN;
        break;
      case 'applicants':
        MenuModel.status = MENU_STATUSES.APPLICANTS;
        break;
      case 'callback':
        if (MenuModel.status === MENU_STATUSES.MAIN) {
          MenuModel.status = MENU_STATUSES.CALLBACK_AFTER_MAIN;
        } else {
          MenuModel.status = MENU_STATUSES.CALLBACK;
        }
        break;
      default:
        break;
    }
  },
  closeMenu() {
    if (MenuModel.status !== MENU_STATUSES.CALLBACK_AFTER_MAIN) {
      clearAllBodyScrollLocks();
    }

    switch (MenuModel.status) {
      case MENU_STATUSES.MAIN:
        MenuModel.status = MENU_STATUSES.CLOSED;
        break;
      case MENU_STATUSES.APPLICANTS:
        MenuModel.status = MENU_STATUSES.CLOSED;
        break;
      case MENU_STATUSES.CALLBACK:
        MenuModel.status = MENU_STATUSES.CLOSED;
        break;
      case MENU_STATUSES.CALLBACK_AFTER_MAIN:
        MenuModel.status = MENU_STATUSES.MAIN;
        break;
      default:
        break;
    }
  },
  isMenuVisible(menu) {
    switch (menu) {
      case 'main':
        return MenuModel.status === MENU_STATUSES.MAIN;
      case 'applicants':
        return MenuModel.status === MENU_STATUSES.APPLICANTS;
      case 'callback':
        return MenuModel.status >= MENU_STATUSES.CALLBACK;
      case 'submit':
        return ScrollListener.status === SCROLL_STATUSES.AFTER_SUBMIT_BUTTON;
      default:
        return null;
    }
  },
};

export const getElementType = place => {
  switch (place) {
    case 'left':
      if (MenuModel.status > MENU_STATUSES.MAIN) return 'whiteArrow';
      if (MenuModel.status === MENU_STATUSES.MAIN) return 'arrow';
      return 'burger';
    case 'center':
      if (
        (ScrollListener.status >= SCROLL_STATUSES.AFTER_PRICE &&
          MenuModel.status !== MENU_STATUSES.CALLBACK) ||
        MenuModel.status === MENU_STATUSES.APPLICANTS
      )
        return 'price';
      return 'logo';
    case 'right':
      if (
        (ScrollListener.status >= SCROLL_STATUSES.AFTER_APPLICANTS &&
          MenuModel.status === MENU_STATUSES.CLOSED) ||
        MenuModel.status === MENU_STATUSES.APPLICANTS
      ) {
        return 'applicants';
      }
      return 'phone';
    default:
      return null;
  }
};
