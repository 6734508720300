import loadingBar from '../loadingBar';

export const STATE_INIT = 'INIT';
const STATE_INSERT = 'INSERT';
const STATE_FADE_IN = 'FADE_IN';
const STATE_VISIBLE = 'VISIBLE';
const STATE_FADE_OUT = 'FADE_OUT';
const STATE_REMOVE = 'REMOVE';

export const transitions = {
  [STATE_INIT]: [
    {
      to: STATE_INSERT,
      check: ({ previous }) => [previous === undefined],
    },
    {
      to: STATE_INSERT,
      check: ({ previous }) => [previous && previous.state === STATE_VISIBLE],
    },
  ],
  [STATE_INSERT]: [
    {
      to: STATE_REMOVE,
      check: ({ next }) => [next !== undefined],
    },
    {
      to: STATE_FADE_IN,
      check: ({ current }) => [current.preview.loaded],
    },
  ],
  [STATE_FADE_IN]: [
    {
      to: STATE_FADE_OUT,
      check: ({ next }) => [next !== undefined],
    },
    {
      to: STATE_VISIBLE,
      check: ({ current }) => [
        current.full.loaded,
        current.preview.transitionCompleted,
      ],
    },
  ],
  [STATE_VISIBLE]: [
    {
      to: STATE_FADE_OUT,
      check: ({ next }) => [next && next.state === STATE_INSERT],
    },
  ],
  [STATE_FADE_OUT]: [
    {
      to: STATE_REMOVE,
      check: ({ next }) => [next.state === STATE_VISIBLE],
    },
  ],
};

export const actions = {
  [STATE_INSERT]: image => {
    loadingBar.start();
    image.preview.insert = true;
  },
  [STATE_FADE_IN]: image => {
    image.preview.show = true;
    image.full.insert = true;
  },
  [STATE_VISIBLE]: image => {
    loadingBar.finish();
    image.full.show = true;
  },
  [STATE_FADE_OUT]: image => {
    image.full.show = false;
  },
  [STATE_REMOVE]: (image, imageSet) => {
    imageSet.delete(image);
  },
};
