const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
section.askQuestion
  Link.back(
    href="/faq"
  ) <- назад
  div.title Вопрос №
    = state.number
    |  Тема: 
    = state.title
  div.question_header
    div.body
      = state.body
    div.number
      = state.number
    div.date
      = state.date
    div.status(className=state.statusClassName)
      = state.statusText
    div
      = state.messageCount
  = children.answers
  = children.form

  `;
};