import m from 'mithril';
import { openModal } from 'components/layout/modal';
import Orders from 'models/orders';

import additional from './additional';
import checkbox from './checkbox';
import radio from './radio';

const inputs = {
  checkbox,
  radio,
};

export default {
  view({ attrs }) {
    const {
      path,
      item: { type, id, list, information_links: parentLinks },
      getSettings,
      updateValue,
      dataIsLoaded,
    } = attrs;

    if (!inputs[type]) {
      return null;
    }

    return m.fragment(
      {},
      list.map(
        ({
          additional: additionalInputs,
          desc,
          information_links: links,
          ...item
        }) => {
          const values = Orders.current.values.services[id].list[item.id];
          if (!values.show) {
            return null;
          }

          return m(
            inputs[type],
            {
              ...item,
              parentId: id,
              path: `${path}.${item.id}`,
              dataIsLoaded,
              updateValue,
              getSettings,
            },
            m('.inputSublabel.inputSublabel--big', desc),
            m(additional, {
              items: additionalInputs,
              path: `${path}.${item.id}.additional`,
              getSettings,
              updateValue,
            }),
            (links.length ? links : parentLinks).map(link =>
              m(
                'a.link',
                {
                  target: '_blank',
                  href: `/${link.url}`,
                  onclick: e => {
                    e.stopPropagation();
                    if (!link.extenral) {
                      e.preventDefault();
                      openModal(link.url);
                    }
                  },
                },
                m('span.line', link.name),
              ),
            ),
          );
        },
      ),
    );
  },
};
