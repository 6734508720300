/* eslint-disable */
import resolveTemplate from 'utils/resolveTemplate';

export default {
  oninit() {
    document.title = 'Контакты';
    this.template = resolveTemplate('pages/contacts');
  },
  oncreate() {
    const scriptYandexMap = document.createElement('script');
    scriptYandexMap.src = 'https://api-maps.yandex.ru/2.1.73/?lang=ru-RU';
    document.body.appendChild(scriptYandexMap);

    scriptYandexMap.onload = function() {
      window.ymaps.ready(function() {
        const myMap = new window.ymaps.Map('map', {
          center: [57.152567923229434, 65.53175596560662],
          // #if process.env.IS_MOBILE == 'true'
          zoom: 15,
          // #endif
          // #if process.env.IS_MOBILE == 'false'
// //           zoom: 16,
          // #endif
          controls: [],
        });
        myMap.options.set('balloonAutoPanMargin', 84);
        myMap.options.set('balloonAutoPanDuration', 300);
        const fullscreenControl = new window.ymaps.control.FullscreenControl();
        myMap.controls.add(fullscreenControl, {
          float: 'none',
          position: {
            right: 32,
            top: 0,
          },
        });
        myMap.controls.add('geolocationControl', {
          float: 'none',
          position: {
            right: 32,
            top: 56,
          },
        });
        myMap.controls.add('zoomControl', {
          float: 'none',
          position: {
            right: 32,
            top: 112,
          },
        });

        // #if process.env.IS_MOBILE == 'true'
        myMap.behaviors.disable(['scrollZoom', 'drag', 'multiTouch']);
        // #endif

        // #if process.env.IS_MOBILE == 'false'
// //         myMap.behaviors.disable(['scrollZoom']);
        // #endif

        let myGeoObjects = [];
        const my_img_map = {
          iconLayout: 'default#image',
          iconImageSize: [54, 66],
          iconImageOffset: [-27, -66],
          iconImageHref: '/img/map/mark-vis.png',
        };

        if (collectionPlacemark != false) {
          myMap.geoObjects.remove(collectionPlacemark);
        }

        const collectionPlacemark = new window.ymaps.GeoObjectCollection();

        const newBaloon = new window.ymaps.Placemark(
          [57.152567923229434, 65.53175596560662],
          {
            name: 'Visard',
            balloonContent:
              '<nobr><b>Visard — сервис оформления визы</b></nobr><br><nobr>«Сити-Центр», Герцена, 64</nobr>',
          },
          my_img_map,
        );
        collectionPlacemark.add(newBaloon);
        myMap.geoObjects.add(collectionPlacemark);
        // myMap.setCenter(centerMap, zoomMap);

        fullscreenControl.events.add('fullscreenenter', function() {
          if ('ontouchstart' in window) {
            myMap.behaviors.enable(['scrollZoom', 'drag', 'multiTouch']);
            myMap.container.fitToViewport();
          } else {
            myMap.behaviors.enable(['scrollZoom']);
          }
        });

        fullscreenControl.events.add('fullscreenexit', function() {
          if ('ontouchstart' in window) {
            myMap.behaviors.disable(['scrollZoom', 'drag', 'multiTouch']);
            myMap.container.fitToViewport();
          } else {
            myMap.behaviors.disable(['scrollZoom']);
          }
        });

        // mapReady = true;
      });
    };
  },
  view() {
    return this.template({});
  },
};
