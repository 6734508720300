import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import Orders from 'models/orders';
import Countries from 'models/countries';
import result from 'components/widgets/order-result';
import autocomplete from 'components/pages/home/order/autocomplete';
import heading from 'components/pages/home/order/heading';

export default {
  oninit() {
    this.template = resolveTemplate('pages/home/order');
    this.isOrderFetched = false;
  },
  onupdate() {
    if (Orders.current.values) {
      this.isOrderFetched = true;
    }
  },
  view() {
    return this.template({
      state: {
        country: Countries.current,
        isCountrySelected: Orders.current.values,
      },
      children: {
        heading: m(heading),
        autocomplete: m(autocomplete),
        ...(this.isOrderFetched
          ? {
              panel: m('.orderCalculator_wrap', m(result)),
            }
          : {}),
      },
    });
  },
};
