import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import { STORE } from 'constants.js';
import semantika from './semantika.svg';

const Footer = {
  oninit() {
    this.template = resolveTemplate('layout/footer');
  },
  view() {
    return this.template({
      state: {
        authors: [
          {
            text: 'Semantika',
            url: 'http://semantika.studio/',
            icon: m.trust(semantika),
            subtitle: 'Дизайн',
          },
          {
            text: 'Gridpix',
            url: 'http://gridpix.ru/',
            subtitle: 'Разработка',
          },
        ],
        links: STORE.footerLinks,
      },
    });
  },
};

export default Footer;
