const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
Link.questionList_item(
  href=state.href
)
  h2
    = state.title
  div.questionItemQuest
    = state.body
  div.number
    = state.number
  div.date
    = state.date
  div.status(className=state.statusClassName)
    = state.statusText
  br
  if state.unreaded
    div вам ответили
  else
    div
      = state.messageCount 
  br br br

  `;
};