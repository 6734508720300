import resolveTemplate from 'utils/resolveTemplate';

export default {
  oninit() {
    this.template = resolveTemplate('pages/home/order/heading');
  },
  onbeforeremove({ dom }) {
    dom.classList.add('homeSelectCountry_title_wrapper--hide');

    return new Promise(resolve => {
      setTimeout(resolve, 1000);
    });
  },
  view() {
    return this.template({});
  },
};
