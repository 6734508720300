const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
.modalWindow.scrollable.modalWindow--show(className=state.className)
  .modalWindow_type.modalWindow_type--standart.modalWindow_type--centering
    .modalWindow_centeringRow
      .modalWindow_centeringCell(
        onclick=actions.close
      )
        .modalWindow_contentList(
          onclick=actions.stopPropagation
        )
          button.modalWindow_close.buttonClose.buttonClose--small(
            title='Закрыть'
            onclick=actions.close
          ) Закрыть
          = children.content

  `;
};