import m from 'mithril';
import popoverDecorator from './popover';

const dropdownDecorator = (anchor, content, hideClass, hideTransition) => {
  const { view, ...compiled } = popoverDecorator(
    anchor,
    content,
    hideClass,
    hideTransition,
  );

  let listenerExists = false;

  const createListener = state =>
    function newListener() {
      state.opened = false;
      state.ondelete();
      document.body.removeEventListener('click', newListener);
      listenerExists = false;
      m.redraw();
    };

  return {
    ...compiled,
    view(vnode) {
      return view({
        ...vnode,
        attrs: {
          ...vnode.attrs,
          onclick() {
            if (vnode.state.opened && !listenerExists) {
              listenerExists = true;
              const listener = createListener(vnode.state);

              setTimeout(() => {
                document.body.addEventListener('click', listener);
              });
            }
          },
        },
      });
    },
  };
};

export default dropdownDecorator;
