const Modal = {
  items: {},

  add(item, section) {
    item.id = new Date().getTime();
    if (!Modal.items[section]) {
      Modal.items[section] = new Set();
    }

    Modal.items[section].add(item);

    return () => {
      Modal.items[section].delete(item);
    };
  },
};

// #if process.env.IS_DEVELOPMENT == 'true'
if (window.models) {
  window.models.Modal = Modal;
}
// #endif

export default Modal;
