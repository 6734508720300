import m from 'mithril';
import { STORE } from 'constants.js';
import Orders from 'models/orders';

const getTitle = () => {
  const uri = m.route.get();
  const id = +m.route.param('id');
  let title = '';

  if (STORE.titles[uri]) {
    title = STORE.titles[uri];
  } else if (/orders/.test(uri)) {
    const order = Orders.list.find(item => item.id === id);
    title = order ? order.title : 'Название страницы';
  }

  return title;
};

export default {
  view(vnode) {
    this.title = getTitle();
    document.title = `${this.title} — Visard`;

    return m(
      'section',
      {
        style: {
          paddingTop: '80px',
        },
      },
      [m('h1', vnode.state.title), m('h2', 'Страница на стадии заполнения')],
    );
  },
};
