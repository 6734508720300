const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div
  section.homeSelectCountry
    figure.homeSelectCountry_figure
      = children.background

      figcaption.homeSelectCountry_caption
        if state.isCountrySelected
          Link.homeSelectCountry_captionLink(
            href='/strany/' + state.country.url
          )
            span.line.white Узнать больше о визе в эту страну
        else
          a.homeSelectCountry_captionLink.spriteFlags(
            class='flags_' + state.country.iso
            href='javascript:void(0);'
            onclick=${actions.countryClick}
          )
            span.line.white.dashed= state.country.name
            = state.mincost
    = children.order
  if !state.needHideCountires
    // section
    div.homeGridCountry.gridCountry
      div.gridCountry_list
        = children.countries
      .homeGridCountry_header
        // Link.homeGridCountry_headerLink(
        //   href='/strany'
        // )
        //   span.line
        //     | Все страны
        //     = ' '
        //     span.homeGridCountry_headerArrow →
        Link.buttonAll(
          href='/strany'
        ) Все страны
    section.simpleListCountry
      div.simpleListCountry_header
        h2.simpleListCountry_headerTitle Минимальная стоимость оформления визы
        div.toogleSelect(
          role='radiogroup'
        )
          .toogleSelect_list
            label.toogleSelect_item
              input.toogleSelect_input(
                type='radio'
                name='simple_list_country_switch'
                onclick=actions.regionClick('')
                checked=state.allChecked
              )
              span.toogleSelect_button Все
            label.toogleSelect_item
              input.toogleSelect_input(
                type='radio'
                name='simple_list_country_switch'
                onclick=actions.regionClick('schengen')
                checked=state.schengenChecked
              )
              span.toogleSelect_button Шенген
            label.toogleSelect_item
              input.toogleSelect_input(
                type='radio'
                name='simple_list_country_switch'
                onclick=actions.regionClick('asia')
                checked=state.asiaChecked
              )
              span.toogleSelect_button Азия
      ul.simpleListCountry_list
        each country in state.countries
          li.simpleListCountry_item(key=country.key)
            Link.simpleListCountry_itemLink(
              href=country.link
            )
              span.simpleListCountry_itemName= country.title
              span.simpleListCountry_itemCost= country.price
    section.howWeWork
      .bigList
        h2.bigList_title Как мы работаем
        ul.bigList_list
          li.bigList_item
            div.bigList_icon
              svg.bigList_iconSvg(
                viewBox='0 0 26 26'
                width='26px'
                height='26px'
              )
                path(
                  d='M6,0A2,2,0,0,0,4,2H21a3,3,0,0,1,3,3V18a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2ZM2,4A2,2,0,0,0,0,6V20a2,2,0,0,0,2,2H5v3a1,1,0,0,0,1.84.54L9.5,22H20a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2Z'
                )
            h3.bigList_itemTitle 1. Заявка
            p.pStandart Оставьте заявку на сайте. просто кликните по одной из зеленых кнопок, в появившемся поле вводите имя, номер телефона и ваш e-mail. заявка принята!
          li.bigList_item
            div.bigList_icon
              svg.bigList_iconSvg(
                viewBox='0 0 23 23'
                width='23px'
                height='23px'
              )
                path(
                  d='M8.11,17.23a1.28,1.28,0,0,0,0-1.31L6.88,13.87A1.28,1.28,0,0,1,7,12.42,34.75,34.75,0,0,1,9.51,9.51,34.75,34.75,0,0,1,12.42,7a1.28,1.28,0,0,1,1.45-.09L15.92,8.1a1.28,1.28,0,0,0,1.31,0l5.13-3A1.27,1.27,0,0,0,23,3.68a4.19,4.19,0,0,0-1.19-2C19.45-.62,15.55-1.85,6.85,6.85S-.62,19.46,1.69,21.77a4.24,4.24,0,0,0,2,1.19,1.27,1.27,0,0,0,1.42-.6C5.83,21.13,7.39,18.46,8.11,17.23Z'
                )
            h3.bigList_itemTitle 2. Звонок
            p.pStandart Вам поступает от нас звонок и мы выясним какая виза вам необходима, на какой период и в какую страну.
          li.bigList_item
            div.bigList_icon
              svg.bigList_iconSvg(
                viewBox='0 0 24 24'
                width='24px'
                height='24px'
              )
                path(
                  d='M24,10.75c0,4.53-2.21,7.34-5.79,7.34-1.86,0-3.19-.8-3.58-2.17h-.27A3.34,3.34,0,0,1,11,18.11c-3,0-5-2.42-5-6.08,0-3.5,1.91-5.86,4.77-5.86a3.51,3.51,0,0,1,3.36,2h.27V6.51h3.32v7.32c0,1,.46,1.63,1.24,1.63,1.26,0,2.07-1.69,2.07-4.37,0-5.21-3.42-8.55-8.73-8.55C6.75,2.54,3,6.43,3,12.09s3.81,9.32,9.92,9.32a13.46,13.46,0,0,0,3.44-.4v2.56a15.62,15.62,0,0,1-3.76.43C5,24,0,19.2,0,12S5,0,12.34,0C19.29,0,24,4.35,24,10.75ZM9.79,12.13c0,1.87.78,3,2.09,3S14,14,14,12.13s-.82-3-2.13-3S9.79,10.26,9.79,12.13Z'
                )
            h3.bigList_itemTitle 3. Письмо
            p.pStandart Вы получите на свой электронный ящик пакет документов который необходимо подготовить для получения визы.
          li.bigList_item
            div.bigList_icon
              svg.bigList_iconSvg(
                viewBox='0 0 26 26'
                width='26px'
                height='26px'
              )
                path(
                  d='M13,0A13,13,0,0,0,1,7.94a1,1,0,0,0,.11,1,1,1,0,0,0,.93.4,1,1,0,0,0,.8-.62,11,11,0,0,1,17.2-4.16A1,1,0,0,0,21.35,3,13,13,0,0,0,13,0Zm0,4a9,9,0,0,0-3,.51,1,1,0,0,0-.32,1.71,1,1,0,0,0,1,.18A6.91,6.91,0,0,1,13,6a7,7,0,0,1,7,7c0,2.95-1,9.67-1,9.67a1,1,0,1,0,2,.3,92.42,92.42,0,0,0,1-10A9,9,0,0,0,13,4ZM23.5,6.22a1,1,0,0,0-.85,1.5,11,11,0,0,1,.89,8.43,1,1,0,1,0,1.91.57A12.73,12.73,0,0,0,26,13a12.89,12.89,0,0,0-1.6-6.24A1,1,0,0,0,23.5,6.22ZM6.65,7.07a1,1,0,0,0-.75.39A9,9,0,0,0,4,13a34.54,34.54,0,0,1-.78,7.76,1,1,0,1,0,1.94.49A37,37,0,0,0,6,13,6.91,6.91,0,0,1,7.48,8.7a1,1,0,0,0-.83-1.63ZM13,8a5,5,0,0,0-5,5A46.29,46.29,0,0,1,7,23.69a1,1,0,1,0,1.94.5A49.27,49.27,0,0,0,10,13a3,3,0,0,1,6,0c0,1.2-.05,2.45-.13,3.66a1,1,0,0,0,1.45,1,1,1,0,0,0,.55-.84C18,15.54,18,14.25,18,13A5,5,0,0,0,13,8ZM1,11.18a1,1,0,0,0-.95.94c0,.29,0,.59,0,.88a13.23,13.23,0,0,0,.39,3.17,1,1,0,0,0,1.68.49,1,1,0,0,0,.26-1A11.24,11.24,0,0,1,2,13c0-.25,0-.5,0-.74a1,1,0,0,0-.28-.78,1,1,0,0,0-.77-.3ZM13,12a1,1,0,0,0-1,1,63,63,0,0,1-.34,6.73,1,1,0,0,0,.4.93,1,1,0,0,0,1,.11,1,1,0,0,0,.59-.82A64.59,64.59,0,0,0,14,13a1,1,0,0,0-.29-.72A1,1,0,0,0,13,12Zm3.48,8.24a1,1,0,0,0-1,.9c-.23,2-.45,3.36-.45,3.36a1,1,0,0,0,.36.94,1,1,0,0,0,1,.15,1,1,0,0,0,.63-.77s.22-1.4.46-3.45a1,1,0,0,0-.24-.79A1,1,0,0,0,16.46,20.23ZM12,22.88a1,1,0,0,0-1,.8c-.15.7-.25,1-.25,1a1,1,0,0,0,.22,1,1,1,0,0,0,1,.3,1,1,0,0,0,.73-.68s.13-.42.3-1.17a1,1,0,0,0-1-1.23Z'
                )
            h3.bigList_itemTitle 4. Биометрические данные
            p.pStandart Мы проверим пакет документов на соответствие, заполним бланки, запишем вас на подачу, проконсультируем о процедуре сдачи документов и биометрических данных.
          li.bigList_item
            div.bigList_icon
              svg.bigList_iconSvg(
                viewBox='0 0 20 26'
                width='20px'
                height='26px'
              )
                path(
                  d='M19.71,6.79,13.21.29A1,1,0,0,0,12.5,0H2A2,2,0,0,0,0,2V24a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V7.5A1.05,1.05,0,0,0,19.71,6.79ZM13,19H5a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Zm2-4H5a1,1,0,0,1,0-2H15a1,1,0,0,1,0,2ZM13,8a1,1,0,0,1-1-1V1.9L18.1,8Z'
                )
            h3.bigList_itemTitle 5. Документы
            p.pStandart Если подача документов в вашем случае не предполагает сдачу биометрических данных, то мы направляем ваш пакет документов нашему доверенному лицу при консульстве страны в которую подаем документы. сдаем документыв консульство.
          li.bigList_item
            div.bigList_icon
              svg.bigList_iconSvg(
                viewBox='0 0 20 26'
                width='20px'
                height='26px'
              )
                path(
                  d='M2,0A2,2,0,0,0,0,2V24a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2Zm8,3a7,7,0,1,1-7,7A7,7,0,0,1,10,3ZM8.64,5S5,6.36,5,10c0,4.54,5,5,5,5L8.62,13.65l0-1.83L7.27,10.46H5.91V9.55L8.18,8.18V7.27h.91l.45.46.91-.46V5H9.54V6.36h-.9Zm4.45,1.09-.82.27v.46h-.45l-.46.45v.91l.91-.45,1.37.45-.46.91-.91-.45h-.91l-.9.91v.91l.9.9h.91l.46.91L11.36,15S15,13.64,15,10A5.79,5.79,0,0,0,13.09,6.09ZM6,20h8a1,1,0,0,1,0,2H6a1,1,0,0,1,0-2Z'
                )
            h3.bigList_itemTitle 6. Виза в паспорт
            p.pStandart Наше доверенное лицо получает ваш паспорт с визой в консульстве и направляет его нам.
          li.bigList_item
            div.bigList_itemTitle2
              svg.bigList_iconSvg(
                viewBox='0 0 21 15'
                width='21px'
                height='15px'
              )
                path(
                  d='M20.14,0a.88.88,0,0,0-.58.25L6.73,13,1.44,7.75a.82.82,0,0,0-.81-.23.82.82,0,0,0-.6.59.83.83,0,0,0,.23.82l5.87,5.83a.86.86,0,0,0,1.19,0L20.75,1.43a.83.83,0,0,0,.18-.92A.83.83,0,0,0,20.14,0Z'
                )
              span Готово! Счастливого пути!
            p.bigList_itemTitle Мы встречаемся и передаем вам паспорт с визой!
    .bigList.sixReasons
      h2.bigList_title.bigList_title--forNoIcon 6 причин заказать у нас визу
      ul.bigList_list
        li.bigList_item
          h3.bigList_itemTitle 1. Бесплатная консультация
          p.pStandart Наши специалисты ответят на все ваши вопросы по телефону или письменно.
        li.bigList_item
          h3.bigList_itemTitle 2. Прозрачная цена
          p.pStandart Вы можете получить детализацию стоимости вашей визовой поддержки.
        li.bigList_item
          h3.bigList_itemTitle 3. Для очень занятых
          p.pStandart Мы сами приедем за пакетом ваших документов а потом привезем вам паспорт с визой.
        li.bigList_item
          h3.bigList_itemTitle 4. Виза очень срочно
          p.pStandart В ряд стран мы ставим визу быстро, за 1 день.
        li.bigList_item
          h3.bigList_itemTitle 5. Дружным компаниям скидка
          p.pStandart Мы делаем дисконт, дружным компаниям и семьям.
        li.bigList_item
          h3.bigList_itemTitle 6. Наш опыт
          p.pStandart За три года работы мы выдали уже более 1000 виз.
    section.reviewsList
      h2.titleMiddle.colorGray.titleMiddle--inline Отзывы клиентов
      Link.buttonAll(
        href='/otzyvy'
      ) Все
      div.reviewsList_list
        article.reviewsList_item
          figure.reviewsList_figure
            img.reviewsList_img(
              src='/img/reviews/khusainov_ildar-220x332@2x.jpg'
              alt=''
            )
          div.reviewsList_txt
            div.reviewsList_txtInner
              h3.reviewsList_txtTitle Агентство недвижимости «Этажи»
              p.pStandart Я Хусаинов Ильдар, учредитель федеральной компании Этажи, обширная география работы заставляет много путешествовать по России, а с образовательными или бизнес целями я регулярно вылетаю в другие страны. Визовые вопросы уже много лет доверяю визовому центру Визард. Они легко делают визу в любой уголок мира.
            a.reviewsList_instLink(
              href='//instagram.com/khusainov_ildar'
              target='_blank'
            )
              svg.reviewsList_instLinkSvg(
                xmlns='http://www.w3.org/2000/svg' 
                viewBox='0 0 17 17'
                width='17px'
                height='17px'
              )
                path(
                  d='M5,0A5,5,0,0,0,0,5v7a5,5,0,0,0,5,5h7a5,5,0,0,0,5-5V5a5,5,0,0,0-5-5Zm7.73,3.4a.85.85,0,1,1-.85.85A.85.85,0,0,1,12.75,3.4ZM8.5,4.25A4.25,4.25,0,1,1,4.25,8.5,4.25,4.25,0,0,1,8.5,4.25Zm0,.85a3.4,3.4,0,1,0,3.4,3.4A3.41,3.41,0,0,0,8.5,5.1Z'
                  fill='#8144e0'
                )
              span khusainov_ildar
        article.reviewsList_item
          figure.reviewsList_figure
            img.reviewsList_img(
              src='/img/reviews/vicky_b2b-220x332@2x.jpg'
              alt=''
            )
          div.reviewsList_txt
            div.reviewsList_txtInner
              h3.reviewsList_txtTitle Ресторанный холдинг «МаксиМ»
              p.pStandart Я Барсукова Виктория, руководитель ресторанного холдинга, очень часто путешествую со своими детьми, хочется показать им весь мир. Визовой поддержкой в наших поездках занимается визовый центр Визард, они делают визы под любые задачи и маршруты, с ними всегда очень легко и понятно.
            a.reviewsList_instLink(
              href='//instagram.com/vicky_b2b'
              target='_blank'
            )
              svg.reviewsList_instLinkSvg(
                xmlns='http://www.w3.org/2000/svg' 
                viewBox='0 0 17 17'
                width='17px'
                height='17px'
              )
                path(
                  d='M5,0A5,5,0,0,0,0,5v7a5,5,0,0,0,5,5h7a5,5,0,0,0,5-5V5a5,5,0,0,0-5-5Zm7.73,3.4a.85.85,0,1,1-.85.85A.85.85,0,0,1,12.75,3.4ZM8.5,4.25A4.25,4.25,0,1,1,4.25,8.5,4.25,4.25,0,0,1,8.5,4.25Zm0,.85a3.4,3.4,0,1,0,3.4,3.4A3.41,3.41,0,0,0,8.5,5.1Z'
                  fill='#8144e0'
                )
              span vicky_b2b
    section.articlesList
      h2.titleMiddle.colorGray.titleMiddle--inline Статьи на тему «Виза для россиян»
      Link.buttonAll(
        href='/viza-dlja-rossijan'
      ) Все
      div.articlesList_list
        for article in state.articles
          article.articlesList_item(
            className=article.preview_img ? null : 'articlesList_item--txt'
            key=article.id
            data-type=article.type_show
          )
            Link.articlesList_link(
              href='/stati/'+article.url
            )
              if article.preview_img
                figure.articlesList_figure
                  img.articlesList_img(
                    src=article.preview_img
                    alt=article.title
                  )
              div.articlesList_txt
                h3.articlesList_title= m.trust(article.title)
                time.articlesList_time= article.date
    section.newsList
      h2.titleMiddle.colorGray.titleMiddle--inline Новости Визарда
      Link.buttonAll(
        href='/novosti'
      ) Все
      div.newsList_list
        for news in state.news
          article.newsList_item(
            key=news.id
          )
            Link.newsList_link(
              href='/novosti/'+news.url
            )
              time.newsList_time= news.date
              h3.newsList_title= news.title

  `;
};