import C from 'models/countries';
import N from 'models/notifications';
import O from 'models/orders';
import OD from 'models/orderDocuments';
import P from 'models/persons';
import U from 'models/user';
import Q from 'models/questions';
import QQ from 'models/questionnaries';
import WS from 'models/webSocket';

const withTrigger = wrapped => trigger => (...args) =>
  trigger(wrapped(...args));

U.fetch = withTrigger(U.fetch)(promise =>
  promise.then(() =>
    Promise.all([
      C.fetchAll(),
      N.fetchNotifications(),
      O.fetchAll(),
      Q.fetchAll(),
      WS.init(),
    ]),
  ),
);

U.logout = withTrigger(U.logout)(() => {
  N.clear();
  O.clear();
  Q.clear();
  WS.close();
  WS.clear();
});

O.fetch = withTrigger(O.fetch)(promise =>
  promise.then(order =>
    Promise.all([
      C.fetch(order.country),
      P.setList(order.persons),
      QQ.fetchValues(O.current.id, P.current),
      O.fetchInstitutions(P.current),
      OD.fetchDocuments(order.id),
    ]),
  ),
);

P.setCurrent = withTrigger(P.setCurrent)(() => {
  O.fetchInstitutions(P.current);
  OD.fetchDocuments(O.current.id, P.current);
  return QQ.fetchValues(O.current.id, P.current);
});

P.createPerson = withTrigger(P.createPerson)(promise =>
  promise.then(() => {
    O.fetch(O.current.id);
  }),
);

P.deletePerson = withTrigger(P.deletePerson)(promise =>
  promise.then(() => {
    O.fetch(O.current.id);
  }),
);

QQ.updateValue = withTrigger(QQ.updateValue)(promise =>
  promise.then(() => {
    OD.fetchDocuments(O.current.id, P.current);
    O.fetchInstitutions(P.current);
  }),
);

export const Orders = O;
export const Persons = P;
export const User = U;
export const Questions = Q;
export const WebSockets = WS;
