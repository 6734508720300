const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
.scrollable.headerPage(class=state.className)
  button.burgerMenu(
    onclick=actions.burgerAction
    className=state.burgerClass
  )
    span.burgerMenu_inner Меню
  .headerPage_backend
    Link.logo(
      href='/'
      className=state.logoClass
    )
      = children.logo
    span.header_fullCost.header_fullCost_spriteFlags.spriteFlags(className=state.priceClass)
      span= state.price
      |  ₽
    button.header_persons(
      className=state.applicantsClass
      onclick=actions.applicantsAction
    )
      = children.personIcon
      span= state.applicantsCount
  button.callbackButton.callbackButton--mobile(
    onclick=actions.phoneAction
    className=state.callbackClass
  )
    .callbackButton_inner
      = children.phone
      span.callbackButton_title Перезвонить мне


  `;
};