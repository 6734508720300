import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import IScroll from 'iscroll/build/iscroll-probe.js';
import Questions from 'models/questions';
import Item from './item';
import intersectionObserver from './intersectionObserver';

export default {
  oninit() {
    this.template = resolveTemplate('pages/question/answer-list');
    this.messageCount = Questions.current.messageCount;
  },
  oncreate(vnode) {
    this.scroll = new IScroll(vnode.dom, {
      mouseWheel: true,
      scrollbars: true,
      scrollX: false,
      scrollY: true,
    });

    intersectionObserver.init(vnode.dom);
  },
  onupdate() {
    if (this.messageCount !== Questions.current.messageCount) {
      this.scroll.refresh();
      this.scroll.scrollTo(0, this.scroll.maxScrollY, 0);
      this.messageCount = Questions.current.messageCount;
    }
  },
  onremove() {
    this.scroll.destroy();
    intersectionObserver.disconnect();
  },
  view() {
    return this.template({
      children: {
        answers: Questions.current.answers.map(answer =>
          m(Item, { key: answer.id, answer }),
        ),
      },
    });
  },
};
