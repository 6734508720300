const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
section.questions
  h2 Задать вопрос
  p Если у вас возникли проблемы, мы готовы ответить на все интересующие вас вопросы. Напишите тему обращения и опишите вашу проблему. Ответим в течение 24 часов.
  button(
    onclick = actions.toAddQuestionForm
  ) Задать вопрос
  div.my_questions
    div.title
      b Мои вопросы
    div.only_open
      label
        input(type='checkbox' checked=state.showOnlyOpen onclick=actions.toggleShowOnlyOpened)
        = 'Показывать только открытые'
    div.number № Обращения
    div.date Дата
    div.status Статус
    div.questionList_wrapper
      div.questionList_items
        = children.items

  `;
};