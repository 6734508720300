import m from 'mithril';
import Orders from 'models/orders';
import Tabs from 'components/widgets/tabs';
import InfoBlock from 'components/widgets/info-block';

export default {
  view() {
    const { institutions } = Orders.current;
    if (!institutions || !institutions.length) return m('h1', 'Нет учреждений');

    // Orders.current.institutions.push(Orders.current.institutions[0]);
    // Orders.current.institutions.push(Orders.current.institutions[0]);
    // Orders.current.institutions.push(Orders.current.institutions[0]);

    return m(Tabs, {
      tabs: Orders.current.institutions.map(item => ({
        key: item.id,
        title: item.name,
        content: InfoBlock,
        args: { settings: item.description },
      })),
      classes: {
        wrapper: '',
        tabWrapper: 'descriptionSimple',
        headerList: 'tabSwitch--type2 fadeSides fadeSides--x',
        headerItem: '',
        headerItemActive: 'tabSwitch_label--active',
        scrollHeader: 'gpScroll gpScroll--x',
      },
    });
  },
};
