import m from 'mithril';
import c from 'classnames';

export default {
  view({ attrs, children }) {
    const { path, id, name, dataIsLoaded, getSettings, updateValue } = attrs;
    const valuePath = `${path}.active`;
    const input = getSettings(path);
    const { disabled, active: checked } = input;

    return m(
      '.checkbox.checkbox--big',
      {
        className: c({
          'checkbox--disabled': !dataIsLoaded || disabled,
        }),
      },
      m(
        'label.checkbox_label',
        {
          for: `inp_${id}`,
          onclick: e => {
            e.preventDefault();
            updateValue(valuePath, !checked);
          },
        },
        [
          m('input.checkbox_input', {
            disabled,
            checked,
            id: `inp_${id}`,
            type: 'checkbox',
            value: id,
            onchange: ({ target: { checked: isActive } }) => {
              updateValue(valuePath, isActive);
            },
          }),
          m('.checkbox_icon', m('.checkbox_mark')),
          m('.checkbox_content', [name, ...children]),
        ],
      ),
    );
  },
};
