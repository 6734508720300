import m from 'mithril';

class Counter {
  constructor(minValue, maxValue, value, onChange) {
    this.value = value || minValue || 0;
    this.minValue = minValue || -Infinity;
    this.maxValue = maxValue || Infinity;
    this.onChange = onChange;
  }

  #change = value => {
    if (value > this.maxValue) {
      this.value = this.maxValue;
    } else if (!Number.isNaN(value) && value > this.minValue) {
      this.value = value;
    } else {
      this.value = this.minValue;
    }
    this.onChange(this.value);
  };

  increment = () => {
    this.#change(this.value + 1);
  };

  decrement = () => {
    this.#change(this.value - 1);
  };

  validate = () => {
    this.#change(parseInt(`${this.value}`, 10));
  };

  change = inputValue => {
    this.value = inputValue;
  };
}

Counter.prototype.toString = function toString() {
  return `${this.value}`;
};

export default {
  oninit({
    attrs: { min_value: min, max_value: max, path, updateValue, getSettings },
  }) {
    this.counter = new Counter(min, max, getSettings(path), value =>
      updateValue(path, value),
    );
    this.path = path;
  },

  onupdate({
    attrs: { min_value: min, max_value: max, path, updateValue, getSettings },
  }) {
    if (path !== this.path) {
      this.counter = new Counter(min, max, getSettings(path), value =>
        updateValue(path, value),
      );
      this.path = path;
    }
  },
  view({ state: { counter }, attrs: { label } }) {
    return m(
      '.inputSubcount.inputSubcount--big',
      {
        onclick: e => {
          e.stopPropagation();
        },
      },
      [
        m('span.inputSubcount_label', label),
        m('.inputNumber.inputSubcount_input', [
          m(
            'button.inputNumber_button.inputNumber_button--minus',
            {
              onclick: e => {
                e.stopPropagation();
                counter.decrement();
              },
            },
            'минус',
          ),
          m('input.inputNumber_count', {
            value: `${counter}`,
            oninput: ({ target: { value } }) => counter.change(value),
            onblur: counter.validate,
          }),
          m(
            'button.inputNumber_button.inputNumber_button--plus',
            {
              onclick: e => {
                e.stopPropagation();
                counter.increment();
              },
            },
            'плюс',
          ),
        ]),
      ],
    );
  },
};
