/* eslint-disable */
import m from 'mithril';

import { cache } from '../radio';

export default {
  view({ attrs: { items, path, getSettings, updateValue } }) {
    return m(
      '.toogleSelect',
      {
        role: 'radiogroup',
      },
      m(
        '.toogleSelect_list',
        items.map(({ id: itemId, label }) =>
          m(
            'label.toogleSelect_item',
            {
              for: `sw_${itemId}`,
              onclick: e => {
                e.stopPropagation();
              },
            },
            [
              m('input.toogleSelect_input', {
                name: path,
                type: 'radio',
                id: `sw_${itemId}`,
                checked: getSettings(path) === itemId,
                onchange: e => {
                  e.stopPropagation();

                  updateValue(path, itemId);

                  // const matches = path.match(/services.(\d+).list.(\d+)/);
                  // if (cache[matches[1]]) {
                  //   const parent = `services.${matches[1]}.list.${
                  //     matches[2]
                  //   }.active`;
                  //   Orders.updateValue(cache[+matches[1]], false);
                  //   cache[+matches[1]] = parent;
                  //   Orders.updateValue(cache[+matches[1]], true);
                  // }
                  // Orders.updateValue(path, id);
                  // e.stopPropagation();
                },
              }),
              m('span.toogleSelect_button', label),
            ],
          ),
        ),
      ),
    );
  },
};
