const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
button#applicants.orderCalculator_persons(...state.attrs)
  span.orderCalculator_personsSide
    = children.icon
    span Заявители
    .buttonAdd.buttonAdd--floatLeft.orderCalculator_personsSide.count
      span= state.peopleCount

  `;
};