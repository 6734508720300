// import c from 'classnames';
import m from 'mithril';

export default {
  oninit({ attrs }) {
    const { ids, updateValue, path } = attrs;

    this.update = id =>
      updateValue(
        path.concat('list'),
        ids.reduce(
          (res, item) => ({
            ...res,
            [item]: {
              active: item === id,
            },
          }),
          {},
        ),
      );
  },
  view({ attrs }) {
    const {
      list,
      settings: { list: values = {}, disabled },
      dataIsLoaded,
    } = attrs;

    // console.log(values);
    // console.log(attrs.path[0]);
    // console.log(list);
    // console.log(values);

    return m(
      'ul.toogleSelect_list',
      list.map(({ id, name }) =>
        m(
          'li.toogleSelect_item',
          m(
            'label',
            m('input.toogleSelect_input', {
              type: 'radio',
              name: `q${attrs.path[0]}`,
              checked: values[id] && values[id].active,
              onclick: () => this.update(id),
              disabled: !dataIsLoaded || disabled,
            }),
            m('span.toogleSelect_button', name),
          ),
          // m(
          //   'button',
          //   {
          //     class: c({
          //       active: values[id] && values[id].active,
          //     }),
          //     onclick: () => this.update(id),
          //     disabled: !dataIsLoaded || disabled,
          //   },
          //   name,
          //   values[id] && values[id].active ? '<' : null,
          // ),
        ),
      ),
    );
  },
};
