/* eslint-disable */

import m from 'mithril';
import Persons from 'models/persons';
import resolveTemplate from 'utils/resolveTemplate';
import Item from './item';

import common from './common.svg';

export default {
  oninit(vnode) {
    const { orderId } = vnode.attrs;
    this.orderId = orderId;
    this.editMode = false;
    this.createPerson = () => Persons.createPerson(orderId);
    this.setPersonToCommon = () => Persons.setCurrent(null);
    this.toggleEditMode = () => {
      this.editMode = !this.editMode;
    };
    this.template = resolveTemplate('pages/order/persons');
  },
  view({ attrs: { qModel } }) {
    return this.template({
      state: {
        commonSelectedClass:
          !Persons.current && !this.editMode ? 'personList_item--selected' : '',
        editMode: this.editMode,
        person_count: Persons.list.length,
      },
      children: {
        items: Persons.list.map(person =>
          m(Item, {
            key: person.id,
            editMode: this.editMode,
            orderId: this.orderId,
            person,
            qModel,
            person_count: Persons.list.length,
          }),
        ),
        common: m.trust(common),
      },
      actions: {
        commonOnclick: !this.editMode ? this.setPersonToCommon : null,
        createOnclick: this.createPerson,
        editOnclick: this.toggleEditMode,
      },
    });
  },
};
