import m from 'mithril';
import { API, STORE } from 'constants.js';
import LoadingBar from './loadingBar';

const Articles = {
  articles: [],
  news: [],
  home: { news: [], articles: [], loaded: false },
  full: {},

  fetchForHomePage: () => {
    if (Articles.home.loaded) {
      return Promise.resolve();
    }
    LoadingBar.start();
    Articles.home.loaded = true;

    return m
      .request(`${API}/articles`, {
        method: 'GET',
        params: {
          device_type: STORE.isMobile ? 'mobile' : 'desktop',
          webp: STORE.WebPSupport,
          retina: window.devicePixelRatio > 1,
          type: 'main',
        },
      })
      .then(({ data: { news, articles } }) => {
        Articles.home.news = news;
        Articles.home.articles = articles;
      })
      .finally(LoadingBar.finish);
  },

  fetchArticles: () => {
    if (Articles.articles.length) {
      return Promise.resolve();
    }
    LoadingBar.start();

    return m
      .request(`${API}/articles`, {
        method: 'GET',
        params: {
          device_type: STORE.isMobile ? 'mobile' : 'desktop',
          webp: STORE.WebPSupport,
          retina: window.devicePixelRatio > 1,
          type: 'article',
        },
      })
      .then(({ data: articles }) => {
        Articles.articles = articles;
      })
      .finally(LoadingBar.finish);
  },

  fetchNews: () => {
    if (Articles.news.length) {
      return Promise.resolve();
    }
    LoadingBar.start();

    return m
      .request(`${API}/articles`, {
        method: 'GET',
        params: {
          device_type: STORE.isMobile ? 'mobile' : 'desktop',
          webp: STORE.WebPSupport,
          retina: window.devicePixelRatio > 1,
          type: 'news',
        },
      })
      .then(({ data: news }) => {
        Articles.news = news;
      })
      .finally(LoadingBar.finish);
  },

  fetchArticle: ({ url }) =>
    m
      .request(`${API}/articles/${url}`)
      .then(({ data: article }) => {
        Articles.full[url] = article;
      })
      .finally(LoadingBar.finish),
};

// #if process.env.IS_DEVELOPMENT == 'true'
if (window.models) {
  window.models.Articles = Articles;
}
// #endif

export default Articles;
