import m from 'mithril';
import popoverDecorator from 'decorators/dropdown';
import { puntoSwitcher } from 'utils/puntoSwitcher';

export default (
  autocompleteContext,
  oninitFunc,
  inputTemplate,
  listTemplate,
  itemTemplate,
  getInputAttrs,
  hideClass,
  hideTransition = 200,
) => {
  const onInput = ({ target: { value } }) => {
    if (autocompleteContext.needRemove) {
      if (value < autocompleteContext.value) {
        autocompleteContext.setValue('');
      } else {
        autocompleteContext.setValue(puntoSwitcher(value[value.length - 1]));
      }
      autocompleteContext.needRemove = false;
    } else {
      autocompleteContext.setValue(puntoSwitcher(value));
    }
  };

  const input = {
    oninit() {
      oninitFunc(autocompleteContext);
    },
    view({ attrs }) {
      const inputAttrs = getInputAttrs(autocompleteContext);

      return inputTemplate({
        state: {
          ...inputAttrs,
          wrapper: {
            ...attrs,
            ...inputAttrs.wrapper,
          },
          input: {
            onkeydown: autocompleteContext.changeHoverItemIndex,
            oninput: onInput,
            onblur: autocompleteContext.setPreviousValue,
            value: autocompleteContext.value,
            ...(inputAttrs.input || {}),
          },
        },
        children: inputAttrs.children || {},
      });
    },
  };

  const itemComponent = {
    onupdate({ attrs: { index }, dom }) {
      if (autocompleteContext.needScroll) {
        const parent = dom.parentElement;
        parent.scrollBy(0, index * dom.offsetHeight - parent.scrollTop);
      }
    },
    view({ attrs: { id, isCurrent, item, ...attrs } }) {
      return itemTemplate({
        state: {
          attrs,
          isCurrent,
          item,
        },
      });
    },
  };

  const list = {
    view({ attrs: { getStyles = () => ({}), ...attrs }, state }) {
      return listTemplate({
        state: {
          attrs,
          length: autocompleteContext.display.length,
        },
        actions: {
          close: state.ondelete,
        },
        helpers: {
          getStyles,
        },
        children: {
          items: autocompleteContext.display.map((item, index) =>
            m(itemComponent, {
              key: item.id,
              item,
              index,
              isCurrent: index === autocompleteContext.hoverItemIndex,
              onmousemove: () =>
                autocompleteContext.setHoverItemIndex(index, false),
              onclick: () => {
                autocompleteContext.selectItem(item);
                state.ondelete();
              },
            }),
          ),
        },
      });
    },
  };

  return popoverDecorator(input, list, hideClass, hideTransition);
};
