const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
label.personList_item(
  className=state.selectedClass
  onclick=actions.itemOnclick
)
  span.personList_itemName
    = state.fullName 
  span.personList_itemProgress 
    // числа будут анимироваться если изменятся
    span.personList_itemCount=state.filled 
    = ' из ' 
    span.personList_itemCount=state.summary
    = ' полей'
  if state.editMode && state.person_count > 1
    button.personList_removeButton(
      onclick=actions.deleteOnclick
    ) 
      | Удалить заявителя
  `;
};