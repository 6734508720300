const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
.callbackFormRequest(className=state.className)
  .callbackFormRequest_item
    button.callbackFormRequest_button(onclick=actions.onClick) Заказать звонок
  .callbackFormRequest_item
    .callbackFormInput
      label.inputStandart.inputStandart--inline.callbackFormRequest_label(
        className=state.inputClass
      )
        input#callbackPhone.inputStandart_input.callbackFormRequest_input(
          type='tel'
          placeholder='+7 (495) 000-00-00'
          oninput=actions.onChange
          required
        )
        div.inputStandart_line
    a.callbackFormRequest_button.callHanterHeaderMobile(
      href='tel:+73452566566'
      className=state.buttonClass
    ) Набрать +7 (3452) 566‒566
  button.buttonStandart.callbackFormRequest_buttonSend(
    className=state.formClass
    onclick=actions.onSubmit
  )
    span.buttonStandart_name Перезвонить мне

  `;
};