const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div.articlePage
  div.articlePage_content
    div.articlePage_lead
      div.articlePage_leadTxt
        h1.titleBig 9 сентября мобильная биометрия, страна${'\u00A0'}Франция 🇫🇷 
        p.pStandart Успей записаться, количество мест ограничено!
    h2.pStandart.p--beforeListAccent Стоимость
    ul.listStandart.pStandart
      li.listStandart_item 
        | Взрослый — 13 500 ₽
      li.listStandart_item 
        | Ребёнок (до 6 лет) — 5 000 ₽
    h2.pStandart.p--beforeListAccent Что входит
    ul.listStandart.pStandart
      li.listStandart_item Сервисный и консульские сборы
      li.listStandart_item Биометрия
      li.listStandart_item Курьерская доставка
      li.listStandart_item Проверка документов

    //- p.pStandart
    //-   | Узнай свободное время подачи уже сейчас: г. Тюмень, Герцена 64, 7 этаж, офис 701, или по телефону 
    //-   a.simpleLink(
    //-     href='tel:+73452566566'
    //-   )
    //-     span.line +7 (3452) 566-566
    if state.formSubmitted
      div.accentTypeLarge
        div.accentTypeLarge_content
          span.titleBig Спасибо!
          br
          span.pStandart Ваша заявка отправлена
    else
      div.formSimple
        h2.titleMiddle(
          style='margin-bottom:8px'
        ) Записаться:
        form(onsubmit=actions.onSubmit)
          =children.nameInput
          =children.emailInput
          =children.phoneInput
          button.buttonStandart.buttonStandart--alternativeDisabled.createQuestion_formButtonSubmit(
            disabled=state.hasErrors()
          )
            span.buttonStandart_name Отправить заявку
  section.articlesList
    h2.titleMiddle.colorGray.titleMiddle--inline Другие статьи
    a.buttonAll(
      href='/viza-dlja-rossijan'
      data-internal
    ) Все
    div.articlesList_list
      article.articlesList_item
        a.articlesList_link(
          href='/stati/7-samyih-neobyichnyih-arhitekturnyih-sooruzheniy-evropyi'
          data-internal
        )
          figure.articlesList_figure
            img.articlesList_img(
              src='/img/articles/7-samyih-neobyichnyih-arhitekturnyih-sooruzheniy-evropyi/7-samyih-neobyichnyih-arhitekturnyih-sooruzheniy-evropyi-528x392.jpg'
              alt=''
            )
          div.articlesList_txt
            time.articlesList_time 2 апреля 2019
            h3.articlesList_title 7 самых необычных архитектурных сооружений Европы
      article.articlesList_item
        a.articlesList_link(
          href='/stati/kak-pravilno-chitat-shengenskuyu-vizu'
          data-internal
        )
          figure.articlesList_figure
            img.articlesList_img(
              src='/img/articles/kak-pravilno-chitat-shengenskuyu-vizu/kak-pravilno-chitat-shengenskuyu-vizu-528x392.jpg'
              alt=''
            )
          div.articlesList_txt
            time.articlesList_time 30 марта 2019
            h3.articlesList_title Как правильно читать шенгенскую визу?
      article.articlesList_item.articlesList_item--txt(
        data-type='2'
      )
        a.articlesList_link(
          href='/stati/biometriya-na-shengen-v-tyumeni'
          data-internal
        )
          div.articlesList_txt
            time.articlesList_time 2 апреля 2019
            h3.articlesList_title Биометрия на шенген в${'\u00A0'}Тюмени
  `;
};