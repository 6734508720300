/* eslint-disable */

import m from 'mithril';
import resolveTemplate from 'utils/resolveTemplate';
import TabHeader from './header';

class TabsModel {
  constructor(tabs, selected) {
    this.updateData(tabs, selected);
  }

  updateData(tabs, selected) {
    this.headers = tabs.map(item => ({
      key: item.key,
      title: item.title,
    }));

    this.data = tabs.reduce(
      (result, item) => ({
        ...result,
        [item.key]: { content: item.content, args: item.args },
      }),
      {},
    );

    if (!this.data[this.selected]) {
      this.selected = selected || (tabs[0] && tabs[0].key);
    }
  }

  onSelect = key => {
    this.selected = key;
  };

  selectFirst = () => {
    setTimeout(() => {
      this.selected = this.headers[0].key;
      m.redraw();
    }, 50);
  };
}

export default {
  oninit(vnode) {
    const { tabs = [], selected, bubble } = vnode.attrs;

    this.model = new TabsModel(tabs, selected);
    if (bubble) {
      bubble(this.model);
    }
    this.template = resolveTemplate('widgets/tabs');

    this.onkeydown = () => console.log('here!');
  },

  view(vnode) {
    if (this.model) {
      this.model.updateData(vnode.attrs.tabs, vnode.attrs.selected);
      const {
        state: {
          model: { headers, selected, onSelect, data },
        },
        attrs: { classes, onUnderline, onIcons },
      } = vnode;

      if (data[selected]) {
        return this.template({
          state: { classes },
          children: {
            headers: m(TabHeader, {
              classes,
              headers,
              selected,
              onSelect,
              onUnderline,
              onIcons,
            }),
            content: m(data[selected].content, data[selected].args),
          },
          // actions: {
          // test: 'test',
          // keydown: this.keydown,
          // },
        });
      } else {
        return this.template({
          state: { classes },
          children: {
            headers: m(TabHeader, {
              classes,
              headers,
              selected,
              onSelect,
              onUnderline,
              onIcons,
            }),
            // content: m({}, {}),
          },
          // actions: {
          // test: 'test',
          // keydown: this.keydown,
          // },
        });
      }
    }
  },
};
