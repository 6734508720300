/* eslint-disable */

import m from 'mithril';
import { API } from 'constants.js';
import LoadingBar from './loadingBar';

const Persons = {
  current: null,
  list: [],

  setList(personsList) {
    Persons.list = personsList;
    if (personsList.length && personsList.length == 1) {
      Persons.current = personsList[0].id;
    } else {
      Persons.current = null;
    }
  },

  setCurrent(personId = null) {
    Persons.current = personId;
  },

  createPerson(orderId) {
    LoadingBar.start();
    return m
      .request({
        method: 'POST',
        url: `${API}/order/${orderId}/persons`,
        withCredentials: true,
      })
      .finally(LoadingBar.finish);
  },

  deletePerson(orderId, personId) {
    LoadingBar.start();
    return m
      .request({
        method: 'DELETE',
        url: `${API}/order/${orderId}/persons/${personId}`,
        withCredentials: true,
      })
      .finally(LoadingBar.finish);
  },
};

// #if process.env.IS_DEVELOPMENT == 'true'
if (window.models) {
  window.models.Persons = Persons;
}
// #endif

export default Persons;
