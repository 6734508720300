const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
div(...state.wrapper)
  div.searchCountry_form
    .searchCountry_inputBackground
      input(
        ...state.input
        type="search"
        autocomplete="off"
      )
      = children.icon

  `;
};