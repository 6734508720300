import m from 'mithril';

export default {
  view({ attrs }) {
    const {
      id,
      path,
      label,
      multiline,
      placeholder,
      dataIsLoaded,
      settings: { disabled, value },
      updateValue,
    } = attrs;

    const props = {
      disabled: !dataIsLoaded || disabled,
      id: `inp_${id}`,
      type: 'text',
      onchange: e => {
        updateValue(path.concat('value'), e.target.value);
      },
    };

    if (placeholder.length > 1) {
      props.push(placeholder);
    }

    return m(
      'label.inputStandart', // .inputStandart--inline
      {
        for: props.id,
      },
      multiline
        ? m('textarea.inputStandart_input[required]', props, value)
        : m('input.inputStandart_input[required]', {
            ...props,
            value,
          }),
      m('span.inputStandart_label', {}, label),
      m('div.inputStandart_line'),
    );
  },
};
