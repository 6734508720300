const m = require("mithril");

module.exports = function(params) {
  const actions = params.actions;
  const helpers = params.helpers;
  const state = params.state;
  const children = params.children;
  const Link = m.route.Link

  return pug`
section.modalWindow_item(
  role='dialog'
)
  h1 Авторизация
  form(name='form-auth')(
    onkeydown= actions.keyDown
    onsubmit=actions.onSubmit
  )
    = children.usernameInput
    = children.passwordInput
    button(
      onclick= actions.login
    ) Войти
    br
    span.error
    = state.errorMessage
  `;
};